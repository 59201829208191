/* globals window */

import React, { Component } from 'react';
import { Button, Col, Row } from 'react-materialize';

import { connect } from 'react-redux';
import Zippity from './components/Zippity';
import { apiGet, apiPut } from '../brainApi';
import { setPageTitle } from '../helper';

class Checkin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      loading: true,
      swId: '',
      processingCheckin: false,
    };

    this.checkIn = this.checkIn.bind(this);
  }

  componentWillMount() {
    setPageTitle('Checkin');
  }

  componentDidMount() {
    const { params } = this.props;
    const { sw_id, customer_id } = params;

    // Get the details on this SW
    apiGet(`/scheduled-work/${sw_id}/${customer_id}`)
      .then((response) => response.json())
      .then((json) => {
        const status = json.status ? json.status.toUpperCase() : '';
        const alreadyCheckedIn = ['KEYS_AVAILABLE', 'FINISHED'].includes(
          status,
        );

        if (alreadyCheckedIn) {
          window.location = 'https://zippitycars.com/checked-in';
          return;
        }

        const isValid = json.customer.customer_id.toString() === customer_id;

        if (!isValid) {
          window.location = 'https://app.zippitycars.com/login';
          return;
        }

        this.setState({
          firstName: json.customer.first_name,
          swId: json.id,
          loading: false,
        });
      })
      .catch(() => {
        window.location = 'https://app.zippitycars.com/login';
      });
  }

  checkIn() {
    this.setState({ processingCheckin: true });

    const { swId } = this.state;
    apiPut(`/checkin/scheduled-work/${swId}`, {
      keys_available: true,
      checkin_type: 'web',
    }).then(() => {
      window.location = 'https://zippitycars.com/checked-in';
    });
  }

  render() {
    const { loading, firstName, processingCheckin } = this.state;
    const { z3pConfiguration } = this.props;
    const {
      customer_facing_name: serviceProviderName,
      customer_facing_phone: serviceProviderPhone,
    } = z3pConfiguration;

    return (
      <Zippity active="checkin">
        {loading ? (
          <Row>
            <Col s={12} className="center">
              <h2>Loading...</h2>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col s={12} m={8} offset="m2" className="neue center">
              <h3>Hey {firstName}!</h3>

              <p>
                It&apos;s time to check in for your {serviceProviderName}{' '}
                service!
              </p>
              <p>
                Park your car, drop off your keys, then click the button below
                to check in so we know you&apos;re here.
              </p>

              {processingCheckin ? (
                <Button disabled large>
                  <strong>Checking In...</strong>
                </Button>
              ) : (
                <Button large className="green" onClick={this.checkIn}>
                  <strong>Check In</strong>
                </Button>
              )}

              <p>
                If you have any questions about your service day, please text us
                at {serviceProviderPhone}.
              </p>
            </Col>
          </Row>
        )}
      </Zippity>
    );
  }
}

function mapStateToProps(state) {
  return {
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(Checkin);
