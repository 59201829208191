/* globals alert */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Select from './Select';

const LocationDropdown = (props) => {
  const { clientLocations, selectedClientLocation, handleClientChange } = props;

  clientLocations.sort((a, b) => a.long_name.localeCompare(b.long_name));

  const createOptionElement = (loc) => (
    <option
      key={loc.client_location_id}
      data-client-location-id={loc.client_location_id}
      value={loc.short_name}
    >
      {loc.long_name}
    </option>
  );

  return (
    <Fragment>
      <Select
        s={12}
        m={12}
        l={6}
        onChange={handleClientChange}
        id="clientLocationDropdown"
        name="clientLocationDropdown"
        key="clientLocationDropdown"
        value={selectedClientLocation}
      >
        <option key="disabled" value="" disabled>
          Select your location
        </option>

        {clientLocations.map(createOptionElement)}
      </Select>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    clientLocations: state.ui.clientLocations,
  };
}

export default connect(mapStateToProps, null)(LocationDropdown);
