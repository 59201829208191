/* globals document */

// Enum for the payment processors supported by Zippity
export const STRIPE = Symbol('Stripe');
export const SQUARE = Symbol('Square');

const prodOriginsThatUseStagingStripe = [
  'https://first-starter-pro.zippitycars.com',
  'https://second-starter-pro.zippitycars.com',
];

// https://stripe.com/docs/keys#obtain-api-keys
export const getStripePublishableKey = () => {
  // Default to Sandbox
  let key = '';

  if (
    process.env.REACT_APP_ENVIRO === 'production' &&
    !prodOriginsThatUseStagingStripe.includes(document.location.origin)
  ) {
    key = '';
  }
  return key;
};
