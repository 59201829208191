/* globals window */

import React from 'react';
import { connect } from 'react-redux';
import Pricing from './components/Pricing';
import { getEmailSupportLink, setPageTitle } from '../helper';

// Customers can use Save My Vehicle to create an account but not go through the full booking flow
const SaveMy = (props) => {
  setPageTitle('Account Created');
  const { z3pConfiguration } = props;
  const {
    z3p_client_name: z3pEnv,
    customer_facing_name: serviceProviderName,
  } = z3pConfiguration;
  return (
    <Pricing
      currentStep="SaveMy"
      h1={`Thank you for signing up for ${serviceProviderName}!`}
      h2="You're all set!"
    >
      <div className="neue">
        <p>
          If you have any questions please email us at {getEmailSupportLink()}
          {z3pEnv === 'zippity'
            ? ' or live chat with us on this website using the button below'
            : ''}
          .
        </p>
      </div>

      <span
        onClick={() => window.Intercom('show')}
        className="waves-effect waves-light btn-large chat"
        role="button"
        tabIndex="-1"
      >
        Start Chat
      </span>
    </Pricing>
  );
};

function mapStateToProps(state) {
  return {
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(SaveMy);
