import React from 'react';
import Day from './Day';

const Week = ({
  weekToRender = [],
  timeslots,
  selectedTimeslot,
  onSelectTimeslot,
  tz,
}) => {
  return (
    <div className="tsc__week">
      {weekToRender.map((day, index) => {
        return (
          <Day
            key={index}
            day={day}
            timeslots={timeslots[day.format('YYYY-MM-DD')]}
            selectedTimeslot={selectedTimeslot}
            onSelectTimeslot={onSelectTimeslot}
            weekDays={weekToRender.length}
            tz={tz}
          />
        );
      })}
    </div>
  );
};

export default Week;
