/* globals window */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import { Button, Checkbox, Modal } from 'react-materialize';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import _ from 'lodash';
import {
  loadCompatibleDates,
  loadCompatibleDatesForHomeAddress,
} from '../../brainApi';
import Loading from './Loading';

const CompatibleDateSelector = ({
  cart,
  customerLocation,
  useHomeAddress,
  homeAddress,
  compatibleDates,
  areCompatibleDatesLoading,
  setAreCompatibleDatesLoading,
  selectScheduleInstance,
  dispatch,
  selectedDate,
  displayWaitlistForm,
  wantServiceSooner,
  setWantServiceSooner,
  z3pConfiguration,
}) => {
  const {
    is_using_waitlist: isUsingWaitlist,
    is_offering_service_sooner: isOfferingServiceSooner,
  } = z3pConfiguration;

  const [displayServiceSoonModal, setDisplayServiceSoonModal] = useState(false);

  useEffect(() => {
    // Hide loading message if we already have a schedule from props
    if (compatibleDates.length > 0) {
      setAreCompatibleDatesLoading(false);
    }

    let startDate = Moment()
      .startOf('month')
      .format('YYYY-MM-DD');
    let endDate = Moment()
      .add(1, 'month')
      .endOf('month')
      .format('YYYY-MM-DD');
    if (selectedDate) {
      startDate = Moment(selectedDate)
        .startOf('month')
        .format('YYYY-MM-DD');
      endDate = Moment(selectedDate)
        .add(1, 'month')
        .endOf('month')
        .format('YYYY-MM-DD');
    }
     console.log(useHomeAddress);
    if (useHomeAddress) {
      loadCompatibleDatesForHomeAddress(
        homeAddress.addressIDSelected,
        cart,
        startDate,
        endDate,
      ).then((newCompatibleDates) => {
        if (newCompatibleDates.length > 0) {
          setCompatibleDates(newCompatibleDates, true);
        } else {
          // If there is no availability in the first range, get availability for a full year
          endDate = Moment()
            .add(1, 'year')
            .endOf('month')
            .format('YYYY-MM-DD');
          loadCompatibleDatesForHomeAddress(
            homeAddress.addressIDSelected,
            cart,
            startDate,
            endDate,
          ).then((newCompatibleDates) => {
            setCompatibleDates(newCompatibleDates, true);
          });
        }
      });
    } else {
      // Fetch a fresh schedule from the server
      loadCompatibleDates(
        customerLocation.clientLocationId,
        cart,
        startDate,
        endDate,
      ).then((newCompatibleDates) => {
        if (newCompatibleDates.length > 0) {
          setCompatibleDates(newCompatibleDates, true);
        } else {
          // If there is no availability in the first range, get availability for a full year
          endDate = Moment()
            .add(1, 'year')
            .endOf('month')
            .format('YYYY-MM-DD');
          loadCompatibleDates(
            customerLocation.clientLocationId,
            cart,
            startDate,
            endDate,
          ).then((newCompatibleDates) => {
            setCompatibleDates(newCompatibleDates, true);
          });
        }
      });
    }
  }, []);

  const setCompatibleDates = (newCompatibleDates, isInitialLoad = false) => {
    // For the initial load, only use new dates to avoid stale data
    const allCompatibleDates = isInitialLoad
      ? newCompatibleDates
      : [...compatibleDates, ...newCompatibleDates];
    // Remove duplicates to only add values not already saved
    const allUniqueCompatibleDates = _.uniqBy(allCompatibleDates, (item) =>
      [item.date, item.service_vehicle_id].join(),
    );

    dispatch({
      type: 'SET_COMPATIBLE_DATES',
      compatibleDates: allUniqueCompatibleDates,
    });

    setAreCompatibleDatesLoading(false);

    if (isInitialLoad && !selectedDate) {
      // Autoselect the first available date
      const newAvailableDates = newCompatibleDates.filter(
        (d) => d.is_available,
      );
      newAvailableDates.sort((a, b) => new Date(a.date) - new Date(b.date));
      if (newAvailableDates.length > 0 && !displayWaitlistForm) {
        selectScheduleInstance(newAvailableDates[0], null, true);
      }
    }
  };

  const clientLocationName = customerLocation.location;

  const showScheduleFullWaitlist = compatibleDates.every(
    (date) => !date.is_available,
  );
  const availableDates = compatibleDates.filter((d) => d.is_available);

  const serviceSoonerModal = (
    <Modal
      header="You're on the waitlist"
      actions={[
        <Button
          className="btn-teal"
          onClick={() => {
            setDisplayServiceSoonModal(false);
          }}
        >
          Got It
        </Button>,
      ]}
      open={displayServiceSoonModal}
    >
      {wantServiceSooner ? (
        <div>
          Want service earlier than the dates listed here? By checking this box,
          you'll be added to our waitlist. Please fill out the additional
          textbox with information about your availability and schedule. If any
          spots open up sooner, we'll reach out to change your date! And in the
          meantime, we'll confirm your services for the date you selected.
        </div>
      ) : (
        <div>
          Thanks for joining the waitlist. We will reach out to confirm a date
          for you as soon as possible. In the meantime, go ahead and finish
          checking out by providing us with information about your availability.
          <br />
          We will still ask for your credit card information, but we will NOT
          charge your card until a date is confirmed and the service is
          complete. We look forward to servicing your vehicle soon.
        </div>
      )}
    </Modal>
  );

  const compatibleDatesMap = {};
  compatibleDates.forEach((object) => {
    compatibleDatesMap[object.date] = object;
  });

  const waitlistText = (text, datesAvailable = false) => {
    const obj = {
      date: null,
      service_vehicle_id: null,
      schedule_instance_id: null,
    };

    let datesString = '';
    if (datesAvailable) {
      // Save dates that are available but were not chosen to add to the waitlist note
      const datesNotChosen = Object.keys(compatibleDatesMap)
        .filter((key) => compatibleDatesMap[key].is_available)
        .map((date) => Moment.utc(date).format('ddd, MMMM Do'));
      datesString = datesNotChosen.join('\n');
    }

    if (
      !displayServiceSoonModal &&
      (showScheduleFullWaitlist || !datesAvailable)
    ) {
      selectScheduleInstance(obj);
    }

    const isSelected = selectedDate === obj.date;
    const className = isSelected ? 'active' : 'available';
    if (!datesAvailable) {
      return (
        <Button
          className="no-dates-waitlist"
          onClick={() => {
            selectScheduleInstance(obj);
            setDisplayServiceSoonModal(!displayServiceSoonModal);
          }}
        >
          {text}
        </Button>
      );
    }
    return (
      <div>
        <br />
        <li
          key="waitlist"
          onClick={() => {
            selectScheduleInstance(obj, datesString);
            setDisplayServiceSoonModal(!displayServiceSoonModal);
          }}
          onKeyPress={() => {
            selectScheduleInstance(obj, datesString);
            setDisplayServiceSoonModal(!displayServiceSoonModal);
          }}
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            fontSize: '1rem',
            textAlign: 'center',
          }}
        >
          <div>{text}</div>
        </li>
      </div>
    );
  };

  const calendarView = () => {
    const getFormattedCalendarDate = (date) => {
      return new Date(date)
        .toUTCString()
        .split(' ', 4)
        .join(' ');
    };

    const getCompatibleDateForCalendar = (calendarDate) => {
      return Object.keys(compatibleDatesMap).find(
        (date) => getFormattedCalendarDate(date) === calendarDate,
      );
    };

    const isDateAvailable = (date) => {
      const calendarDate = getFormattedCalendarDate(date);
      return (
        !!date &&
        !!getCompatibleDateForCalendar(calendarDate) &&
        compatibleDatesMap[getCompatibleDateForCalendar(calendarDate)]
          .is_available
      );
    };

    const selectDate = (date) => {
      if (!date) {
        return false;
      }
      const calendarDate = getFormattedCalendarDate(date);
      const dateObjectToSelect =
        compatibleDatesMap[getCompatibleDateForCalendar(calendarDate)];
      if (dateObjectToSelect.is_available) {
        selectScheduleInstance(dateObjectToSelect);
      }
      return dateObjectToSelect;
    };

    const showDateDetails = (date) => {
      if (!date) {
        return null;
      }
      const calendarDate = getFormattedCalendarDate(date);
      const dateObject =
        compatibleDatesMap[getCompatibleDateForCalendar(calendarDate)];
      if (
        dateObject &&
        dateObject.is_available &&
        dateObject.is_pickup_and_delivery
      ) {
        return (
          <div>
            <i className="material-icons" style={{ fontSize: '18px' }}>
              airport_shuttle
            </i>
          </div>
        );
      }

      if (
        dateObject &&
        dateObject.is_available &&
        dateObject.is_demand_based &&
        !dateObject.is_pickup_and_delivery &&
        dateObject.scheduled_works_at_client_location > 0 &&
        !useHomeAddress
      ) {
        return (
          <div>
            <img
              style={{ height: 18 }}
              src="/static/img/leaf-white.png"
              alt="Eco-friendly"
            />
          </div>
        );
      }
      return null;
    };
    const colorKey = (
      <div style={{ textAlign: 'center' }}>
        <span className="available-date-text">
          <div className="available-date-color" />
          Available Date
        </span>
        <span className="selected-date-text">
          <div className="selected-date-color" />
          Selected Date
        </span>
      </div>
    );

    const offset = new Date().getTimezoneOffset() / 60;
    const formattedSelectedDate = selectedDate && new Date(selectedDate);
    if (formattedSelectedDate) {
      const selectedDateForCalendar = new Date(
        formattedSelectedDate.setHours(
          formattedSelectedDate.getHours() + offset,
        ),
      );
      return (
        <div className="schedule-calendar-wrapper">
          <Calendar
            prev2Label={null}
            next2Label={null}
            minDetail="month"
            maxDetail="month"
            defaultActiveStartDate={selectedDateForCalendar}
            defaultValue={selectedDateForCalendar}
            onClickDay={(date) => selectDate(date)}
            tileDisabled={({ date }) => !isDateAvailable(date)}
            tileContent={({ date }) => showDateDetails(date)}
            calendarType="US"
            className="schedule-calendar"
            tileClassName="schedule-calendar-tile"
            navigationLabel={({ label }) => (
              <span style={{ color: 'black' }}>{label}</span>
            )}
            onActiveStartDateChange={({ activeStartDate }) => {
              let active = true;

              if (active) {
                // Load the next 2 months
                const startDate = Moment(activeStartDate).format('YYYY-MM-DD');
                const endDate = Moment(activeStartDate)
                  .add(1, 'month')
                  .endOf('month')
                  .format('YYYY-MM-DD');
                if (useHomeAddress) {
                  loadCompatibleDatesForHomeAddress(
                    homeAddress.addressIDSelected,
                    cart,
                    startDate,
                    endDate,
                  ).then((newCompatibleDates) => {
                    setCompatibleDates(newCompatibleDates, false);
                  });
                } else {
                  loadCompatibleDates(
                    customerLocation.clientLocationId,
                    cart,
                    startDate,
                    endDate,
                  ).then((newCompatibleDates) => {
                    setCompatibleDates(newCompatibleDates, false);
                  });
                }
              }

              // Use this active flag as a cleanup to avoid race conditions
              return () => (active = false);
            }}
          />
        
        </div>
      );
    }
    return (
      <div className="schedule-calendar-wrapper">
        <Calendar
          prev2Label={null}
          next2Label={null}
          minDetail="month"
          maxDetail="month"
          value={null}
          onClickDay={(date) => selectDate(date)}
          tileDisabled={({ date }) => !isDateAvailable(date)}
          tileContent={({ date }) => showDateDetails(date)}
          calendarType="US"
          className="schedule-calendar"
          tileClassName="schedule-calendar-tile"
        />
        {colorKey}
      </div>
    );
  };

  const locationToShow = useHomeAddress
    ? `your location, ${homeAddress.addressNameSelected}`
    : clientLocationName;

  const scheduleHasPickupAndDelivery = () => {
    return (
      Object.keys(compatibleDatesMap).filter(
        (date) =>
          compatibleDatesMap[date].is_available &&
          compatibleDatesMap[date].is_pickup_and_delivery,
      ).length > 0
    );
  };

  const scheduleHasEcoFriendlyOption = () => {
    return (
      Object.keys(compatibleDatesMap).filter(
        (date) =>
          compatibleDatesMap[date].is_available &&
          compatibleDatesMap[date].is_demand_based &&
          !compatibleDatesMap[date].is_pickup_and_delivery &&
          compatibleDatesMap[date].scheduled_works_at_client_location > 0 &&
          !useHomeAddress,
      ).length > 0
    );
  };

  return (
    <div className="col s12 schedule-wrapper">
      {serviceSoonerModal}
      <ul className="dates">
        {areCompatibleDatesLoading && <Loading />}
        {!areCompatibleDatesLoading &&
          availableDates.length > 0 &&
          !showScheduleFullWaitlist &&
          calendarView()}
        {!areCompatibleDatesLoading &&
          isUsingWaitlist &&
          availableDates.length > 0 &&
          showScheduleFullWaitlist &&
          waitlistText(
            "Our schedule is full, but you'll be added to our waitlist. Click here for details",
            false,
          )}
        {!areCompatibleDatesLoading &&
          availableDates.length === 0 &&
          isUsingWaitlist &&
          waitlistText(
            `Sorry, there are no service dates currently available for ${locationToShow},\n but we'll add you to our waitlist. Click here for details.`,
            false,
          )}
        {!areCompatibleDatesLoading &&
          !(availableDates.length > 0) &&
          !isUsingWaitlist && (
            <div>
              Sorry, there are no service dates currently available for{' '}
              {locationToShow}.
            </div>
          )}
        {!areCompatibleDatesLoading &&
          isUsingWaitlist &&
          availableDates.length > 0 &&
          !showScheduleFullWaitlist &&
          waitlistText(
            'None of these dates work? Click here to add yourself to our waitlist!',
            true,
          )}
      </ul>
      {!areCompatibleDatesLoading &&
        isOfferingServiceSooner &&
        availableDates.length > 0 &&
        !showScheduleFullWaitlist &&
        selectedDate && (
          <div className={!selectedDate ? 'lighten-checkbox' : ''}>
            <Checkbox
              s={4}
              name="addToWaitlist"
              label={
                wantServiceSooner
                  ? "You've been added to our waitlist"
                  : 'Prefer to have your service sooner? Click Here.'
              }
              checked={wantServiceSooner}
              disabled={!selectedDate}
              onChange={() => {
                if (wantServiceSooner === false) {
                  setDisplayServiceSoonModal(!displayServiceSoonModal);
                }
                setWantServiceSooner(!wantServiceSooner);
              }}
            />
          </div>
        )}
      {(scheduleHasPickupAndDelivery() || scheduleHasEcoFriendlyOption()) && (
        <h6>Icon Guide:</h6>
      )}
      {scheduleHasPickupAndDelivery() && (
        <div>
          <i className="material-icons" style={{ fontSize: '18px' }}>
            airport_shuttle
          </i>
          This indicates a Valet Service. If you select this date, we will pick
          up your vehicle and bring it to our service shop.
        </div>
      )}
      {scheduleHasEcoFriendlyOption() && (
        <div>
          <img
            style={{ height: 18 }}
            src="/static/img/leaf.png"
            alt="Eco-friendly"
          />
          Eco-friendly choice! We’re already on-site on the selected date, so if
          you choose this date, you’re saving energy 🌎 🙌
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    cart: state.ui.cart,
    compatibleDates: state.ui.compatibleDates,
    customerLocation: state.ui.customerLocation,
    homeAddress: state.ui.homeAddress,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(CompatibleDateSelector);
