import React from 'react';
import Moment from 'moment';

const PrettyDate = (props) => {
  const { dateString } = props;
  const date = Moment.utc(dateString);

  let prettyString = '';
  if (!date) {
    prettyString = 'Unknown Date';
  } else {
    prettyString = date.format('dddd, MMMM D');
  }

  return <span>{prettyString}</span>;
};

export default PrettyDate;
