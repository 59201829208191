import Raven from 'raven-js';
import { apiGet } from './brainApi';
import { stateAbbreviationToName } from './screens/components/UsStates';

// Check which schedule types are offered at this location.
// This is used to determine which services to show on the SimpleServices.js page
export function loadAvailableScheduleTypes(locationId, isHomeLocation = false) {
  const urlPart = isHomeLocation ? 'home-address' : 'client-location';
  const url = `/get-offered-schedule-types/${urlPart}/${locationId}`;

  return apiGet(url)
    .then((response) => response.json())
    .catch((error) => Raven.captureException(error));
}

// Transform each service to object with minimal necessary keys
function transformServiceList(serviceList) {
  // The "normal" filter to to check that it's bookable and not deleted.
  // The one exception is, for the ATD tire product service, we still want it in priceList even if it's deleted or not bookable.
  // This ATD tire product is basically an "invisible" service that is just used as a placeholder.
  // It's not shown in the tire service selection page.
  // But we still need access to it within Tires.js.
  const filtered = serviceList.filter(
    (s) =>
      (s.service_offering.bookable && !s.service_offering.is_deleted) ||
      s.service_offering.is_atd_tire_product,
  );
  const transformed = filtered.map((s) => {
    return {
      service_offering_id: s.service_offering_id,
      long_name: s.service_offering.long_name,
      short_name: s.service_offering.short_name,
      schedule_types: s.service_offering.schedule_types,

      parts_cost_dollars: s.parts_cost_dollars,
      labor_cost_dollars: s.labor_cost_dollars,
      total_cost_dollars: s.total_cost_dollars,
      original_value_dollars: s.service_offering.original_value_dollars,
      variable_pricing: s.service_offering.variable_pricing,
      tax_type: s.service_offering.tax_type,

      marketing_url: s.service_offering.marketing_url,
      more_info_image: s.service_offering.more_info_image,

      labor_hours: s.service_offering.labor_hours,

      display_emphasize: s.service_offering.display_emphasize,
      display_order: s.service_offering.display_order,
      display_superscript: s.service_offering.display_superscript,
      display_category: s.service_offering.display_category,

      incompatible_service_offering_ids:
        s.service_offering.incompatible_service_offering_ids,

      included_with_every_mechanical_service:
        s.service_offering.included_with_every_mechanical_service,
      add_oil_cost: s.service_offering.add_oil_cost,

      extra_oil_cost_per_qt_dollars: s.extra_oil_cost_per_qt_dollars,

      descriptions: s.service_offering.descriptions,

      booking_message_1: s.service_offering.custom_message_1
        ? s.service_offering.custom_message_1.booking_review_message
        : null,
      booking_message_2: s.service_offering.custom_message_2
        ? s.service_offering.custom_message_2.booking_review_message
        : null,

      inline_image_url: s.service_offering.inline_image_url,

      subtitle: s.service_offering.subtitle,
      card_image_url: s.service_offering.card_image_url,
      per_tire: s.service_offering.per_tire,
      card_bullet_points: s.service_offering.card_bullet_points || [],
      details_blurb: s.service_offering.details_blurb,
      tags: s.service_offering.tags,
      next_step_in_flow: s.service_offering.next_step_in_flow,
      offered_vehicle_makes: s.service_offering.offered_vehicle_makes,
      parent_service_offering: s.service_offering.parent_service_offering_id,
      child_service_offerings: s.service_offering.child_service_offerings || [],

      is_atd_tire_product: s.service_offering.is_atd_tire_product,
      is_tire_installation_fee: s.service_offering.is_tire_installation_fee,
    };
  });

  transformed.sort((a, b) => a.long_name.localeCompare(b.long_name));

  return transformed;
}

export function loadClientLocations(dispatch) {
  const params = { filter: JSON.stringify({ is_active: true }) };
  apiGet('/client-location', params)
    .then((response) => response.json())
    .then((clientLocations) => {
      dispatch({
        type: 'SET_CLIENT_LOCATIONS',
        clientLocations,
      });
    });
}

export function loadClientLocationDetailsAndPriceList(
  dispatch,
  clientLocationId = null,
  vehicleId = null,
  options = {},
) {
  const url = clientLocationId
    ? `/client-location/${clientLocationId}/details`
    : '/priced-service-offerings';
  const { setLocation = true } = options;

  let params = null;
  if (vehicleId) params = { vehicle_id: vehicleId };

  return apiGet(url, params)
    .then((response) => response.json())
    .then((response) => {
      if (setLocation)
        dispatch({
          type: 'SET_LOCATION',
          location:
            response.client_location && response.client_location.short_name,
          selectedLocationAlias:
            response.client_location && response.client_location.short_name,
          clientLocationId: clientLocationId || undefined, // if there's no client location, don't include it as a key
          customerUsState:
            response.client_location &&
            response.client_location.address &&
            stateAbbreviationToName(response.client_location.address.state),
        });
      dispatch({ type: 'SET_ALL_KEYOSKS', allKeyosks: response.keyosks });
      dispatch({
        type: 'SET_ALL_PARKING_ZONES',
        allParkingZones: response.parking_zones,
      });
      if (response.client_location && response.client_location.tax_rate_percent)
        dispatch({
          type: 'SET_TAX_RATE',
          taxRate: response.client_location.tax_rate_percent,
        });
      dispatch({
        type: 'SET_PRICE_LIST',
        priceList: transformServiceList(response.service_offerings),
      });
      return response;
    })
    .catch((error) => Raven.captureException(error));
}

export function loadCoupon(couponCode) {
  if (!couponCode) {
    return null;
  }
  const couponUpper = couponCode.toUpperCase();

  return apiGet(`/coupon/${couponUpper}`)
    .then((response) => response.json())
    .catch((error) => Raven.captureException(error));
}
