import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Select from './Select';

const ShopLocationDropdown = (props) => {
  const {
    clientLocations,
    selectedClientLocation,
    handleClientChange,
    fullWidth = false,
  } = props;

  return (
    <Fragment>
      <Select
        s={12}
        m={fullWidth ? 12 : 8}
        l={fullWidth ? 12 : 8}
        onChange={handleClientChange}
        id="clientLocationDropdown"
        name="clientLocationDropdown"
        key="clientLocationDropdown"
        value={selectedClientLocation}
      >
        <option key="disabled" value="" disabled>
          Select your shop
        </option>

        {clientLocations
          .sort((a, b) => a.long_name.localeCompare(b.long_name))
          .map((loc) => (
            <option
              key={loc.client_location_id}
              data-client-location-id={loc.client_location_id}
              value={loc.short_name}
            >
              {loc.long_name}{' '}
              {loc.address && loc.address.one_line_human_readable_address
                ? `(${loc.address.one_line_human_readable_address})`
                : ''}
            </option>
          ))}
      </Select>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    clientLocations: state.ui.clientLocations,
  };
}

export default connect(mapStateToProps, null)(ShopLocationDropdown);
