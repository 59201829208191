import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, TextInput } from 'react-materialize';
import _ from 'lodash';
import moment from 'moment';
import VehicleDropdown from '../components/VehicleDropdown';

const HeavyDutyTab = (props) => {
  const { dispatch, pricing, z3pConfiguration } = props;

  const {
    year: selectedYear,
    make: selectedMakeName,
    other_make: selectedOtherMake,
    model: selectedModelName,
    heavy_duty_engine_make: selectedEngineMake,
    heavy_duty_other_engine_make: selectedOtherEngineMake,
    heavy_duty_engine_serial_number: selectedEngineId,
    vin: selectedVin,
    fuel_type: selectedFuelType,
    other_fuel_type: selectedOtherFuelType,
    is_using_heavy_duty: isUsingHeavyDuty,
  } = pricing;

  const { z3p_client_name: z3pClient } = z3pConfiguration;
  const isValvolineHeavyDuty =
    z3pClient.toLowerCase().includes('valvoline') && isUsingHeavyDuty;

  // Data from https://docs.google.com/spreadsheets/d/17FtWdsLeMP2pIDfhfhzGi207rv3TZ41IDspfwDNGuIk/edit#gid=1080072560
  const currentYear = Number(moment().format('YYYY'));
  const years = _.range(currentYear + 1, currentYear - 100);
  const heavyDutyMakes = [
    'Chevrolet',
    'Ford',
    'Freightliner',
    'Hino',
    'International Trucks',
    'Isuzu',
    'Kenworth Truck Company',
    'Mack',
    'Peterbilt',
    'Ram',
    'Volvo',
    'Western Star Trucks',
    'Other',
  ];
  const heavyDutyEngineMakes = [
    'Caterpillar',
    'Cummins',
    'Daimler',
    'Detroit Diesel',
    'Duramax',
    'Hino',
    'Mercedes-Benz',
    'Navistar',
    'PACCAR',
    'Powerstroke',
    'Volvo',
    'Other',
  ];

  const heavyDutyFuelTypes = [
    'Diesel',
    'Gas',
    'Natural Gas',
    'Other',
    'I Don’t Know',
  ];

  // This is a curried function - using this syntax, we can avoid needing to explicitly pass the event
  // For more info: https://stackoverflow.com/questions/32782922/what-do-multiple-arrow-functions-mean-in-javascript
  const handleChange = (type, name) => (e) => {
    dispatch({
      type,
      [name]: name === 'vin' ? e.target.value.toUpperCase() : e.target.value,
    });
  };

  return (
    <Col>
      <Row style={{ marginBottom: isValvolineHeavyDuty ? 20 : 0 }}>
        <TextInput
          s={6}
          m={3}
          label="VIN"
          placeholder="Enter VIN"
          id="vin"
          value={selectedVin}
          onChange={handleChange('SELECT_HEAVY_DUTY_VIN', 'vin')}
          inputClassName="vehicle-entry-text-field"
        />
      </Row>
      {isValvolineHeavyDuty && (
        <div style={{ margin: '0 0 50px 0' }}>
          <p>
            <span style={{ fontWeight: 'bold' }}>
              Please be sure to provide the VIN (Vehicle Identification Number)
              so we can fully prepare for your visit.
            </span>{' '}
            If you do not have access to the VIN at this time, we’ll give you a
            call soon to record your VIN number prior to your service
            appointment.
          </p>
        </div>
      )}
      <Row>
        <VehicleDropdown
          s={6}
          m={3}
          type="Year"
          value={selectedYear}
          onChange={handleChange('SELECT_YEAR', 'year')}
          list={years}
          classes="vehicle-entry-col-dropdown"
        />
        <VehicleDropdown
          s={6}
          m={3}
          type="Make"
          value={selectedMakeName}
          onChange={handleChange('SELECT_MAKE', 'makeName')}
          list={heavyDutyMakes}
          required
          classes="vehicle-entry-col-dropdown"
        />
        {selectedMakeName === 'Other' && (
          <TextInput
            s={6}
            m={3}
            label="Other Make *"
            placeholder="Enter Make"
            id="otherMake"
            value={selectedOtherMake}
            onChange={handleChange('SELECT_OTHER_MAKE', 'otherMakeName')}
            inputClassName="vehicle-entry-text-field"
          />
        )}
        <TextInput
          s={6}
          m={3}
          label="Model"
          placeholder="Enter Model"
          id="model"
          value={selectedModelName}
          onChange={handleChange('SELECT_MODEL', 'modelName')}
          inputClassName="vehicle-entry-text-field"
        />
        <VehicleDropdown
          s={6}
          m={3}
          type="Engine Make"
          value={selectedEngineMake}
          onChange={handleChange(
            'SELECT_HEAVY_DUTY_ENGINE_MAKE',
            'heavy_duty_engine_make',
          )}
          list={heavyDutyEngineMakes}
          classes="vehicle-entry-col-dropdown"
        />
        {selectedEngineMake === 'Other' && (
          <TextInput
            s={6}
            m={3}
            label="Other Engine Make"
            placeholder="Enter Engine Make"
            id="otherEngineMake"
            value={selectedOtherEngineMake}
            onChange={handleChange(
              'SELECT_HEAVY_DUTY_OTHER_ENGINE_MAKE',
              'heavy_duty_other_engine_make',
            )}
            inputClassName="vehicle-entry-text-field"
          />
        )}
        <TextInput
          s={6}
          m={3}
          label="Engine ID"
          placeholder="Enter Engine ID"
          id="engine-id"
          value={selectedEngineId}
          onChange={handleChange(
            'SELECT_HEAVY_DUTY_ENGINE_SERIAL_NUMBER',
            'heavy_duty_engine_serial_number',
          )}
          inputClassName="vehicle-entry-text-field"
        />
        <VehicleDropdown
          s={6}
          m={3}
          type="Fuel Type"
          value={selectedFuelType}
          onChange={handleChange('SELECT_HEAVY_DUTY_FUEL_TYPE', 'fuel_type')}
          list={heavyDutyFuelTypes}
          classes="vehicle-entry-col-dropdown"
        />
        {selectedFuelType === 'Other' && (
          <TextInput
            s={6}
            m={3}
            label="Other Fuel Type"
            placeholder="Enter Fuel Type"
            id="otherFuelType"
            value={selectedOtherFuelType}
            onChange={handleChange(
              'SELECT_HEAVY_DUTY_OTHER_FUEL_TYPE',
              'other_fuel_type',
            )}
            inputClassName="vehicle-entry-text-field"
          />
        )}
      </Row>
    </Col>
  );
};

function mapStateToProps(state) {
  return {
    pricing: state.ui.pricing,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(HeavyDutyTab);
