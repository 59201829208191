import React, { Component, Fragment } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { Button, CardPanel, Col, Icon, Row } from 'react-materialize';

import Pricing from './components/Pricing';
import Loading from './components/Loading';
import { apiPost, apiPut } from '../brainApi';
import {
  getCommonVehicleColors,
  setPageTitle,
  setUserWorkplaceAndRedirect,
  shouldAutoSelectLocation,
} from '../helper';
import { getVehicleType } from '../vehicleHelper';

class VehicleInfoFromLookup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      service_records_count: null,
      recalls_count: null,
      recall_list: [],
      image_link: false,
      oil_types: null,
      tire_sizes: null,
      vehicleString: null,
      infoLoaded: [false, false, false, false],
      selectedColor: '',
      allowContinue: false,
      errorInvalidVehicleMake: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateColor = this.updateColor.bind(this);
  }

  componentWillMount() {
    setPageTitle('Vehicle Information');
  }

  componentDidMount() {
    const { dispatch, params, z3pConfiguration } = this.props;
    const { plateState, plateNumber } = params;
    const { offered_vehicle_makes: allowedVehicleMakes } = z3pConfiguration;

    const url = `/booking/vehicle-lookup/${plateState}/${plateNumber}`;

    apiPost(url, {})
      .then((response) => {
        if (response.status !== 200) {
          browserHistory.push('/pricing/vehicle-lookup?error=true');
        }
        return response.json();
      })
      .then(
        ({
          vehicle,
          service_records_count,
          recalls_count,
          image_link,
          oil_types,
          recall_list,
        }) => {
          if (vehicle) {
            if (
              allowedVehicleMakes &&
              allowedVehicleMakes.length > 0 &&
              vehicle.make &&
              !allowedVehicleMakes.includes(vehicle.make)
            ) {
              this.setState({
                errorInvalidVehicleMake: true,
                allowContinue: false,
              });
            }
            const { recall_makes_offered } = z3pConfiguration;
            const vehicleType = getVehicleType(vehicle);
            dispatch({ type: 'SELECT_VEHICLE', vehicle });
            dispatch({
              type: 'SELECT_VEHICLE_TYPE',
              vehicleType,
            });
            this.setState(
              {
                vehicleString: vehicle.display_name,
                vehicleMake: `${vehicle.make}`,
                image_link,
                recalls_count,
                recall_list:
                  recall_makes_offered &&
                  recall_makes_offered.includes(vehicle.make)
                    ? recall_list
                    : [],
                service_records_count,
                infoLoaded: [true, false, false, false],
              },
              () => {
                setTimeout(() => {
                  this.setState(
                    {
                      infoLoaded: [true, true, false, false],
                    },
                    () => {
                      setTimeout(() => {
                        this.setState(
                          {
                            oil_types,
                            infoLoaded: [true, true, true, false],
                          },
                          () => {
                            setTimeout(() => {
                              this.setState({
                                tire_sizes: vehicle.standard_tire_sizes,
                                infoLoaded: [true, true, true, true],
                                allowContinue: true,
                              });
                            }, 500);
                          },
                        );
                      }, 500);
                    },
                  );
                }, 500);
              },
            );
          }
        },
      );
  }

  handleSubmit(event) {
    event.preventDefault();
    const { pricing, dispatch, clientLocations, z3pConfiguration } = this.props;
    const {
      service_locations: serviceLocations,
      skip_simple_services_to,
      is_skipping_vehicle,
    } = z3pConfiguration;
    const { selectedColor, recall_list } = this.state;
    if (selectedColor) {
      apiPut(`/vehicle/${pricing.vehicle_id}`, { color: selectedColor }).then(
        () => {
          dispatch({ type: 'SELECT_COLOR', color: selectedColor });
        },
      );
    }
    dispatch({ type: 'SET_RECALL_LIST', recallList: recall_list });
    // If the only service location is workplace or shop and there is only 1 client location or a location has already been selected, skip /place
    if (shouldAutoSelectLocation(serviceLocations, clientLocations)) {
      const clId = clientLocations[0].client_location_id;
      return setUserWorkplaceAndRedirect(
        clId,
        dispatch,
        skip_simple_services_to,
        is_skipping_vehicle,
        pricing,
      );
    }
    return browserHistory.push('/pricing/place');
  }

  updateColor(event) {
    this.setState({
      selectedColor: event.target.value,
    });
  }

  colorSelection() {
    const colors = getCommonVehicleColors();

    return (
      <Fragment>
        <Row>
          <Col s={12} m={6} offset="m3">
            <select
              onChange={(e) => this.updateColor(e)}
              defaultValue=""
              style={{ display: 'block' }}
            >
              <option value="" disabled>
                Please select a color
              </option>
              {colors.map((color) => {
                return (
                  <option key={color} value={color}>
                    {color}
                  </option>
                );
              })}
            </select>
          </Col>
        </Row>
      </Fragment>
    );
  }

  loadingCarInfo() {
    const {
      service_records_count,
      recalls_count,
      tire_sizes,
      oil_types,
      infoLoaded,
    } = this.state;
    let tire_size_display = null;
    if (tire_sizes) {
      if (tire_sizes.length > 2) {
        tire_size_display = `${tire_sizes.length} compatible sizes`;
      } else {
        tire_size_display = tire_sizes.join(', ');
      }
    }
    const oil_types_display =
      oil_types && oil_types.length > 3
        ? `${oil_types.length} compatible types`
        : oil_types;
    const columnWidth = 3;
    const imageStyle = { maxWidth: '100%' };
    const IMGIX_OPTIONS = 'auto=format,compress';
    const IMGIX_URL = 'https://zippity.imgix.net/booking-app';
    const carfaxText = `${recalls_count === null ? '-' : recalls_count} Recalls
    ${
      service_records_count === 0
        ? ''
        : `${'\n'}${
            service_records_count === null ? '-' : service_records_count
          } CarFax Records`
    }`;
    const vehicleInfo = [
      {
        urlPartial: 'prod-1560276185428-carfax.png',
        alt: 'carfax',
        text: carfaxText,
        loaded: infoLoaded[0],
      },
      {
        urlPartial: 'prod-1560276213619-maintenance_schedule.png',
        alt: 'maintenance_schedule',
        text: 'Maintenance Schedule',
        loaded: infoLoaded[1],
      },
      {
        urlPartial: 'prod-1560276239431-recommended_oil.png',
        alt: 'recomended oil',
        text: `Oil Type:${'\n'}${oil_types_display || '-'}`,
        loaded: infoLoaded[2],
      },
      {
        urlPartial: 'prod-1560276252881-tire_size.png',
        alt: 'tire size',
        text: `Tire Size:${'\n'}${tire_size_display || '-'}`,
        loaded: infoLoaded[3],
      },
    ];

    return (
      <Fragment>
        <Col s={12} style={{ textAlign: 'center' }}>
          <h3>
            {infoLoaded.every((val) => val === true)
              ? 'Here’s the data for your vehicle:'
              : 'Loading manufacturer’s data for your vehicle…'}
          </h3>
        </Col>
        <Col s={12} l={8} offset="l2" style={{ textAlign: 'center' }}>
          <br />
          <div className="row vehicle-data">
            {vehicleInfo.map((info) => (
              <Col s={columnWidth} key={info.alt}>
                {info.text.split('\n').map((text) => {
                  return (
                    <div key={text} style={{ textAlign: 'center' }}>
                      {text}
                    </div>
                  );
                })}
              </Col>
            ))}
          </div>

          <div className="row">
            {vehicleInfo.map((info) => (
              <Col s={columnWidth}>
                <div style={{ position: 'relative', width: '100%' }}>
                  <img
                    src={`${IMGIX_URL}/${info.urlPartial}?${IMGIX_OPTIONS}`}
                    alt={info.alt}
                    style={imageStyle}
                  />
                  <div className="vehicle-info-icon-overlay vehicle-info-received-icon-overlay">
                    <span
                      className="vehicle-info-icon-overlay vehicle-info-received-icon-overlay"
                      style={{ opacity: info.loaded ? 1 : 0 }}
                    >
                      <Icon className="vehicle-info-received-icon medium vehicle-info-icon-mask">
                        brightness_1
                      </Icon>
                      <Icon className="vehicle-info-received-icon medium">
                        check_circle
                      </Icon>
                    </span>
                    {!info.loaded && <Loading />}
                  </div>
                </div>
              </Col>
            ))}
          </div>
        </Col>
      </Fragment>
    );
  }

  loadingCarImage() {
    const { image_link, vehicleString } = this.state;
    return (
      <Fragment>
        <span className="flow-text">{vehicleString || 'Loading...'}</span>
        <br />
        {image_link ? (
          <img
            className="grayscale-img responsive-img"
            style={{ display: 'inline-block' }}
            src={image_link}
            alt="your car model"
          />
        ) : (
          <div style={{ width: 288, height: 144, display: 'inline-block' }}>
            -
          </div>
        )}
      </Fragment>
    );
  }

  confirmOrManual() {
    return (
      <Fragment>
        <div className="row">
          <Col s={12}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {this.submitButton('Continue')}
            </div>
          </Col>
        </div>
        <div className="row">
          <Col s={12}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <button
                type="button"
                className="button-to-link"
                onClick={() => browserHistory.push('/pricing/vehicle-lookup')}
              >
                Not right? Try Again
              </button>
            </div>
          </Col>
        </div>
      </Fragment>
    );
  }

  submitButton(buttonText) {
    const { allowContinue, errorInvalidVehicleMake } = this.state;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          clear: 'both',
        }}
      >
        <Button
          className="waves-effect waves-light btn-large"
          onClick={this.handleSubmit}
          disabled={!allowContinue || errorInvalidVehicleMake}
        >
          <strong>{buttonText}</strong>
        </Button>
      </div>
    );
  }

  vehicle_info() {
    return (
      <Fragment>
        <Row style={{ marginBottom: '0px' }}>{this.loadingCarInfo()}</Row>
        <Row>
          <Col s={12} style={{ textAlign: 'center' }}>
            {this.loadingCarImage()}
          </Col>
        </Row>
      </Fragment>
    );
  }

  render() {
    const { errorInvalidVehicleMake, vehicleMake } = this.state;
    const { z3pConfiguration } = this.props;
    const { customer_facing_phone: serviceProviderPhone } = z3pConfiguration;
    return (
      <Pricing
        currentStep="Vehicle"
        h1="Tell us about your vehicle."
        h2="Verify your vehicle's information."
        hideAlert
        showMotorAttribution
      >
        {errorInvalidVehicleMake && (
          <CardPanel className="red lighten-4">
            <strong>
              Sorry, we do not currently offer mobile service for {vehicleMake}{' '}
              vehicles. To schedule in-house, please call {serviceProviderPhone}
              .
            </strong>
          </CardPanel>
        )}
        {this.vehicle_info()}
        {this.colorSelection()}
        {this.confirmOrManual()}
      </Pricing>
    );
  }
}

function mapStateToProps(state) {
  return {
    customerLocation: state.ui.customerLocation,
    account: state.ui.account,
    pricing: state.ui.pricing,
    clientLocations: state.ui.clientLocations,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(VehicleInfoFromLookup);
