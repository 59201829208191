import React, { useState } from 'react';
import { Modal, Button, Preloader } from 'react-materialize';
import { apiPut } from '../brainApi';
import { addMessage } from '../zippity';

const RemoveUserModal = (props) => {
  const {
    dispatch,
    isModalOpen,
    setIsModalOpen,
    users,
    userId,
    setUsers,
  } = props;

  const [isRemoving, setIsRemoving] = useState(false);

  const handleRemove = () => {
    const payload = { is_deleted: true };
    setIsRemoving(true);
    apiPut(`/user/${userId}`, payload).then(() => {
      const updatedUsers = users.filter((u) => u.id !== userId);
      setUsers(updatedUsers);
      setIsModalOpen(false);
      addMessage(dispatch, 'User removed successfully', 10);
      setIsRemoving(false);
    });
  };

  return (
    <Modal
      header="Are you sure you want to remove this user?"
      open={isModalOpen}
      options={{ onCloseStart: () => setIsModalOpen(false) }}
      actions={[
        <Button
          onClick={() => setIsModalOpen(false)}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>,
        <Button onClick={handleRemove}>
          {isRemoving && <Preloader size="small" />}
          <div>Remove</div>
        </Button>,
      ]}
    />
  );
};

export default RemoveUserModal;
