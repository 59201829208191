import React, { useState } from 'react';
import { Button, Card, Col, Dropdown, Icon } from 'react-materialize';
import ConfirmActionModal from '../components/ConfirmActionModal';
import ErrorModal from '../components/ErrorModal';
import { apiPut } from '../../brainApi';

const VehicleMenu = ({ id, refreshVehicles, scheduledWorks }) => {
  const [deleteVehicleModalOpen, setDeleteVehicleModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const checkForUpcomingServices = () => {
    const upcomingServices = scheduledWorks.filter(
      (sw) =>
        sw.vehicle_id === id &&
        ['scheduled', 'keys_available'].includes(sw.status),
    );
    if (upcomingServices.length === 0) {
      setDeleteVehicleModalOpen(true);
    } else {
      setErrorModalOpen(true);
    }
  };

  const deleteVehicle = () => {
    apiPut(`/vehicle/${id}`, {
      is_deleted: true,
    }).then(() => {
      setDeleteVehicleModalOpen(false);
      refreshVehicles();
    });
  };

  return (
    <>
      <Dropdown
        id={`Dropdown_${id}`}
        trigger={
          <span className="cardMenu">
            <Icon>more_vert</Icon>
          </span>
        }
      >
        <p className="dropdownMenuItem" onClick={checkForUpcomingServices}>
          <Icon>delete</Icon> Delete
        </p>
      </Dropdown>
      <ErrorModal
        error={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        errorText="This vehicle is scheduled for an upcoming service and cannot be deleted. If you would like to delete this vehicle, please cancel the upcoming service."
      />
      <ConfirmActionModal
        open={deleteVehicleModalOpen}
        mainText="Are you sure you want to delete this vehicle?"
        confirmText="Delete"
        onConfirm={deleteVehicle}
        onCancel={() => {
          setDeleteVehicleModalOpen(false);
        }}
      />
    </>
  );
};

const VehicleCard = ({
  id,
  name,
  vin,
  licensePlateNumber,
  licensePlateState,
  bookServiceCallback,
  refreshVehicles,
  scheduledWorks,
}) => {
  return (
    <Col l={4} m={6} s={12}>
      <Card key={id} className="vehicleCard" align="center" title={name}>
        <VehicleMenu
          id={id}
          refreshVehicles={refreshVehicles}
          scheduledWorks={scheduledWorks}
        />
        {vin && <p>VIN: {vin}</p>}
        {licensePlateNumber && (
          <p>
            Plate: {licensePlateState} {licensePlateNumber}
          </p>
        )}
        <Button
          className="btn-teal btn"
          onClick={() => bookServiceCallback()}
        >
          Book Service
        </Button>
      </Card>
    </Col>
  );
};

export default VehicleCard;
