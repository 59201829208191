/*
 * Modal that requires user to confirm an action they're about to take.
 *
 * As a design principle, you should set the confirmText prop to tell the user what action they're about to take.
 * For example, if they're about to delete an address, confirmText should be "Delete Address".
 */

import React from 'react';
import { Button, Modal } from 'react-materialize';

const ConfirmActionModal = ({
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  mainText = 'Are you sure?',
  subText = null,
  onConfirm,
  onCancel,
  open = true,
}) => {
  return (
    <Modal
      className="confirm_modal"
      header={mainText}
      open={open}
      options={{
        onCloseStart: onCancel,
      }}
      actions={[
        <Button
          className="btn-teal grey cancel-or-delete-button"
          style={{ marginRight: 20 }}
          onClick={onCancel}
        >
          {cancelText}
        </Button>,
        <Button className="red lighten-1" onClick={onConfirm}>
          {confirmText}
        </Button>,
      ]}
    >
      <p>{subText}</p>
    </Modal>
  );
};

export default ConfirmActionModal;
