/* globals alert dataLayer */

import { apiPost, apiPut } from './brainApi';

export const isNonAutomobileType = (vehicleType) => {
  return ['rv', 'atv', 'motorcycle', 'plane', 'boat'].includes(vehicleType);
};

export const TAB_TO_TYPE = {
  rv: 'rv',
  atv: 'atv',
  motorcycle: 'motorcycle',
  boat: 'boat',
  plane: 'plane',
  year: 'automobile',
  vin: 'automobile',
  license: 'automobile',
  heavy: 'automobile',
};

// The definition of an 'automobile' here differs slightly from that in Admin/Tech. Here it includes 'heavy' because we want to render it as a tab, not a button
export const AUTOMOBILE_TABS = ['vin', 'license', 'year', 'heavy'];

export const getVehicleType = (vehicle) => {
  if (!vehicle || !vehicle.input_method_type) return null;
  return TAB_TO_TYPE[vehicle.input_method_type];
};

// Create a new vehicle in Brain2 (and select it)
export function createVehicle(newVehicle, customerId, dispatch, vehicles) {
  const payload = { ...newVehicle };
  payload.customer_id = customerId;

  // Set model and color to null if they are empty strings
  payload.model = !newVehicle.model ? null : newVehicle.model;
  payload.color = !newVehicle.color ? null : newVehicle.color;

  return apiPost('/vehicle', payload).then((response) => {
    if (response.status !== 200) {
      response.text().then((x) => {
        alert(`Critical Error: ${x}`);
      });
      return null;
    }

    return response.json().then((json) => {
      const analyticsEvent = {
        event: 'Vehicle Added',
        event_category: 'Vehicle',
        event_label: 'Vehicle Added',
        vehicle_make: newVehicle.make,
        vehicle_model: newVehicle.model,
        vehicle_year: newVehicle.year,
      };

      // Push to dataLayer to be picked up by GTM
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push(analyticsEvent);
      }

      // Add gtag event to be picked up by Google Analytics
      if (window && window.gtag && window.location) {
        window.gtag('event', 'Vehicle Added', analyticsEvent);
      }

      dispatch({
        type: 'SELECT_VEHICLE',
        vehicle: json,
      });
      const vehicleType = getVehicleType(json);
      dispatch({
        type: 'SELECT_VEHICLE_TYPE',
        vehicleType,
      });

      vehicles.push(json);
      dispatch({
        type: 'SET_VEHICLES',
        vehicles,
      });
      return json;
    });
  });
}

// This is a pretty "forgiving" vehicle match algorithm.
// As long as YMMT and color are the same, we count the vehicles as the same.
// (even if the engines / MOTOR ids are different)
// If you don't want this behavior, use "createVehicle" instead of "findOrCreateVehicleId"
function vehiclesMatch(v1, v2) {
  const fields = ['year', 'make', 'model', 'trim', 'color'];
  return fields.every((field) => v1[field] === v2[field]);
}

// Use this when we want a Brain2 vehicleId
// (meaning the vehicle needs to actually be persisted to Brain2)
// If we already have a "matching" vehicle in Redux vehicles, use that.
// Otherwise, actually make the call to Brain2 to store the vehicle.
export function findOrCreateVehicleId(
  newVehicle,
  customerId,
  dispatch,
  vehicles,
) {
  // This means that we already had the vehicleId
  if (newVehicle.vehicle_id) {
    return newVehicle.vehicle_id;
  }

  const matchingVehicles = vehicles.filter(
    (vehicle) =>
      vehicle && vehicle.vehicle_id && vehiclesMatch(vehicle, newVehicle),
  );
  if (matchingVehicles && matchingVehicles.length) {
    dispatch({
      type: 'SELECT_VEHICLE',
      vehicle: matchingVehicles[0],
    });
    const vehicleType = getVehicleType(matchingVehicles[0]);
    dispatch({
      type: 'SELECT_VEHICLE_TYPE',
      vehicleType,
    });
    return matchingVehicles[0].vehicle_id;
  }

  return createVehicle(
    newVehicle,
    customerId,
    dispatch,
    vehicles,
  ).then((response) => (response ? response.vehicle_id : null));
}

export function uploadVehicleImages(file){
    try {
      
      return apiPost('/s3-upload', file).then((response) => {
           return response;
      });

    } catch (error) {
      console.error("Error sending file to API:", error);
    }
};

export function deleteVehicleImage(file){
  try {
    
    return apiPut('/s3-delete', file).then((response) => {
         return response;
    });

  } catch (error) {
    console.error("Error sending file to API:", error);
  }
};
