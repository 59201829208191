/* globals window document */
import React, { useEffect, useState } from 'react';
import { Icon, Modal, Button } from 'react-materialize';
import { connect } from 'react-redux';

const prodOriginsThatUseStagingSquare = [
  'https://app-service-shop-usa.zippitycars.com',
  'https://service-shop-usa.bookzippity.com',
  'https://booking-demo-mobile-mechanic.zippity.cc',
  'https://demo-mobile-mechanic.bookzippity.com',
  'https://booking-demo-mobile-tire.zippity.cc',
  'https://demo-mobile-tire.bookzippity.com',
  'https://booking-demo-mobile-detailing.zippity.cc',
  'https://demo-mobile-detailing.bookzippity.com',
  'https://demo-pet-grooming.bookzippity.com',
  'https://demo-barber-shop.bookzippity.com',
  'https://demo-bike-repair.bookzippity.com',
  'https://demo-computer-services.bookzippity.com',
  'https://demo-salon.bookzippity.com',
  'https://demo-cell-phone-repair.bookzippity.com',
  'https://demo-massage.bookzippity.com',
  'https://demo-carpet-cleaning.bookzippity.com',
  'https://demo-mobile-services.bookzippity.com',
  'https://demo-mobile-tire-no-atd.bookzippity.com',
];

// default Square config to staging
const square = {
  appId: 'sandbox-sq0idb-xZGgYpCYozNAeo9ofWOcKg',
  locationId: 'LGGZFQ2AMT14H',
};
if (
  process.env.REACT_APP_ENVIRO === 'production' &&
  !prodOriginsThatUseStagingSquare.includes(document.location.origin)
) {
  square.appId = 'sq0idp-2FLjz7ZlFd_q7h3uxRnXOw';
  square.locationId = 'LMA2A9V2QSE94';
}

// This function tokenizes a payment method.
// The ‘error’ thrown from this async function denotes a failed tokenization,
// which is due to buyer error (such as an expired card). It is up to the
// developer to handle the error and provide the buyer the chance to fix
// their mistakes.
async function tokenize(paymentMethod) {
  const tokenResult = await paymentMethod.tokenize();
  if (tokenResult.status === 'OK') {
    return tokenResult.token;
  }
  let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
  if (tokenResult.errors) {
    errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
  }
  throw new Error(errorMessage);
}

const SquareModal = (props) => {
  const [card, setCard] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);

  const {
    onToken,
    openButtonText,
    submitButtonText,
    z3pConfiguration,
    disabled = false,
    paymentFailed = false,
    textStyleButton = false,
    fromOnlineAppointmentPage = false,
  } = props;
  const { customer_facing_name: serviceProviderName } = z3pConfiguration;

  const initializeCard = (payments) => {
    payments.card().then((c) => {
      c.attach('#card-container').then(() => {
        setCard(c);
      });
    });
  };

  // Listen for any errors from the card form, and use them to disable the submit button.
  useEffect(() => {
    if (card) {
      card.addEventListener('errorClassAdded', () => {
        setHasError(true);
      });
      card.addEventListener('errorClassRemoved', () => {
        setHasError(false);
      });
    }
  }, [card]);

  const handlePaymentMethodSubmission = (paymentMethod) => {
    setIsSubmitting(true);
    tokenize(paymentMethod)
      .then((token) => {
        onToken(token);
        setIsModalOpen(false);
        setIsSubmitting(false);
      })
      .catch((e) => {
        console.error(e.message);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    // When you open/close the Square modal, we should reset any errors.
    setHasError(false);

    if (!window.Square) {
      throw new Error('Square.js failed to load properly');
    }
    const payments = window.Square.payments(square.appId, square.locationId);

    if (isModalOpen) {
      // When you open the Square modal, we should initialize the card form.
      try {
        initializeCard(payments);
      } catch (e) {
        console.error('Initializing Card failed', e);
      }
    } else if (card) {
      // When you close the Square modal, it should clear credit card details.
      card.destroy();
      setCard(null);
    }
  }, [isModalOpen]);

  return (
    <>
      {fromOnlineAppointmentPage ? (
        <Button
          small
          flat={textStyleButton}
          type="button"
          onClick={() => setIsModalOpen(true)}
          disabled={disabled}
          className={
            textStyleButton
              ? `add-payment-text-btn ${paymentFailed ? 'error' : ''}`
              : 'add-payment-btn'
          }
        >
          {paymentFailed ? 'Invalid Credit Card...' : openButtonText}
        </Button>
      ) : (
        <Button
          className={paymentFailed ? 'btn-red' : 'btn-teal'}
          onClick={() => setIsModalOpen(true)}
          disabled={disabled}
        >
          {paymentFailed ? 'Invalid Credit Card...' : openButtonText}
        </Button>
      )}
      <Modal
        id="modal-square"
        header=""
        open={isModalOpen}
        options={{ onCloseStart: () => setIsModalOpen(false) }}
        actions=""
      >
      <span className="close_btn"
          onClick={() => setIsModalOpen(false)}
        >
          <Icon className="check-icon" small>
            close
          </Icon>
        </span>
        <form id="payment-form">
          <div>
            {serviceProviderName}
          </div>
          <p className="card_note">
            This is just a hold on your card, you will not be charged until your appointment is complete.
          </p>
          <div id="card-container" />

          <button
            id="card-button"
            type="button"
            className="btn btn-teal"
            onClick={() => handlePaymentMethodSubmission(card)}
            disabled={hasError || isSubmitting}
          >
            {submitButtonText}
          </button>
        </form>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(SquareModal);
