import Moment from 'moment';
import { roundToTwoDecimals, Z3P_CONFIG } from './helper';

function getValidServices(valid_service_offering_ids, services) {
  if (valid_service_offering_ids && valid_service_offering_ids.length > 0) {
    return services.filter((s) =>
      valid_service_offering_ids.includes(s.service_offering_id),
    );
  }
  return [];
}

export function couponIsValid(
  coupon,
  services,
  clientLocationId = null,
  bookingCreatedDate = Moment(),
) {
  if (!coupon || !coupon.coupon_code) {
    return false;
  }
  const couponCode = coupon.coupon_code.toUpperCase();
  console.log(`Checking couponIsValid: ${couponCode}`);

  const {
    start_date,
    end_date,
    valid_service_offering_ids,
    valid_client_location_ids,
  } = coupon;

  // Check valid dates
  const bookingCreatedString = bookingCreatedDate
    ? bookingCreatedDate.format('YYYY-MM-DD')
    : '?';
  if (start_date && bookingCreatedDate.isBefore(start_date, 'day')) {
    console.log(
      `Invalid start date. ${bookingCreatedString} is before ${start_date}.`,
    );
    return false;
  }
  if (end_date && bookingCreatedDate.isAfter(end_date, 'day')) {
    console.log(
      `Invalid end date. ${bookingCreatedString} is after ${end_date}.`,
    );
    return false;
  }

  // Also check valid service offering ids, if that restriction is present.
  if (valid_service_offering_ids && valid_service_offering_ids.length > 0) {
    const validServices = getValidServices(
      valid_service_offering_ids,
      services,
    );
    if (!validServices || validServices.length === 0) {
      console.log('Invalid services');
      return false;
    }
  }

  // Also check client location id, if that restriction is present.
  if (valid_client_location_ids && valid_client_location_ids.length > 0) {
    const validClientLocationIds = valid_client_location_ids.map((s) =>
      parseInt(s, 10),
    );
    if (clientLocationId) {
      const myLocationId = parseInt(clientLocationId, 10);
      if (!validClientLocationIds.some((l) => l === myLocationId)) {
        console.log('Invalid location');
        return false;
      }
    }
  }

  return true;
}

const calculateSuppliesFeeRaw = (percentage, total, minimum, maximum) => {
  const value = percentage * total;
  const cappedValue = Math.max(minimum, Math.min(maximum, value));
  return cappedValue;
};

export function calculateCosts(
  coupon,
  services = [],
  clientLocationId = null,
  taxRatePercent = 0,
  bookingCreatedDate = Moment(),
  tipAmount = 0,
  customDiscount = {},
) {
  const z3pClient = Z3P_CONFIG.z3p_client_name;
  const minimumServiceThresholdAmountDollars =
    Z3P_CONFIG.minimum_service_threshold_amount_dollars;
  const getTotalCostDollars = (prices) =>
    prices
      .map((p) => parseFloat(p.total_cost_dollars) * (p.quantity || 1))
      .reduce((sum, x) => sum + x, 0);

  const originalTotalDollars = getTotalCostDollars(services);

  const discounts = [];
  let couponDiscountDollars = 0;
  let totalDiscountDollars = 0;

  if (coupon && coupon.coupon_code) {
    const couponCode = coupon.coupon_code.toUpperCase();

    const isValid = couponIsValid(
      coupon,
      services,
      clientLocationId,
      bookingCreatedDate,
    );
    console.log(`Coupon ${couponCode} is ${isValid ? 'VALID' : 'NOT VALID'}`);

    // Note: We disallow any one coupon from giving both amountOffDollars and amountOffPercent.
    if (isValid) {
      const {
        amount_off_dollars: amountOffDollars,
        amount_off_percent: amountOffPercent,
        applies_to_full_bill: appliesToFullBill,
      } = coupon;

      const validServices = getValidServices(
        coupon.valid_service_offering_ids,
        services,
      );
      if (appliesToFullBill) {
        if (amountOffDollars > 0) {
          discounts.push(`-$${amountOffDollars.toFixed(2)} for ${couponCode}`);
          couponDiscountDollars += amountOffDollars;
        } else if (amountOffPercent > 0) {
          discounts.push(`-${amountOffPercent}% for ${couponCode}`);
          couponDiscountDollars += roundToTwoDecimals(
            originalTotalDollars * (amountOffPercent / 100.0),
          );
        }
      } else if (!appliesToFullBill && validServices.length > 0) {
        if (amountOffDollars > 0) {
          validServices.forEach((s) => {
            discounts.push(
              `-$${amountOffDollars.toFixed(2)} on ${s.display_name ||
                s.long_name} for ${couponCode}`,
            );
            couponDiscountDollars += amountOffDollars;
          });
        } else if (amountOffPercent > 0) {
          validServices.forEach((s) => {
            // It looks weird to say 100% off of a service.
            // Instead, we write out the dollar amount off.
            const discount = roundToTwoDecimals(
              s.total_cost_dollars *
                (s.quantity || 1) *
                (amountOffPercent / 100),
            );
            discounts.push(
              `-$${discount.toFixed(2)} on ${s.display_name ||
                s.long_name} for ${couponCode}`,
            );
            couponDiscountDollars += discount;
          });
        }
      }
    } else {
      console.log('Invalid coupon.');
    }
  }

  totalDiscountDollars += couponDiscountDollars;

  // Add custom discount from pro to total discount
  const customDiscountAmountDollars =
    customDiscount?.customDiscountAmountDollars || 0;
  totalDiscountDollars += customDiscountAmountDollars;

  const customDiscountReason = customDiscount?.customDiscountReason;

  if (customDiscountAmountDollars > 0) {
    // If there's a reason (ex: "New customer"):
    // '-$20.00 for New customer'
    // If there's no reason:
    // '-$20.00 (Custom Discount)
    discounts.push(
      `-$${customDiscountAmountDollars.toFixed(2)} ${
        customDiscountReason
          ? `for ${customDiscountReason}`
          : '(Custom Discount)'
      }`,
    );
  }

  // Calculate supplies fee
  const totalPartsCostDollars = services.reduce(
    (sum, next) =>
      sum + parseFloat(next.parts_cost_dollars) * (next.quantity || 1),
    0,
  );
  const partsFraction = totalPartsCostDollars / originalTotalDollars || 0;
  const discountedTotalDollars = Math.max(
    originalTotalDollars - totalDiscountDollars,
    0,
  );
  const partsFractionDiscountDollars = partsFraction * totalDiscountDollars;
  const discountedPartsCostDollars =
    totalPartsCostDollars - partsFractionDiscountDollars;

  const clientGroup1 = [
    'zippity',
    'uvngo',
    'brakes-on-demand',
    'timely-tire',
    'flat-on-the-spot',
    'quick-tires',
    'the-mobile-mechanic-service',
  ];
  const clientGroup2 = ['capital-auto-group'];

  // Default supplies fee is 0
  let estimatedSuppliesFeeDollars = 0;
  if (clientGroup1.includes(z3pClient)) {
    // Group 1: 10.85% of post-discount parts cost, with a minimum of $0 and a maximum of $20
    estimatedSuppliesFeeDollars = calculateSuppliesFeeRaw(
      0.1085,
      discountedPartsCostDollars,
      0,
      20,
    );
  } else if (clientGroup2.includes(z3pClient)) {
    // Group 2: 17.3% of post-discount total cost (parts + labor), with a minimum of $0 and a maximum of $50
    estimatedSuppliesFeeDollars = calculateSuppliesFeeRaw(
      0.173,
      discountedTotalDollars,
      0,
      50,
    );
  }

  // Calculate sales tax
  const bundledServices = services.filter(
    (service) => service.tax_type === 'bundled',
  );
  const itemizedServices = services.filter(
    (service) => service.tax_type === 'itemized',
  );
  const bundledTaxableAmountDollars = bundledServices.reduce(
    (sum, next) =>
      sum + parseFloat(next.total_cost_dollars) * (next.quantity || 1),
    0,
  );
  const itemizedTaxableAmountDollars = itemizedServices.reduce(
    (sum, next) =>
      sum + parseFloat(next.parts_cost_dollars) * (next.quantity || 1),
    0,
  );

  const taxableAmountDollars =
    bundledTaxableAmountDollars + itemizedTaxableAmountDollars;
  const taxableFraction = taxableAmountDollars / originalTotalDollars;
  const taxableFractionDiscountDollars = totalDiscountDollars * taxableFraction;
  const discountedTaxableAmountDollars =
    taxableAmountDollars - taxableFractionDiscountDollars;

  // Example: taxRatePercent is 7% -> taxRateDecimal is 0.07
  const taxRateDecimal = taxRatePercent / 100.0;
  const estimatedSalesTaxDollars =
    discountedTaxableAmountDollars * taxRateDecimal || 0;

  // Include tip amount
  const tipAmountDollars = tipAmount;

  const subtotalWithTaxesAndFees =
    originalTotalDollars +
    estimatedSuppliesFeeDollars +
    estimatedSalesTaxDollars;

  // If there is minimum service threshold and total < threshold,
  // calculate service charge as difference between the two
  const minimumServiceChargeAmountDollars =
    minimumServiceThresholdAmountDollars &&
    subtotalWithTaxesAndFees < minimumServiceThresholdAmountDollars
      ? minimumServiceThresholdAmountDollars - subtotalWithTaxesAndFees
      : 0;

  const finalTaxedTotalDollars =
    discountedTotalDollars +
    estimatedSuppliesFeeDollars +
    estimatedSalesTaxDollars +
    minimumServiceChargeAmountDollars +
    tipAmountDollars;

  return {
    originalTotalDollars: roundToTwoDecimals(originalTotalDollars),
    discountedTotalDollars: roundToTwoDecimals(discountedTotalDollars),
    discounts,
    discountAmountDollars: roundToTwoDecimals(totalDiscountDollars),
    tipAmountDollars: roundToTwoDecimals(tipAmountDollars),
    estimatedSuppliesFeeDollars: roundToTwoDecimals(
      estimatedSuppliesFeeDollars,
    ),
    estimatedSalesTaxDollars: roundToTwoDecimals(estimatedSalesTaxDollars),
    minimumServiceChargeAmountDollars: roundToTwoDecimals(
      minimumServiceChargeAmountDollars,
    ),
    finalTaxedTotalDollars: roundToTwoDecimals(finalTaxedTotalDollars),
    couponDiscountDollars: roundToTwoDecimals(couponDiscountDollars),
  };
}
