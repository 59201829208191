import React, { useState } from 'react';
import { Switch } from 'react-materialize';
import { priceOr } from '../../helper';

const UpsellItem = ({ service, showTotalOnly, handleApprovalButtonClick }) => {
  const [approved, setApproved] = useState(true);
  const onToggle = (event) => {
    setApproved(!approved);
    if (event.target.checked) {
      handleApprovalButtonClick(service, 'approved');
    } else {
      handleApprovalButtonClick(service, 'rejected');
    }
  };

  return (
    <tr className="upsells-list">
      <div className="small-screen-wrap">
        <td style={{ textAlign: 'left', marginLeft: '3px' }}>
          <strong>
            {service.display_name ? service.display_name : service.long_name}
            &nbsp;&nbsp;
          </strong>
          {service.marketing_url && (
            <small>
              <a
                href={service.marketing_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </a>
            </small>
          )}
          {service.employee_note && (
            <p>
              Technician Note: <i>{service.employee_note}</i>
            </p>
          )}
        </td>
        {!showTotalOnly && (
          <>
            <td>{priceOr(service.parts_cost_dollars, '0.00')}</td>
            <td>{priceOr(service.labor_cost_dollars, '0.00')}</td>
          </>
        )}

        <td>{priceOr(service.total_cost_dollars, '0.00')}</td>
      </div>
      <div className="small-screen-wrap">
        <td width="180">
          {/* Switch to toggle between approving and rejecting the upsell service */}
          <Switch
            checked={approved}
            id={service.id}
            offLabel="Not Today"
            onChange={onToggle}
            onLabel="Approve"
          />
        </td>
      </div>
    </tr>
  );
};

export default UpsellItem;
