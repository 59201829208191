import { apiPut } from './brainApi';
import { addMessage } from './zippity';

export default function cancelService(
  reason,
  details,
  feeToCharge,
  setAllowSelfServiceCancellation,
  setErrorMessage,
  sw,
  dispatch,
  setCancelling,
  setCancelServiceModalOpen,
  refreshScheduledWorks = () => {},
  setNewStatus = () => {},
  token = null,
) {
  setCancelling(true);
  apiPut(`/booking/scheduled-work/${sw.id}`, {
    status: 'cancelled',
    cancellation_reason: reason,
    cancellation_reason_details: details,
    cancellation_fee_to_charge: feeToCharge,
    token,
  })
    .then((response) => {
      setCancelling(false);
      if (response.status !== 200) {
        response.json().then((r) => {
          setErrorMessage(r?.Message);
        });
        setAllowSelfServiceCancellation(false);
      } else {
        setCancelServiceModalOpen(false);
        addMessage(dispatch, 'Your booking was successfully cancelled', 10);
        refreshScheduledWorks();
        setNewStatus('cancelled');
      }
    })
    .catch(() => {
      setCancelling(false);
      setAllowSelfServiceCancellation(false);
    });
}
