import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {
  mediaQueryMaxWidth600,
  roundMinutesDown,
  roundMinutesUp,
  roundToTwoDecimals,
} from '../../helper';
import {
  loadCompatibleDates,
  loadCompatibleDatesForHomeAddress,
} from '../../brainApi';
import Loading from './Loading';

const CompatibleDateTimeSelector = ({
  cart,
  customerLocation,
  useHomeAddress,
  homeAddress,
  selectScheduleInstance,
  dispatch,
  selectedDate,
  displayWaitlistForm,
  startTime,
  setStartTime,
  schedule,
  areCompatibleDatesLoading,
  setAreCompatibleDatesLoading,
  changeBooking,
  compatibleDates,
  tz,
}) => {
  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [viewRange, setViewRange] = useState(['08:00:00', '17:00:00']);
  const [showWeekends, setShowWeekends] = useState(false);
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState();
  const [showPicker, setShowPicker] = useState(false);
  const [firstDayInWeek, setFirstDayInWeek] = useState(null);
  const [isLoadingNextWeek, setIsLoadingNextWeek] = useState(false);
  const [hasStartTime, setHasStartTime] = useState(false);
  const calendarRef = useRef(null);

  useEffect(() => {
    let startDate = Moment()
      .startOf('week')
      .format('YYYY-MM-DD');
    let endDate = Moment()
      .add(1, 'week')
      .endOf('week')
      .format('YYYY-MM-DD');
    if (selectedDate) {
      startDate = Moment(selectedDate)
        .startOf('week')
        .format('YYYY-MM-DD');
      endDate = Moment(selectedDate)
        .add(1, 'week')
        .endOf('week')
        .format('YYYY-MM-DD');
    }
    setFirstDayInWeek(startDate);

    if (useHomeAddress) {
      loadCompatibleDatesForHomeAddress(
        homeAddress.addressIDSelected,
        cart,
        startDate,
        endDate,
      ).then((newCompatibleDates) => {
        if (
          newCompatibleDates.length > 0 &&
          !!newCompatibleDates.find(
            (s) => s.is_available && s.available_time_ranges?.length > 0,
          )
        ) {
          setCompatibleDates(newCompatibleDates, true);
        } else {
          // If there is no availability in the first range, get availability for a full year
          endDate = Moment()
            .add(1, 'year')
            .endOf('week')
            .format('YYYY-MM-DD');
          loadCompatibleDatesForHomeAddress(
            homeAddress.addressIDSelected,
            cart,
            startDate,
            endDate,
          ).then((newCompatibleDates) => {
            setCompatibleDates(newCompatibleDates, true);
          });
        }
      });
    } else {
      // Fetch a fresh schedule from the server
      loadCompatibleDates(
        customerLocation.clientLocationId,
        cart,
        startDate,
        endDate,
      ).then((newCompatibleDates) => {
        if (
          newCompatibleDates.length > 0 &&
          !!newCompatibleDates.find(
            (s) => s.is_available && s.available_time_ranges?.length > 0,
          )
        ) {
          setCompatibleDates(newCompatibleDates, true);
        } else {
          // If there is no availability in the first range, get availability for a full year
          endDate = Moment()
            .add(1, 'year')
            .endOf('week')
            .format('YYYY-MM-DD');
          loadCompatibleDates(
            customerLocation.clientLocationId,
            cart,
            startDate,
            endDate,
          ).then((newCompatibleDates) => {
            setCompatibleDates(newCompatibleDates, true);
          });
        }
      });
    }
  }, []);

  const setAvailableDatesAndTimes = (newCompatibleDates) => {
    const newAvailableDates = newCompatibleDates.filter((d) => d.is_available);
    newAvailableDates.sort((a, b) => new Date(a.date) - new Date(b.date));
    const newAvailableTimes = [];
    newAvailableDates.forEach((date) => {
      if (date.available_time_ranges)
        date.available_time_ranges.forEach((timeRange) => {
          const startOfRange = Moment(timeRange[0])
            .tz(tz)
            .format();
          // Subtract the last buffer time in a time range to make it appear unbookable
          // Round down so if buffer time is something like 25 minutes, there aren't 5 minutes of available time left in the range
          const endOfRange = roundMinutesDown(
            Moment(timeRange[1])
              .tz(tz)
              .subtract(date.buffer_time_minutes, 'minutes'),
          ).format();
          newAvailableTimes.push({
            groupId: 'availableForAppointment',
            start: startOfRange,
            end: endOfRange,
            display: 'inverse-background',
            color: 'lightgray',
            className: 'unavailable-times',
          });
        });
    });
    newAvailableTimes.sort(
      (a, b) => Moment(a.start).valueOf() - Moment(b.start).valueOf(),
    );
    setAvailableDates(newAvailableDates);
    setAvailableTimes(newAvailableTimes);
    setViewVariables(newAvailableTimes);
  };

  const setCompatibleDates = (newCompatibleDates, isInitialLoad = false) => {
    // For the initial load, only use new dates to avoid stale data
    const allCompatibleDates = isInitialLoad
      ? newCompatibleDates
      : [...compatibleDates, ...newCompatibleDates];
    // Remove duplicates to only add values not already saved
    const allUniqueCompatibleDates = _.uniqBy(allCompatibleDates, (item) =>
      [item.date, item.service_vehicle_id].join(),
    );

    setAvailableDatesAndTimes(allUniqueCompatibleDates);
    dispatch({
      type: 'SET_COMPATIBLE_DATES',
      compatibleDates: allUniqueCompatibleDates,
    });

    if (isInitialLoad && !selectedDate) {
      // Autoselect the first available date and time
      const newAvailableDates = newCompatibleDates.filter(
        (d) => d.is_available && d.available_time_ranges?.length > 0,
      );
      newAvailableDates.sort((a, b) => new Date(a.date) - new Date(b.date));
      if (newAvailableDates.length > 0 && !displayWaitlistForm) {
        selectScheduleInstance(newAvailableDates[0], null, true);
      }
    }
  };

  useEffect(() => {
    if (availableTimes && availableTimes.length > 0) {
      setInitialStartTime();

      // TODO: Attempted to fix issue where start time was
      // first available window start time. Might need to readdress
      // this in the future (https://app.shortcut.com/zippity/story/1721)
      setHasStartTime(true);
    }
  }, [availableTimes]);

  const setInitialStartTime = (firstDate = null, lastDate = null) => {
    const availableTimesInView =
      firstDate && lastDate
        ? availableTimes.filter(
            (t) =>
              Moment(t.start).format('YYYYMMDD') >=
                firstDate.format('YYYYMMDD') &&
              Moment(t.end).format('YYYYMMDD') <= lastDate.format('YYYYMMDD'),
          )
        : null;
    const timeToTrySelectingObj =
      availableTimesInView?.length > 0
        ? availableTimesInView[0]
        : availableTimes[0];

    const timeToTrySelecting = Moment(timeToTrySelectingObj.start);

    // If rescheduling, set original appointment as initial start time
    if (changeBooking && schedule.confirmedAppointmentStartTime) {
      const originalDateTime = Moment(
        `${schedule.date}T${schedule.confirmedAppointmentStartTime}`,
      );
      setStartTime(originalDateTime);
    } else {
      selectTime(timeToTrySelecting);
    }
  };

  const setViewVariables = (times) => {
    let earliestTime = viewRange[0];
    let latestTime = viewRange[1];
    times.forEach((time) => {
      let start = Moment(time.start)
        .tz(tz)
        .subtract(1, 'hours')
        .startOf('hour');
      let end = Moment(time.end)
        .tz(tz)
        .add(1, 'hours')
        .endOf('hour');
      if (!start.isSame(Moment(time.start), 'day'))
        start = Moment(time.start)
          .tz(tz)
          .startOf('day');
      if (!end.isSame(Moment(time.end), 'day'))
        end = Moment(time.end)
          .tz(tz)
          .endOf('day');
      start = Moment(start)
        .tz(tz)
        .format('HH:mm:00');
      end = Moment(end)
        .tz(tz)
        .format('HH:mm:00');
      if (start < earliestTime) earliestTime = start;
      if (end > latestTime) latestTime = end;
      if (isWeekend(Moment(time.start))) setShowWeekends(true);
    });
    setAreCompatibleDatesLoading(false);
    setViewRange([earliestTime, latestTime]);
  };

  const isWeekend = (datetime) => datetime.isoWeekday() > 5;

  const timeIsAvailable = (datetime) => {
    const end = calculateEndTime(datetime);
    return availableTimes.some(
      (timeObj) =>
        Moment(timeObj.start) <= datetime && Moment(timeObj.end) >= end,
    );
  };

  const selectTime = (datetime, revert = () => {}) => {
    if (!datetime) return false;
    const datetimeAsDate = datetime.clone().format('YYYY-MM-DD');
    const datetimeAsTime = datetime.clone().format('HH:mm');
    const scheduleInstanceToSelect = availableDates.find(
      (si) =>
        si?.date === datetimeAsDate &&
        si?.available_start_times
          ?.map((t) => Moment(t).format('HH:mm'))
          .includes(datetimeAsTime),
    );
    if (scheduleInstanceToSelect && timeIsAvailable(datetime)) {
      selectScheduleInstance(scheduleInstanceToSelect);
      setStartTime(datetime);
    } else {
      revert();
    }
  };

  const calculateAppointmentDurationMinutes = () => {
    const appointmentDurationMinutes =
      cart && cart.length > 0
        ? roundToTwoDecimals(
            cart.reduce(
              (total, service) =>
                total + service.labor_hours * (service.quantity || 1),
              0,
            ) * 60,
          )
        : 0;
    return appointmentDurationMinutes;
  };

  const calculateEndTime = (start) =>
    start
      ? roundMinutesUp(
          Moment(start).add(calculateAppointmentDurationMinutes(), 'minutes'),
        )
      : null;

  if (areCompatibleDatesLoading) return <Loading />;

  if (!availableTimes || availableTimes.length < 1)
    return (
      <div className="schedule-calendar-wrapper" style={{ height: 400 }}>
        <p>
          Sorry, there are no available dates for you to make an appointment at
          your address for the services you selected.
        </p>
      </div>
    );

  const endTime = calculateEndTime(startTime);

  const appointmentEvent =
    startTime && endTime
      ? {
          title:
            calculateAppointmentDurationMinutes() <= 15
              ? ''
              : 'Your Appointment',
          start: startTime.format(),
          end: endTime.format(),
          constraint: 'availableForAppointment',
          isActiveAppointment: true,
        }
      : {};

  const handleDateChange = (dateInfo) => {
    let active = true;
    const setTime = () => {
      if (active) {
        setInitialStartTime(Moment(dateInfo.startStr), Moment(dateInfo.endStr));
        setTitle(dateInfo.view.title);
        setStartDate(new Date(dateInfo.start));
      }
    };
    setTime();

    // Load the next 2 weeks each time a user navigates to the previous/next week
    const startDate = Moment(dateInfo.start)
      .startOf('week')
      .format('YYYY-MM-DD');
    const endDate = Moment(dateInfo.start)
      .add(1, 'week')
      .endOf('week')
      .format('YYYY-MM-DD');

    if (firstDayInWeek && firstDayInWeek !== startDate) {
      setFirstDayInWeek(startDate);
      setIsLoadingNextWeek(true);
      let getDatesForLocation;
      if (useHomeAddress) {
        getDatesForLocation = loadCompatibleDatesForHomeAddress(
          homeAddress.addressIDSelected,
          cart,
          startDate,
          endDate,
        );
      } else {
        getDatesForLocation = loadCompatibleDates(
          customerLocation.clientLocationId,
          cart,
          startDate,
          endDate,
        );
      }
      getDatesForLocation
        .then((newCompatibleDates) => {
          setIsLoadingNextWeek(false);
          if (active) {
            setCompatibleDates(newCompatibleDates, false);
          }
        })
        .then(() => {
          setTime();
        });
    }

    // Use this active flag as a cleanup to avoid race conditions
    return () => (active = false);
  };

  return (
    <div
      className="schedule-calendar-wrapper pin-top"
      style={{ height: mediaQueryMaxWidth600().matches ? 400 : 600 }}
    >
      <FullCalendar
        plugins={[timeGridPlugin, interactionPlugin, momentTimezonePlugin]}
        timeZone={tz}
        initialView="timeGridWeek"
        allDaySlot={false}
        datesSet={(dateInfo) => handleDateChange(dateInfo)}
        eventContent={(e) => {
          // Only show a loading indicator on the appointment to be scheduled when loading current week availability
          if (
            isLoadingNextWeek &&
            e.event &&
            e.event.extendedProps &&
            e.event.extendedProps.isActiveAppointment
          ) {
            return (
              <div>
                <div>{e.timeText}</div>
                <div
                  className="spinner"
                  style={{
                    width: '10px',
                    height: '20px',
                    position: 'absolute',
                    left: '45%',
                    bottom: '10%',
                  }}
                >
                  <div
                    className="bubble-1"
                    style={{ width: '8px', height: '8px' }}
                  />
                  <div
                    className="bubble-2"
                    style={{ width: '8px', height: '8px' }}
                  />
                </div>
              </div>
            );
          }
        }}
        ref={calendarRef}
        customButtons={{
          myCustomTitle: {
            text: title,
            click() {
              setShowPicker((prev) => !prev);
            },
          },
          disabledNavigationPrev: {
            click() {},
          },
          disabledNavigationNext: {
            click() {},
          },
        }}
        buttonIcons={{
          disabledNavigationPrev: 'chevron-left',
          disabledNavigationNext: 'chevron-right',
        }}
        headerToolbar={{
          left: isLoadingNextWeek ? 'disabledNavigationPrev' : 'prev',
          center: 'myCustomTitle',
          right: isLoadingNextWeek ? 'disabledNavigationNext' : 'next',
        }}
        initialDate={
          selectedDate ||
          (!!availableDates &&
            availableDates.length > 0 &&
            availableDates[0]?.date)
        }
        height="100%"
        businessHours={false}
        editable={!isLoadingNextWeek}
        eventDurationEditable={false}
        defaultTimedEventDuration="00:15"
        eventMinHeight={15}
        eventShortHeight={15}
        slotDuration="00:30:00"
        snapDuration="00:15:00"
        slotMinTime={viewRange[0]}
        slotMaxTime={viewRange[1]}
        slotLabelInterval="01:00"
        scrollTime={startTime?.format('HH:mm:00')}
        longPressDelay={0}
        eventDrop={({ event: { startStr }, revert }) =>
          selectTime(Moment(startStr), revert)
        }
        dateClick={(data) => {
          if (isLoadingNextWeek) return;
          selectTime(Moment(data.dateStr));
        }}
        expandRows
        weekends={showWeekends}
        dayHeaderContent={(arg) => ({
          html: Moment(arg.date)
            .tz(tz)
            .format('ddd <br/> MM/DD'),
        })}
        events={[
          appointmentEvent,
          // Areas where "Your Appointment" must be dropped
          ...availableTimes,
        ]}
      />
      {showPicker && hasStartTime && (
        <div className="picker-calendar">
          <Calendar
            value={startDate}
            onChange={(date) => {
              setShowPicker(false);
              setStartDate(date);
              calendarRef.current.getApi().gotoDate(date);
            }}
            minDate={new Date()}
            minDetail="year"
          />
        </div>
      )}
      <p>
        Click anywhere on the schedule or drag your appointment to select a
        time.
      </p>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    cart: state.ui.cart,
    changeBooking: state.ui.changeBooking,
    compatibleDates: state.ui.compatibleDates,
    customerLocation: state.ui.customerLocation,
    homeAddress: state.ui.homeAddress,
    schedule: state.ui.schedule,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(CompatibleDateTimeSelector);
