/* globals window */
import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { Col } from 'react-materialize';
import update from 'immutability-helper';

import Pricing from '../components/Pricing';
import { loadAtdProducts, hasAccessToken } from '../../brainApi';
import TireFilter from './TireFilter';
import TireRecommendation from './TireRecommendation';
import {
  getATDTireProduct,
  getTireInstallationFee,
} from '../../serviceHelpers';

import Loading from '../components/Loading';
import { setPageTitle } from '../../helper';

class NewTires extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: null,
      filters: {},
      sortSelection: 'recommendations',
      width: window.innerWidth,
    };

    this.addToCart = this.addToCart.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    setPageTitle('Select New Tires');
  }

  componentDidMount() {
    this.createFilters();
    const {
      tireDetails,
      dispatch,
      tireProducts,
      z3pConfiguration,
    } = this.props;

    const { atd_location_id } = z3pConfiguration;
    // Default to East Taunton MA ATD location, if customer has not set their location.
    const atdLocationId = atd_location_id || 1258934;
    const key = `${atdLocationId}|${tireDetails.tireSize}`;

    // First, see if there's a cached response
    if (tireProducts && tireProducts[key]) {
      this.setState({ products: tireProducts[key] }, this.createFilters);
    }

    // Always fetch a new result, even if we use a cached value
    loadAtdProducts(atdLocationId, tireDetails.tireSize)
      .then(({ products }) => {
        // In the future, we could also pull "recommendations" from this response
        dispatch({
          type: 'SET_TIRE_PRODUCTS',
          key,
          tireProducts: products,
        });
        this.setState({ products: products || [] }, this.createFilters);
      })
      .catch(() => {
        this.setState({ products: [] });
      });
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  compareRecommendations = (a, b) => {
    if (!a.recommendation && !b.recommendation) {
      return a.ATDProductNumber.localeCompare(b.ATDProductNumber);
    }
    if (!a.recommendation) {
      return 1;
    }
    if (!b.recommendation) {
      return -1;
    }

    // Both have a recommendation. Go in order ['premium', 'standard', 'value']
    return a.recommendation.localeCompare(b.recommendation);
  };

  compareRatings = (a, b, key) => {
    const ratingOrder = ['AA', 'A', 'B', 'C'];
    const aVal = a.productSpec[key] || '';
    const bVal = b.productSpec[key] || '';
    const aIndex = ratingOrder.indexOf(aVal);
    const bIndex = ratingOrder.indexOf(bVal);

    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    }
    return bVal.localeCompare(aVal);
  };

  arrayToObject = (array) => {
    const obj = {};
    array.forEach((k) => {
      obj[k] = true;
    });
    return obj;
  };

  createService = (product, serviceOfferingId) => {
    const { price, ATDProductNumber } = product;
    const retailPrice = price.retail || price.cost; // Ideally, every product should have price.retail

    return {
      service_offering_id: serviceOfferingId,
      atd_product_number: ATDProductNumber,
      long_name: `${product.brand} ${product.style}`,
      short_name: `${product.brand} ${product.style}`,
      display_name: `${product.brand} ${product.style}`,
      schedule_types: ['tires'],

      labor_cost_dollars: 0,
      parts_cost_dollars: retailPrice,
      atd_parts_cost_dollars: retailPrice,
      total_cost_dollars: retailPrice,
      original_value_dollars: null,
      variable_pricing: false,
      tax_type: 'itemized',

      labor_hours: 0,
    };
  };

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  toggleFilter(category, value) {
    const { filters } = this.state;
    const copy = update(filters, { [category]: { $toggle: [value] } });
    this.setState({ filters: copy });
  }

  createFilters() {
    const { products } = this.state;
    const { pricing } = this.props;
    const { make } = pricing;

    if (!products || !products.length) {
      return;
    }

    const brands = this.arrayToObject(products.map((p) => p.brand));
    const specs = products.map((p) => p.productSpec);
    const seasonals = this.arrayToObject(
      specs.map((s) => s.SeasonalDesignation),
    );
    const temps = this.arrayToObject(
      specs.map((s) => s.TemperatureRating || 'Not listed'),
    );
    const hasOeRecommendation = products.some((p) => p.OEMakes.includes(make));
    // const productGroups = this.arrayToObject(products.map((p) => p.productGroup));
    // const loadRanges = this.arrayToObject(specs.map((s) => s.LoadRange));

    const filters = {
      Brand: brands,
      Seasonal: seasonals,
      'Temperature Rating': temps,
    };
    if (hasOeRecommendation) {
      filters['OE Recommended'] = { Yes: false };
    }
    this.setState({ filters });
  }

  // Add this tire product service to the cart, with quantity "numberOfTires".
  // Also, if there is a tireInstallationFee, we add that with the same quantity "numberOfTires".
  addToCart(numberOfTires, product) {
    const { dispatch, cart, priceList } = this.props;

    const tireInstallationFee = getTireInstallationFee(priceList);
    const atdTireProduct = getATDTireProduct(priceList);

    // April 2022: Right now, in production, our Pros use both tireInstallationFee (215) and atdTireProduct (88).
    // But we would like to simplify to just one service.
    // So we want to handle the situation where a Pro only has one of these.

    const newTireServiceOfferingIds = [];
    if (atdTireProduct) {
      newTireServiceOfferingIds.push(atdTireProduct.service_offering_id);
    }
    // Not else-if
    if (tireInstallationFee) {
      newTireServiceOfferingIds.push(tireInstallationFee.service_offering_id);
    }

    const tireProductService = this.createService(
      product,
      newTireServiceOfferingIds[0],
    );

    // Find "otherServices" -- that is, services that are in the cart that are not the
    // "tireProductService" or "tireInstallationFee".
    const otherServices = cart.filter(
      (s) => !newTireServiceOfferingIds.includes(s.service_offering_id),
    );

    // Build the "updatedCart" -- combine "otherServices" with the correct number of "newTire" services.
    const updatedCart = otherServices;

    for (let i = 0; i < numberOfTires; i += 1) {
      updatedCart.push(tireProductService);
      if (tireInstallationFee) {
        updatedCart.push(tireInstallationFee);
      }
    }

    dispatch({ type: 'SET_CART', cart: updatedCart });

    // If the user is logged in, we can skip the "Account Creation" page.
    if (hasAccessToken()) {
      return browserHistory.push('/pricing/schedule');
    }

    return browserHistory.push('/pricing/account');
  }

  render() {
    const { products, filters, sortSelection, width } = this.state;
    const { pricing, z3pConfiguration } = this.props;
    const { customer_facing_phone: phone } = z3pConfiguration;
    const { make } = pricing;

    let filteredProducts = products || [];
    if (products && products.length) {
      // Use filters
      if (filters.Brand) {
        filteredProducts = filteredProducts.filter(
          (p) => filters.Brand[p.brand],
        );
      }
      if (filters.Seasonal) {
        filteredProducts = filteredProducts.filter(
          (p) => filters.Seasonal[p.productSpec.SeasonalDesignation],
        );
      }
      if (filters['Temperature Rating']) {
        filteredProducts = filteredProducts.filter(
          (p) =>
            filters['Temperature Rating'][
              p.productSpec.TemperatureRating || 'Not listed'
            ],
        );
      }
      if (filters['OE Recommended'] && filters['OE Recommended'].Yes) {
        filteredProducts = filteredProducts.filter((p) =>
          p.OEMakes.includes(make),
        );
      }

      // Use sorts
      if (sortSelection === 'recommendations') {
        filteredProducts.sort((a, b) => this.compareRecommendations(a, b));
      } else if (sortSelection === 'priceAsc') {
        filteredProducts.sort((a, b) => a.price.cost - b.price.cost);
      } else if (sortSelection === 'priceDesc') {
        filteredProducts.sort((a, b) => b.price.cost - a.price.cost);
      } else if (sortSelection === 'treadDesc') {
        filteredProducts.sort((a, b) => this.compareRatings(a, b, 'TreadWear'));
      } else if (sortSelection === 'tractionDesc') {
        filteredProducts.sort((a, b) =>
          this.compareRatings(a, b, 'TractionRating'),
        );
      } else if (sortSelection === 'warrantyDesc') {
        filteredProducts.sort((a, b) =>
          this.compareRatings(a, b, 'MileageWarranty'),
        );
      }
    }

    const noResults = (
      <p>
        Sorry, we couldn&apos;t find any tires for your vehicle. But we can
        still help you find the right tires!
        <br />
        Please call us at {phone}.
      </p>
    );

    return (
      <Pricing currentStep="Services" h1="Select Your Services">
        <Col
          s={12}
          m={3}
          l={2}
          className="tireFilterColumn"
          style={{ padding: '0' }}
        >
          <h5>Sort</h5>

          <select
            name="tireSort"
            value={sortSelection}
            className="browser-default"
            onChange={(e) => this.setState({ sortSelection: e.target.value })}
          >
            <option value="recommendations">Recommended</option>
            <option value="priceAsc">Price (low to high)</option>
            <option value="priceDesc">Price (high to low)</option>
            <option value="tractionDesc">Traction Rating (high to low)</option>
            <option value="treadDesc">Tread Wear Rating (high to low)</option>
            <option value="warrantyDesc">Warranty (high to low)</option>
          </select>

          {/* Only show filters on medium/large screens */}
          {width > 600 && (
            <TireFilter filters={filters} toggleFilter={this.toggleFilter} />
          )}
        </Col>
        <Col s={12} m={9} l={10} className="tireProductColumn">
          {products === null && <Loading />}
          {products && !products.length && noResults}
          {filteredProducts &&
            !!filteredProducts.length &&
            filteredProducts.map((p) => (
              <TireRecommendation
                key={p.ATDProductNumber}
                product={p}
                addToCart={this.addToCart}
                sortSelection={sortSelection}
              />
            ))}
        </Col>
        {products && !!products.length && (
          <span className="attribution-text" style={{ marginBottom: '-50px' }}>
            Icons made by Smashicons and Freepik, from www.flaticon.com
          </span>
        )}
      </Pricing>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.ui.account,
    priceList: state.ui.priceList,
    cart: state.ui.cart,
    tireDetails: state.ui.tireDetails,
    pricing: state.ui.pricing,
    customerLocation: state.ui.customerLocation,
    tireProducts: state.ui.tireProducts,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(NewTires);
