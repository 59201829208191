import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-materialize';
import Zippity from './components/Zippity';
import { apiGet } from '../brainApi';
import UserModal from './UserModal';
import RemoveUserModal from './RemoveUserModal';
import convertPhoneFormat from './userHelper';
import { addMessage } from '../zippity';

const Settings = (props) => {
  const {
    z3pConfiguration: { allows_subusers: allowsSubusers },
    dispatch,
  } = props;

  const [subusers, setSubusers] = useState([]);
  const [primary, setPrimary] = useState(null);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [contactIsNew, setContactIsNew] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [userToEdit, setUserToEdit] = useState({});
  const [isPrimary, setIsPrimary] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openRemoveUser, setOpenRemoveUser] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const compareSubusers = (a, b) => a.id - b.id;

  const setSortedSubusers = useCallback((users) => {
    setSubusers(users.sort(compareSubusers));
  }, []);

  useEffect(() => {
    apiGet('/customer-info').then((res) =>
      res.json().then((json) => {
        const { customer } = json;
        const primaryUser = {
          firstName: customer.first_name,
          lastName: customer.last_name,
          email: customer.email,
          phone: convertPhoneFormat(customer.phone),
          id: customer.id,
        };
        setPrimary(primaryUser);
        if (allowsSubusers) {
          apiGet(`/customer/${customer.id}/subusers`).then((res2) =>
            res2.json().then((json2) => {
              const fetchedSubusers = json2
                .filter((subuser) => !subuser.is_deleted)
                .map((subuser) => ({
                  firstName: subuser.first_name,
                  lastName: subuser.last_name,
                  email: subuser.username,
                  id: subuser.id,
                }));
              setSortedSubusers(fetchedSubusers);
              setIsLoading(false);
            }),
          );
        } else {
          setIsLoading(false);
        }
      }),
    );
  }, [allowsSubusers]);

  const handleClickNewUser = () => {
    setSelectedUser({ firstName: '', lastName: '', email: '' });
    setContactIsNew(true);
    setIsUserModalOpen(true);
  };

  const handleEdit = (userId, editPrimary = false) => {
    const thisUser = editPrimary
      ? primary
      : subusers.find((u) => u.id === userId);
    setIsPrimary(editPrimary);
    setUserToEdit(thisUser);
    setSelectedUser({ ...thisUser });
    setIsUserModalOpen(true);
  };

  const handleOpenRemoveUser = (userId) => {
    if (userId === primary.id) {
      addMessage(
        dispatch,
        'Must switch primary account owner before removing.',
        10,
        { messageType: 'error' },
      );
    } else {
      setSelectedUserId(userId);
      setOpenRemoveUser(true);
    }
  };

  return isLoading ? (
    <h1 className="center-align">Loading...</h1>
  ) : (
    <Zippity>
      <div className="setting_section">
      <h3>Account Settings</h3>
      {isSaving ? (
        <h3>Loading...</h3>
      ) : (
        <>
          {allowsSubusers && <h3>Primary User</h3>}
          <table>
            <tr>
              <th style={{ textAlign: 'left' }} width="20%">
                NAME
              </th>
              <th>EMAIL</th>
              <th>PHONE</th>
              <th />
            </tr>
            {primary && (
              <tr>
                <td>{`${primary.firstName} ${primary.lastName}`}</td>
                <td>{primary.email}</td>
                <td>{primary.phone}</td>
                <td style={{ textAlign: 'right' }}>
                  <Button small onClick={() => handleEdit(primary.id, true)} className="btn">
                    Edit
                  </Button>
                </td>
              </tr>
            )}
          </table>
          {allowsSubusers && (
            <>
              <h3>Additional Users</h3>
              <table>
                {subusers.length > 0 && (
                  <tr>
                    <th style={{ textAlign: 'left' }} width="20%">
                      NAME
                    </th>
                    <th>EMAIL</th>
                    <th />
                  </tr>
                )}
                {subusers.map((user) => (
                  <tr>
                    <td>{`${user.firstName} ${user.lastName}`}</td>
                    <td>{user.email}</td>
                    <td style={{ textAlign: 'right' }}>
                      <Button
                        small
                        onClick={() => handleEdit(user.id)}
                        style={{ marginRight: 10 }}
                        className="btn"
                      >
                        Edit
                      </Button>
                      <Button
                        small
                        onClick={() => handleOpenRemoveUser(user.id)}
                        className="btn"
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
              </table>
              <Button
                small
                onClick={handleClickNewUser}
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                Add New User
              </Button>
            </>
          )}
        </>
      )}

      <UserModal
        isUserModalOpen={isUserModalOpen}
        setIsUserModalOpen={setIsUserModalOpen}
        contactIsNew={contactIsNew}
        setContactIsNew={setContactIsNew}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        subusers={subusers}
        setSubusers={setSortedSubusers}
        primary={primary}
        setPrimary={setPrimary}
        userToEdit={userToEdit}
        isPrimary={isPrimary}
        setIsPrimary={setIsPrimary}
        allowsSubusers={allowsSubusers}
        isSaving={isSaving}
        setIsSaving={setIsSaving}
        dispatch={dispatch}
      />
      <RemoveUserModal
        isModalOpen={openRemoveUser}
        setIsModalOpen={setOpenRemoveUser}
        users={subusers}
        userId={selectedUserId}
        setUsers={setSortedSubusers}
        dispatch={dispatch}
      />
      </div>
    </Zippity>
  );
};

function mapStateToProps(state) {
  return {
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(Settings);
