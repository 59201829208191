import React from 'react';
import { Checkbox } from 'react-materialize';

const CancellationPolicyCheckbox = (props) => {
  const {
    cancellationPolicyIsChecked,
    setCancellationPolicyIsChecked,
    setCancellationPolicyModalOpen,
  } = props;

  const handleCheck = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setCancellationPolicyIsChecked(value);
  };

  const label = (
    <label
      htmlFor="cancellation_policy_acceptance"
      style={{ fontSize: '1rem' }}
    >
      By checking this box you agree to the cancellation policy outlined{' '}
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setCancellationPolicyModalOpen(true);
        }}
      >
        here
      </a>
      .
    </label>
  );

  return (
    <Checkbox
      id="cancellation_policy_acceptance"
      onChange={(e) => handleCheck(e)}
      checked={cancellationPolicyIsChecked}
      label={label}
    />
  );
};

export default CancellationPolicyCheckbox;
