import Slider from 'rc-slider';
import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { Col, Icon, Modal, Row, Switch, Textarea } from 'react-materialize';

import Pricing from '../components/Pricing';
import {
  getServiceById,
  getServiceByName,
  getServicesByScheduleType,
} from '../../serviceHelpers';
import { hasAccessToken } from '../../brainApi';
import { formatPrice, setPageTitle } from '../../helper';

import 'rc-slider/assets/index.css';

const dirtyStatuses = {
  0: 'Very Clean',
  1: 'Clean',
  2: 'Somewhat Dirty',
  3: 'Dirty',
  4: 'Very Dirty',
};

class DetailingMore extends Component {
  constructor(props) {
    super(props);

    const { priceList, cart } = props;
    const serviceOfferingIdsInCart = cart.map((s) => s.service_offering_id);

    const detailingServices = getServicesByScheduleType(
      priceList,
      'detailing',
    ).filter((a) => a.display_category === 'addon');

    const serviceOfferingIdToService = {};
    detailingServices.forEach((s) => {
      serviceOfferingIdToService[s.service_offering_id] = s;
    });

    this.detailingAddonServices = Object.values(serviceOfferingIdToService);
    this.detailingAddonServices = this.detailingAddonServices.filter(
      (s) =>
        !s.incompatible_service_offering_ids ||
        !s.incompatible_service_offering_ids.some((incompat_id) =>
          serviceOfferingIdsInCart.includes(incompat_id),
        ),
    );

    this.detailingAddonServices.sort((a, b) => {
      if (a.display_order < b.display_order) {
        return -1;
      }
      return 1;
    });

    // Some services are included by default. Add them to the cart.
    const includedServices = this.detailingAddonServices.filter(
      (s) => !!s.included_with_every_mechanical_service,
    );
    props.dispatch({ type: 'SET_CART', cart: [...cart, ...includedServices] });

    this.handleContinue = this.handleContinue.bind(this);
    this.toggleService = this.toggleService.bind(this);

    this.state = {
      dirtyIndicator: dirtyStatuses[2],
    };
  }

  componentWillMount() {
    setPageTitle('Detailing Services');
  }

  setDirtyIndicator = (index) => {
    this.setState({ dirtyIndicator: dirtyStatuses[index] });
  };

  updateDetailingNote = (prevNote, dirtyIndicator) => {
    const pattern = /^Vehicle is [a-zA-Z\s]*./;
    if (prevNote.match(pattern)) {
      // If "Vehicle Dirty Question" is already in the note, replace it.
      return prevNote.replace(pattern, `Vehicle is ${dirtyIndicator}.`);
    }
    // If "Vehicle Dirty Question" is not yet in the note, concatenate it to the note.
    return `Vehicle is ${dirtyIndicator}.\n${prevNote}`;
  };

  handleContinue() {
    const { dispatch, detailingNote, z3pConfiguration } = this.props;
    const { is_detailing_dirty_question_enabled } = z3pConfiguration;
    const { dirtyIndicator } = this.state;

    if (is_detailing_dirty_question_enabled) {
      const newDetailingNote = this.updateDetailingNote(
        detailingNote,
        dirtyIndicator,
      );
      dispatch({ type: 'SET_DETAILING_NOTE', detailingNote: newDetailingNote });
    }

    // If the customer is logged in, we can skip the "Account Creation" page.
    if (hasAccessToken()) {
      return browserHistory.push('/pricing/schedule');
    }
    return browserHistory.push('/pricing/account');
  }

  noteChange(value) {
    const { dispatch } = this.props;

    let detailingNote = '';
    if (value.length < 1020) {
      detailingNote = value;
    } else {
      detailingNote = value.substring(0, 1020);
    }

    dispatch({ type: 'SET_DETAILING_NOTE', detailingNote });
  }

  toggleService(service) {
    const { cart, dispatch } = this.props;
    const serviceIsInCart = !!getServiceById(cart, service.service_offering_id);
    // Don't allow user to deselect services that are always included
    if (service.included_with_every_mechanical_service) return;
    if (serviceIsInCart) {
      dispatch({
        type: 'SET_CART',
        cart: cart.filter(
          (s) => s.service_offering_id !== service.service_offering_id,
        ),
      });
    } else {
      cart.push(service);
      dispatch({ type: 'SET_CART', cart });
    }
  }

  renderNoteDescription = (containsScratchService, containsPaintService) => {
    const introText = `We do a thorough clean of the entire vehicle, but please let
    us know ahead of time if you want us to pay extra special attention to any
    particular area of your vehicle!`;

    // if the customer requests a scratch or paint service
    if (containsScratchService || containsPaintService) {
      // ask them to describe their scratches
      return (
        <p>
          {introText}
          <br />
          <strong>
            Paint Correction & Deep Scratch Removal Customers:
          </strong>{' '}
          Please describe which scratches you would like to repair and where
          they are on the vehicle.
        </p>
      );
    }

    // otherwise, just show them a generic message
    return (
      <p>
        {introText} If you can think of anything else that would help us provide
        you excellent service, let us know!
      </p>
    );
  };

  renderWhatYouNeedToKnow = () => {
    const { z3pConfiguration } = this.props;
    const { z3p_client_name: z3pEnv } = z3pConfiguration;

    if (z3pEnv === 'hoch-auto-detailing') {
      return (
        <>
          <li>Interior details are reserved for up to 4 hours of service.</li>
          <li>
            Please remove any bulk items in your vehicle to allow the detailer
            to start quicker.
          </li>
          <li>
            We ask that you take the opportunity to do a walk around with the
            detailer at the end of the service to review the work, touch
            anything up that may need to be fixed, and make sure you&apos;re
            happy with the job.
          </li>
          <li>
            An excessive amount of mold in the vehicle can result in an extra
            charge. Please contact us directly if you have mold in your
            interior.
          </li>
        </>
      );
    }

    return (
      <>
        <li>
          Some vehicles may required additional cleaning time based on their
          condition. If your vehicle requires extra time we will notify you and
          request approval.
        </li>
        <li>
          Please help us expedite service by removing your personal items{' '}
          {z3pEnv === 'armor-auto-detailing' && 'as well as car seats '}in your
          car before service. Additional charges may be applied if there are a
          large number of personal items left in the vehicle.
        </li>
      </>
    );
  };

  render() {
    const { detailingNote, cart, z3pConfiguration } = this.props;

    const containsScratchService = !!getServiceByName(cart, 'Scratch');
    const containsPaintService = !!getServiceByName(cart, 'Paint');
    const hasNote = detailingNote && detailingNote.length;
    const { is_detailing_dirty_question_enabled } = z3pConfiguration;

    return (
      <Pricing
        currentStep="Services"
        h1="Select Your Services"
        showContinue
        enableContinue={
          hasNote || (!containsScratchService && !containsPaintService)
        }
        onContinue={this.handleContinue}
      >
        <Row>
          <Col s={12} m={6}>
            <h5 className="teal-header">Service Details</h5>
            {cart
              .filter(
                (s) =>
                  s.display_category !== 'addon' &&
                  ((s.card_bullet_points && !!s.card_bullet_points.length) ||
                    s.details_blurb),
              )
              .map((s) => (
                <div key={s.service_offering_id}>
                  <strong>{s.short_name}</strong>
                  <br />
                  {s.card_bullet_points && !!s.card_bullet_points.length && (
                    <>
                      What’s Included:
                      <ul className="browser-default">
                        {s.card_bullet_points.map((bp) => (
                          <li key={bp}>{bp}</li>
                        ))}
                      </ul>
                    </>
                  )}
                  {s.details_blurb && <p>{s.details_blurb}</p>}
                </div>
              ))}
          </Col>
          {this.detailingAddonServices &&
            this.detailingAddonServices.length > 0 && (
              <Col s={12} m={6}>
                <h5 className="teal-header">Add-on Services</h5>
                {this.detailingAddonServices.map((s) => (
                  <div key={s.service_offering_id}>
                    <span className="left" style={{ marginTop: '-3px' }}>
                      <Modal
                        id={`modal${s.service_offering_id}`}
                        header={s.short_name}
                      >
                        {s.details_blurb}
                      </Modal>
                      <Switch
                        id={s.service_offering_id}
                        checked={!!getServiceById(cart, s.service_offering_id)}
                        onChange={() => this.toggleService(s)}
                      />
                    </span>
                    <span
                      className="dots-row"
                      onClick={() => this.toggleService(s)}
                    >
                      <span
                        className="left"
                        style={{ verticalAlign: 'middle' }}
                      >
                        <span>{s.short_name}</span>
                        {s.details_blurb && !!s.details_blurb.length && (
                          <span
                            waves="light"
                            href={`#modal${s.service_offering_id}`}
                            onClick={(e) => e.stopPropagation()}
                            className="modal-trigger"
                            style={{ marginLeft: '3px' }}
                          >
                            <Icon tiny>help</Icon>
                          </span>
                        )}
                      </span>
                      <span className="dots" />
                      <span className="right">
                        {s.total_cost_dollars <= 0
                          ? 'FREE'
                          : formatPrice(s.total_cost_dollars)}
                      </span>
                    </span>
                  </div>
                ))}
              </Col>
            )}
        </Row>
        <Row>
          <Col>
            {is_detailing_dirty_question_enabled && (
              <Row>
                <Col s={12} m={7}>
                  <h5 className="teal-header">How dirty is your vehicle?</h5>
                  <div style={{ padding: '30px 30px' }}>
                    <Slider
                      min={0}
                      max={Math.max(...Object.keys(dirtyStatuses))}
                      defaultValue={2}
                      marks={dirtyStatuses}
                      included={false}
                      onChange={this.setDirtyIndicator}
                    />
                  </div>
                </Col>
              </Row>
            )}
            <h5 className="teal-header">Notes</h5>
            {this.renderNoteDescription(
              containsScratchService,
              containsPaintService,
            )}
            <Textarea
              id="detailingNotes"
              name="detailingNotes"
              placeholder="Notes"
              value={detailingNote}
              s={12}
              onChange={(e) => this.noteChange(e.target.value)}
            />
            <p>
              <strong>What you need to know:</strong>
            </p>
            <ul className="browser-default">
              {this.renderWhatYouNeedToKnow()}
            </ul>
          </Col>
        </Row>
      </Pricing>
    );
  }
}

function mapStateToProps(state) {
  return {
    priceList: state.ui.priceList,
    detailingNote: state.ui.detailingNote,
    cart: state.ui.cart,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(DetailingMore);
