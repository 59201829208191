import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  TextInput,
  Preloader,
  Checkbox,
} from 'react-materialize';
import InputMask from 'react-input-mask';
import { apiPost, apiPut } from '../brainApi';
import { addMessage } from '../zippity';
import convertPhoneFormat from './userHelper';

const UserModal = (props) => {
  const {
    isUserModalOpen,
    setIsUserModalOpen,
    contactIsNew,
    setContactIsNew,
    selectedUser,
    setSelectedUser,
    dispatch,
    subusers,
    setSubusers,
    primary,
    setPrimary,
    userToEdit,
    isPrimary,
    setIsPrimary,
    allowsSubusers,
    isSaving,
    setIsSaving,
  } = props;

  const [firstNameIsValid, setFirstNameIsValid] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [phoneIsValid, setPhoneIsValid] = useState(false);
  const [isNewPrimary, setIsNewPrimary] = useState(false);

  const emailValidationRegExp = () =>
    new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  const isFormDirty = () =>
    userToEdit.firstName !== selectedUser.firstName ||
    userToEdit.lastName !== selectedUser.lastName ||
    userToEdit.email !== selectedUser.email ||
    userToEdit.phone !== selectedUser.phone;

  const emailHelperText = () => {
    if (emailIsValid) {
      return null;
    }
    return selectedUser.email?.length ? 'Invalid email address' : 'Required';
  };

  const phoneHelperText = () => {
    if (phoneIsValid) {
      return null;
    }
    return selectedUser.phone?.length ? 'Invalid phone length' : 'Required';
  };

  const updateCustomerOrUser = async (payload) => {
    const route = `/${isPrimary || isNewPrimary ? 'customer' : 'subuser'}/${
      isNewPrimary ? primary.id : selectedUser.id
    }`;
    try {
      setIsSaving(true);
      // If user the is changing AND becoming the primary, we need to update the user first
      if (isNewPrimary && isFormDirty() && !contactIsNew) {
        await apiPut(`/subuser/${selectedUser.id}`, payload);
      }
      const res = await apiPut(route, payload);
      if (res.Status === 'Failure') {
        addMessage(dispatch, res.Message, 10, { messageType: 'error' });
        return Promise.resolve();
      }
      const json = await res.json();
      addMessage(dispatch, 'Customer/user updated successfully', 10);
      const updatedCustomerInfo = {
        firstName: json.first_name,
        lastName: json.last_name,
        email: json.email || json.username,
        phone: convertPhoneFormat(json.phone),
        id: json.id,
      };
      if (isNewPrimary) {
        // Remove newPrimary from users
        const updatedUsers = subusers.filter((u) => u.id !== selectedUser.id);
        // Find old primary from list of subusers based on matching email
        // to use its id
        const oldPrimary = json.subusers.find(
          (u) => u.username === primary.email,
        );
        updatedUsers.push({
          ...primary,
          phone: '',
          id: oldPrimary.id,
        });
        setSubusers(updatedUsers);
      }
      if (isPrimary || isNewPrimary) {
        setPrimary({ ...updatedCustomerInfo });
      } else {
        const thisUser = subusers.find((u) => u.id === selectedUser.id);
        const userIndex = subusers.indexOf(thisUser);
        const updatedUsers = [...subusers];
        updatedUsers[userIndex] = {
          ...updatedCustomerInfo,
          id: selectedUser.id,
        };
        setSubusers(updatedUsers);
      }
      setIsPrimary(false);
      setIsNewPrimary(false);
      setContactIsNew(false);
      setIsUserModalOpen(false);
      return Promise.resolve();
    } catch (error) {
      addMessage(dispatch, error.message, 10, { messageType: 'error' });
      return Promise.resolve();
    }
  };

  const handleSave = async () => {
    const payload = {
      first_name: selectedUser.firstName,
      last_name: selectedUser.lastName || '',
      phone: selectedUser.phone,
    };
    payload[isPrimary || isNewPrimary ? 'email' : 'username'] =
      selectedUser.email;
    if (isNewPrimary) {
      payload.username = selectedUser.email;
      payload.skip_user = true;
    }
    if (contactIsNew) {
      payload.customer_id = primary.id;
      try {
        setIsSaving(true);
        const res = await apiPost(`/subuser`, payload);
        if (res.Status === 'Failure') {
          addMessage(dispatch, res.Message, 10, { messageType: 'error' });
        } else {
          const json = await res.json();
          addMessage(dispatch, 'User created successfully', 10);
          const updatedCustomerInfo = {
            firstName: json.first_name,
            lastName: json.last_name,
            email: json.username,
          };
          const updatedUsers = [...subusers];
          updatedUsers.push({
            ...updatedCustomerInfo,
            id: json.id,
          });
          setSubusers(updatedUsers);
          if (isNewPrimary) {
            await updateCustomerOrUser(payload);
          } else {
            setContactIsNew(false);
            setIsUserModalOpen(false);
          }
        }
      } catch (error) {
        addMessage(dispatch, error.message, 10, { messageType: 'error' });
      }
    } else {
      await updateCustomerOrUser(payload);
    }

    setIsSaving(false);
  };

  const handleCancel = () => {
    setIsUserModalOpen(false);
    setIsPrimary(false);
    setIsNewPrimary(false);
    setContactIsNew(false);
  };

  useEffect(() => {
    setFirstNameIsValid(!!selectedUser.firstName);
    setEmailIsValid(selectedUser.email?.match(emailValidationRegExp()));
    // Filters out non-numeric characters, ex. '+1(2)a 3-4' --> '1234'
    const numericPhone = selectedUser.phone?.replace(/[^0-9]/g, '');
    setPhoneIsValid([10, 11].includes(numericPhone?.length));
  }, [selectedUser]);

  const disableSaveButton = () =>
    isSaving ||
    !firstNameIsValid ||
    !emailIsValid ||
    ((isPrimary || isNewPrimary) && !phoneIsValid) ||
    !isFormDirty();

  return (
    <Modal
      className="user_modal"
      header={contactIsNew ? 'Add New User' : 'Edit User'}
      open={isUserModalOpen}
      options={{ onCloseStart: handleCancel }}
      actions={[
        <Button onClick={handleCancel} style={{ marginRight: 10 }}>
          Cancel
        </Button>,
        <Button onClick={handleSave} disabled={disableSaveButton()}>
          {isSaving && <Preloader size="small" />}
          <div>Save</div>
        </Button>,
      ]}
    >
      <TextInput
        label="First Name*"
        id="firstName"
        value={selectedUser.firstName}
        onChange={(e) => {
          setSelectedUser({ ...selectedUser, firstName: e.target.value });
        }}
        autoFocus
        s={10}
        m={6}
        l={4}
      />
      {!firstNameIsValid && (
        <div style={{ color: '#F44336', fontSize: 10 }}>Required</div>
      )}
      <TextInput
        label="Last Name"
        id="lastName"
        value={selectedUser.lastName}
        onChange={(e) => {
          setSelectedUser({ ...selectedUser, lastName: e.target.value });
        }}
        autoFocus
        s={10}
        m={6}
        l={4}
      />
      <TextInput
        label="Email*"
        id="email"
        value={selectedUser.email}
        onChange={(e) => {
          setSelectedUser({ ...selectedUser, email: e.target.value });
        }}
        autoFocus
        s={10}
        m={6}
        l={4}
      />
      {!emailIsValid && (
        <div style={{ color: '#F44336', fontSize: 10 }}>
          {emailHelperText()}
        </div>
      )}
      {(isPrimary || isNewPrimary) && (
        <InputMask
          mask="(999) 999-9999"
          label="Phone*"
          value={selectedUser.phone}
          onChange={(e) => {
            setSelectedUser({
              ...selectedUser,
              phone: e.target.value,
            });
          }}
          s={10}
          m={6}
          l={4}
        />
      )}
      {(isPrimary || isNewPrimary) && !phoneIsValid && (
        <div style={{ color: '#F44336', fontSize: 10 }}>
          {phoneHelperText()}
        </div>
      )}
      {allowsSubusers && (
        <Checkbox
          label="Primary Contact"
          checked={isPrimary || isNewPrimary}
          disabled={isPrimary}
          onChange={() => setIsNewPrimary(!isNewPrimary)}
        />
      )}
    </Modal>
  );
};

export default UserModal;
