import React from 'react';
import { TextInput } from 'react-materialize';
import Select from '../components/Select';
import { allUsStates } from '../components/UsStates';
import { formatLicenseVin } from '../../helper';

const sortedStates = allUsStates.sort((a, b) =>
  a.abbreviation > b.abbreviation ? 1 : -1,
);

const LicenseTab = (props) => {
  const {
    licensePlate,
    setLicensePlate,
    selectedState,
    setSelectedState,
  } = props;

  const handleLicensePlateChange = (e) => {
    const formattedLicensePlate = formatLicenseVin(e.target.value);

    setLicensePlate(formattedLicensePlate);
  };

  return (
    <>
      <TextInput
        label="License Plate *"
        placeholder="Enter License Plate"
        id="license"
        value={licensePlate}
        onChange={handleLicensePlateChange}
        inputClassName="vehicle-entry-text-field"
        autoFocus
        s={8}
        m={6}
        l={4}
      />
      <Select
        label="State *"
        id="us-state"
        value={selectedState}
        onChange={(e) => setSelectedState(e.target.value)}
        options={{
          classes: `vehicle-entry-dropdown ${
            selectedState === '' ? 'dropdown-placeholder' : ''
          }`,
        }}
        s={3}
        m={2}
        l={1}
      >
        <option key="disabled" value="" disabled>
          State
        </option>
        {sortedStates.map((state) => (
          <option key={state.abbreviation} value={state.name}>
            {state.abbreviation}
          </option>
        ))}
      </Select>
    </>
  );
};

export default LicenseTab;
