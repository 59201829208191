import React, { useEffect, useState } from 'react';
import { Button } from 'react-materialize';
import { apiPost } from '../../brainApi';
import { formatPrice } from '../../helper';
import QuoteAnswers from './QuoteAnswers';

const QuoteOption = ({ option, width, onSelect, isSelected = false }) => {
  const [financials, setFinancials] = useState({});
  const optionName = option.name || '';
  useEffect(() => {
    apiPost('/scheduled-work/cost-preview', {
      services_data: JSON.stringify(option.services),
    })
      .then((response) => response.json())
      .then(({ Data: financials }) => {
        setFinancials(financials);
      });
  }, []);

  return (
    <div
      className={`quote-option ${isSelected ? 'selected-quote-option' : ''}`}
      style={width ? { width } : {}}
      onClick={onSelect}
    >
      {!!(
        option.name ||
        option.label ||
        option.note ||
        option.answers.length > 0
      ) && (
        <div className="quote-option-top-half-container">
          {!!(option.name || option.label) && (
            <div className="quote-option-header-container">
              <h6>{optionName}</h6>
              <h6 className="quote-option-label">
                {option.label?.toUpperCase()}
              </h6>
            </div>
          )}
          <p>{option.note || ''}</p>
          <QuoteAnswers answers={option.answers} />
        </div>
      )}

      <div>
        {option.services?.length > 0 && (
          <>
            {option.services?.map(
              ({ display_name, parts_cost_dollars, labor_cost_dollars }) => (
                <div className="quote-option-service">
                  <span variant="body1">{display_name}</span>
                  <span variant="body1">
                    <strong>{`${formatPrice(
                      labor_cost_dollars + parts_cost_dollars,
                    )}`}</strong>
                  </span>
                </div>
              ),
            )}
          </>
        )}

        <hr className="quote-option-horizontal-line" />
        <div className="quote-option-subtotal">
          <span>
            <strong>
              {option.services?.length > 0
                ? `Subtotal: ${formatPrice(financials.subtotal_dollars)}`
                : 'No Services'}
            </strong>
          </span>
        </div>
      </div>

      <div className="quote-option-select-container">
        <Button
          className="btn-teal quote-option-select-button"
          disabled={isSelected}
        >
          Select{isSelected && 'ed'}
        </Button>
      </div>
    </div>
  );
};

export default QuoteOption;
