/* globals window */
import Raven from 'raven-js';
import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { Card, Col, Icon, Row, Button } from 'react-materialize';
import Moment from 'moment';
import Zippity from '../components/Zippity';
import UpdateCard from './UpdateCard';
import {
  apiGet,
  apiPut,
  clearAccessToken,
  hasAccessToken,
} from '../../brainApi';
import { loadClientLocations } from '../../brain2';
import {
  alphabeticalObjectSort,
  setPageTitle,
  setUserWorkplaceAndRedirect,
  shouldAutoSelectLocation,
} from '../../helper';
import ScheduledWorkCard from './ScheduledWorkCard';
import VehicleCard from './VehicleCard';
import AddressCard from '../components/AddressCard';
import { setUserWorkplace } from '../../placeHelper';
import { SQUARE, STRIPE } from '../../paymentProcessor';
import { getVehicleType } from '../../vehicleHelper';

class Garage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      addresses: [],
      isAddingNewWorkplace: false,
      limitUpcomingServiceList: true,
      limitPastServiceList: true,
      serviceLimit: 12,
    };
  }

  componentWillMount() {
    setPageTitle('My Account');
  }

  async componentDidMount() {
    const { dispatch, account } = this.props;

    if (!hasAccessToken()) {
      dispatch({ type: 'CLEAR_USER' });
      clearAccessToken();
      return browserHistory.push('/login');
    }

    this.fetchUserAddresses();

    loadClientLocations(dispatch);

    // Fetch all the latest data for the logged-in customer
    const response = await apiGet('/customer-info');

    if (response.status !== 200) {
      const msg =
        "Customer had token but couldn't retrieve data from Brain2. Remove token and send to login.";
      Raven.captureMessage(msg, { tags: { customerId: account.customer_id } });
      clearAccessToken();
      window.location = '/login';
    }

    const json = await response.json();
    if (json) {
      this.setState({ loading: false });
      const { customer, vehicles, scheduled_works: newScheduledWorks } = json;
      dispatch({ type: 'SET_ACCOUNT', account: customer });
      dispatch({ type: 'SET_VEHICLES', vehicles });
      dispatch({
        type: 'SET_SCHEDULED_WORKS',
        scheduledWorks: newScheduledWorks,
      });
    }

    return null;
  }

  fetchScheduledWorks = () => {
    const { dispatch } = this.props;
    apiGet('/customer-info')
      .then((res) => res.json())
      .then((json) => {
        const { scheduled_works } = json;
        dispatch({
          type: 'SET_SCHEDULED_WORKS',
          scheduledWorks: scheduled_works,
        });
      });
  };

  fetchUserAddresses = () => {
    return apiGet('/customer/address')
      .then((res) => res.json())
      .then((json) => {
        const { addresses } = json;
        const activeAddresses = addresses.filter((a) => !a.is_deleted);
        alphabeticalObjectSort(activeAddresses, 'display_name');
        this.setState({
          addresses: activeAddresses,
        });
      });
  };

  setWorkplaceAsync = (clientLocationId) => {
    const { dispatch, vehicle } = this.props;
    const vehicle_id = vehicle?.vehicle_id;
    return setUserWorkplace(dispatch, clientLocationId, vehicle_id, () => {
      this.setState({ isAddingNewWorkplace: false });
    });
  };

  deleteWorkplace = () => {
    this.setWorkplaceAsync(null);
  };

  addNewVehicle = () => {
    const { dispatch } = this.props;
    dispatch({ type: 'CLEAR_PRICING' });
    return browserHistory.push('/pricing/vehicle-lookup');
  };

  routeToServicePage = () => {
    const {
      clientLocations,
      z3pConfiguration,
      dispatch,
      customerLocation,
      pricing,
    } = this.props;
    const {
      service_locations,
      skip_simple_services_to,
      is_skipping_vehicle,
    } = z3pConfiguration;
    // If the only service location is workplace or shop and there is only 1 client location or a location has already been selected, skip /place
    if (shouldAutoSelectLocation(service_locations, clientLocations)) {
      const clId =
        customerLocation && !!customerLocation.clientLocationId
          ? customerLocation.clientLocationId
          : clientLocations[0].client_location_id;
      setUserWorkplaceAndRedirect(
        clId,
        dispatch,
        skip_simple_services_to,
        is_skipping_vehicle,
        pricing,
      );
    } else {
      browserHistory.push('/pricing/place');
    }
  };

  bookService = () => {
    const { dispatch } = this.props;
    // Clear existing data
    dispatch({ type: 'SET_CART', cart: [] });
    dispatch({
      type: 'SET_CHANGE_BOOKING',
      changeBooking: false,
    });
    dispatch({ type: 'SET_SCHEDULE', schedule: {} });
    dispatch({
      type: 'SET_HOME_ADDRESS',
      address: {},
      addressIDSelected: null,
      addressNameSelected: null,
    });
    dispatch({
      type: 'SET_LOCATION',
      location: null,
      selectedLocationAlias: null,
      clientLocationId: null,
    });
    dispatch({ type: 'SELECT_VEHICLE', vehicle: null });
    dispatch({
      type: 'SET_COUPON',
      coupon: null,
    });
    dispatch({
      type: 'SET_CUSTOM_DISCOUNT',
      customDiscount: null,
    });
    dispatch({
      type: 'SET_QUOTE_ID',
      quoteId: null,
    });
    this.routeToServicePage();
  };

  selectVehicle = (vehicle) => {
    const { dispatch, account, z3pConfiguration } = this.props;
    const { is_performing_recalls, recall_makes_offered } = z3pConfiguration;

    // Clear existing data
    dispatch({ type: 'SET_CART', cart: [] });
    dispatch({
      type: 'SET_CHANGE_BOOKING',
      changeBooking: false,
    });
    dispatch({ type: 'SET_SCHEDULE', schedule: {} });
    dispatch({
      type: 'SET_HOME_ADDRESS',
      address: {},
      addressIDSelected: null,
      addressNameSelected: null,
    });
    dispatch({
      type: 'SET_LOCATION',
      location: null,
      selectedLocationAlias: null,
      clientLocationId: null,
    });
    dispatch({
      type: 'SET_COUPON',
      coupon: null,
    });
    dispatch({
      type: 'SET_CUSTOM_DISCOUNT',
      customDiscount: null,
    });
    dispatch({
      type: 'SET_QUOTE_ID',
      quoteId: null,
    });

    const vehicleType = getVehicleType(vehicle);
    dispatch({ type: 'SELECT_VEHICLE', vehicle });
    dispatch({ type: 'SELECT_VEHICLE_TYPE', vehicleType });
    if (
      is_performing_recalls &&
      recall_makes_offered &&
      recall_makes_offered.includes(vehicle.make)
    ) {
      apiGet(`/carfax/recalls/${vehicle.vin}`)
        .then((res) => res.json())
        .then((carfax_data) => {
          const recallList = [];
          carfax_data.forEach((record) => {
            recallList.push(record.recall_formatted_string);
          });
          dispatch({
            type: 'SET_RECALL_LIST',
            recallList,
          });
        })
        .catch((err) => {
          console.log(err);
          const msg = 'Failed to fetch vehicle recall information.';
          Raven.captureMessage(msg, {
            tags: { customerId: account.customer_id },
          });
        })
        .finally(() => {
          return this.routeToServicePage();
        });
    } else {
      dispatch({
        type: 'SET_RECALL_LIST',
        recallList: [],
      });
      return this.routeToServicePage();
    }
  };

  refreshVehicles = () => {
    return apiGet('/customer-info')
      .then((res) => res.json())
      .then((json) => {
        const { vehicles } = json;
        const { dispatch } = this.props;

        dispatch({ type: 'SET_VEHICLES', vehicles });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  addNewAddress = () => {
    return browserHistory.push('/add-home-address');
  };

  renderWorkplaceCard = () => {
    const { isAddingNewWorkplace } = this.state;
    const { customerLocation, z3pConfiguration } = this.props;
    const { service_locations: serviceLocations } = z3pConfiguration;
    const hasShopService = serviceLocations.includes('shop');
    const hasWorkplaceService = serviceLocations.includes('workplace');
    if (!(hasShopService || hasWorkplaceService)) return null;
    if (!customerLocation.clientLocationId && !isAddingNewWorkplace)
      return (
        <Col l={3} m={6} s={12}>
          <Card
            className="swCard newVehicleCard waves-effect waves-light"
            align="center"
            title={hasShopService ? 'Select Your Shop' : 'Add Your Workplace'}
            onClick={() => this.setState({ isAddingNewWorkplace: true })}
          />
        </Col>
      );
    return (
      <AddressCard
        id={`Workplace_${customerLocation.clientLocationId}`}
        workplace={customerLocation.location}
        setWorkplaceAsync={this.setWorkplaceAsync}
        deleteWorkplace={this.deleteWorkplace}
        isNewWorkplace={isAddingNewWorkplace}
        onCancel={() => this.setState({ isAddingNewWorkplace: false })}
        hasShopService={hasShopService}
        editable
      />
    );
  };

  render() {
    const {
      addresses,
      loading,
      limitUpcomingServiceList,
      limitPastServiceList,
      serviceLimit,
    } = this.state;
    const { vehicles, scheduledWorks, account, z3pConfiguration } = this.props;
    const activeVehicles = vehicles.filter((v) => !v.is_deleted);
    const {
      customer_id: customerId,
      email,
      is_fleet_user: isFleetUser,
      stripe_brand: stripeBrand,
      stripe_last4: stripeLast4,
      square_brand: squareBrand,
      square_last4: squareLast4,
    } = account;
    const {
      z3p_client_name: z3pEnv,
      customer_facing_name: serviceProviderName,
      is_using_stripe: isUsingStripe,
      is_using_square: isUsingSquare,
      service_locations: serviceLocations,
      is_skipping_vehicle: isSkippingVehicle,
      allow_additional_user_sms_communications: allowAdditionalContacts,
    } = z3pConfiguration;

    const hasHomeService = serviceLocations.includes('home');
    const hasShopService = serviceLocations.includes('shop');

    const vehicleButtons = activeVehicles.map((v) => (
      <VehicleCard
        id={v.vehicle_id}
        name={v.display_name}
        isWaitlist={v.is_waitlist}
        vin={v.vin}
        licensePlateNumber={v.license_plate_number}
        licensePlateState={v.license_plate_state}
        bookServiceCallback={() => this.selectVehicle(v)}
        refreshVehicles={this.refreshVehicles}
        scheduledWorks={scheduledWorks}
      />
    ));

    // Sort appointments by date. As a tiebreaker, sort by vehicle ID
    const sortByDateAndVehicle = (a, b) => {
      if (!a.date) return -1;
      if (!b.date) return 1;
      if (a.date === b.date) {
        return a.vehicle_id > b.vehicle_id ? 1 : -1;
      }
      return a.date > b.date ? 1 : -1;
    };

    const swDateIsInPast = (date) =>
      new Moment(date).isBefore(new Date(), 'day');

    const filteredPastServices = scheduledWorks
      .filter((sw) => sw.status === 'finished' || swDateIsInPast(sw.date))
      .sort((a, b) => sortByDateAndVehicle(a, b));

    // If limitPastServiceList is true, limit by the serviceLimit number
    // Otherwise, show all - slice(0, filteredPastServices.length + 1) will return the full array
    const pastServices = filteredPastServices
      .slice(
        0,
        limitPastServiceList ? serviceLimit : filteredPastServices.length + 1,
      )
      .map((sw) => <Col s={12} m={6}><ScheduledWorkCard key={sw.id} sw={sw} /></Col>);

    const filteredUpcomingServices = scheduledWorks
      .filter(
        (sw) =>
          ['scheduled', 'keys_available'].includes(sw.status) &&
          !swDateIsInPast(sw.date),
      )
      .sort((a, b) => sortByDateAndVehicle(a, b));

    // If limitUpcomingServiceList is true, limit by the serviceLimit number
    // Otherwise, show all - slice(0, filteredUpcomingServices.length + 1) will return the full array
    const upcomingServices = filteredUpcomingServices
      .slice(
        0,
        limitUpcomingServiceList
          ? serviceLimit
          : filteredUpcomingServices.length + 1,
      )
      .map((sw) => (
        <ScheduledWorkCard
          key={sw.id}
          sw={sw}
          refreshScheduledWorks={this.fetchScheduledWorks}
          showAdditionalContacts={allowAdditionalContacts}
        />
      ));

    const suggestedServices = scheduledWorks
      .filter(
        (sw) => sw.status === 'suggested' && Date.parse(sw.date) >= Date.now(),
      )
      .sort((a, b) => sortByDateAndVehicle(a, b))
      .map((sw) => (
        <ScheduledWorkCard
          key={sw.id}
          sw={sw}
          refreshScheduledWorks={this.fetchScheduledWorks}
        />
      ));

    const addressCards = addresses.sort((addr) => addr.is_primary ? -1 : 1).map((address) => (
      <AddressCard
        id={address.customer_address_id}
        editable
        address={address}
        onMakePrimary={(id) => {
          apiPut(`/customer/address/make-primary/${id}`).then(() => {
            this.fetchUserAddresses();
          });
        }}
        refreshAddresses={this.fetchUserAddresses}
      />
    ));

    return (
      <Zippity active="garage">
        <div id="garage">
            {loading && !scheduledWorks.length ? (
              <Row>
                <Col>
                  <h4>Loading Your Bookings...</h4>
                </Col>
              </Row>
            ) : (
                <>
                <Row className="marginBottom0">
                  <Col m={12} s={12}>
                    <h3>Upcoming Services</h3>
                    <div className="upcomming_service">
                      {upcomingServices.length > 0 ? (
                        upcomingServices
                      ) : (
                        <p>You have no services scheduled.</p>
                      )}
                    </div>

                    {filteredUpcomingServices.length > serviceLimit && (
                      <div
                        className="neue expand_link"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          this.setState({
                            limitUpcomingServiceList: !limitUpcomingServiceList,
                          })
                        }
                      >
                        <Icon>
                          {limitUpcomingServiceList
                            ? 'expand_more'
                            : 'expand_less'}
                        </Icon>
                        <span style={{ verticalAlign: 'top' }}>
                          {limitUpcomingServiceList
                            ? ' Show All'
                            : ' Show Less'}
                        </span>
                      </div>
                    )}
                  </Col>
                </Row>

                {suggestedServices.length > 0 && (
                  <Row>
                    <Col m={8} s={12}>
                      <h3>Suggested Services</h3>
                      {suggestedServices}
                    </Col>
                  </Row>
                )}
              </>
            )}

            <Row className="marginBottom0">
              <Col s={12}>
                <h3>Book A New Service</h3>
                <Row className="new_service marginBottom0">
                  {isSkippingVehicle ? (
                    <Col l={4} m={6} s={12}>
                      <Card
                        className="swCard newVehicleCard"
                        align="center"
                        title="Book Service"
                        onClick={() => this.bookService()}
                      />
                    </Col>
                  ) : (
                    <>
                      {vehicleButtons}

                      <Col s={12} className="new_service_btn">
                        <Button
                          className="btn"
                          onClick={() => this.addNewVehicle()}
                        >
                        Add a New Vehicle
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </Row>

          {isUsingStripe /* || isUsingSquare TODO: reactivate once Customers can update Card from My Account */ && (
            <Row>
              <Col m={8} s={12}>
                <h3>My Account</h3>

                <UpdateCard
                  customerId={customerId}
                  email={email}
                  brand={squareBrand || stripeBrand}
                  last4={squareLast4 || stripeLast4}
                  paymentProcessor={
                    isUsingStripe ? STRIPE : isUsingSquare ? SQUARE : null
                  }
                />

                {isFleetUser && (
                  <Card title="Fleet Account" className="swCard">
                    <p>
                      <ul className="neue">
                        You are logged in with a Fleet Account and will receive
                        special privileges at checkout.
                      </ul>
                    </p>
                  </Card>
                )}
              </Col>
            </Row>
          )}

          <Row className="marginBottom0">
            <Col s={12} className="place_box">
              <h3>My Places</h3>
              <Row className="new_service">
                {this.renderWorkplaceCard()}

                {hasHomeService && (
                  <>
                    {addressCards}
                    {!hasShopService && (
                    <Col s={12} className="new_service_btn">
                      <Button
                        className="swCard newVehicleCard waves-effect waves-light btn"
                        onClick={() => this.addNewAddress()}
                      >
                      Add a Home Address
                      </Button>
                    </Col>
                    )}
                  </>
                )}
              </Row>
            </Col>
          </Row>

          {pastServices.length > 0 && (
            <Row>
              <Col s={12}>
                <h3>Past Services</h3>
                <div className="past_service">
                  <Row>
                    {pastServices}
                  </Row>
                  <Row>
                    <Col>
                      {filteredPastServices.length > serviceLimit && (
                        <div
                          className="neue expand_link"
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.setState({
                              limitPastServiceList: !limitPastServiceList,
                            })
                          }
                        >
                          <Icon>
                            {limitPastServiceList ? 'expand_more' : 'expand_less'}
                          </Icon>
                          <span style={{ verticalAlign: 'top' }}>
                            {limitPastServiceList ? ' Show All' : ' Show Less'}
                          </span>
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          )}

          <br />
          {!scheduledWorks.length > 0 && z3pEnv === 'zippity' && (
            <Row>
              <Col m={8} s={12} className="referral-zone">
                <p>
                  Still haven’t tried {serviceProviderName}? We have a special
                  gift for you: $15 off your first service. Just enter promo
                  code <strong>FIRST15</strong> at checkout!
                </p>
              </Col>
            </Row>
          )}
        </div>
      </Zippity>
    );
  }
}

function mapStateToProps(state) {
  return {
    vehicles: state.ui.vehicles,
    vehicle: state.ui.pricing,
    scheduledWorks: state.ui.scheduledWorks,
    account: state.ui.account,
    pricing: state.ui.pricing,
    customerLocation: state.ui.customerLocation,
    clientLocations: state.ui.clientLocations,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(Garage);
