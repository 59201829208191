import React, { Component } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { Button, CardPanel, Icon } from 'react-materialize';
import { connect } from 'react-redux';
import { apiPost } from '../brainApi';
import { creditCardInfoToDescription } from '../helper';
import SquareModal from './components/SquareModal';
import { getStripePublishableKey } from '../paymentProcessor';
import CancellationPolicyModal from './Review/Checkout/CancellationPolicyModal';

class AddPaymentWithoutLoginModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      newLast4: false,
      newBrand: false,
      chargeStatus: false,
      chargeFailureReason: null,
      showCancellationPolicy: false,
    };

    this.onUpdatedToken = this.onUpdatedToken.bind(this);
  }

  componentDidMount() {
    const { ccLast4, ccBrand, setHasCard } = this.props;
    if (ccLast4) setHasCard(true);
    if (this.button?._btnEl && !(ccLast4 && ccBrand)) {
      this.button._btnEl.click();
    }
  }

  onUpdatedToken(ccToken) {
    const { token, z3pConfiguration, setHasCard, setIsPaid } = this.props;
    const { is_using_square } = z3pConfiguration;
    const payload = {
      sw_token: token,
    };
    if (is_using_square) {
      payload.square_token = ccToken;
    } else {
      payload.stripe_token = ccToken.id;
    }

    this.setState({
      loading: true,
    });

    apiPost(`/booking/update-payment-method-token`, payload)
      .then((response) => {
        this.setState({
          loading: false,
        });

        if (response.status !== 200) {
          response.text().then((text) => {
            throw new Error(text);
          });
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => {
        this.setState({
          newBrand: json.brand,
          newLast4: json.last4,
          chargeStatus: json.success,
          chargeFailureReason: json.failure_reason,
        });
        setHasCard(true);
        setIsPaid('other_pending_sw_list' in json); // json.success can be true in many cases, but other_pending_sw_list in json only if the charge completed successfully
      })
      .catch((error) => console.log(error));
  }

  render() {
    const {
      newBrand,
      newLast4,
      loading,
      chargeStatus,
      chargeFailureReason,
      showCancellationPolicy,
    } = this.state;
    const {
      email,
      z3pConfiguration,
      ccLast4,
      ccBrand,
      isPaid,
      isFinished,
      isCCOnline,
      paymentMethod,
      tipAmountDollars,
    } = this.props;
    const {
      customer_facing_name: serviceProviderName,
      is_using_stripe,
      is_using_square,
      is_charging_cancellation_fee = false,
      scheduling_system,
      is_accepting_tip,
    } = z3pConfiguration;

    const stripeKey = getStripePublishableKey();

    const holdPaymentForTip =
      isFinished && is_accepting_tip && !tipAmountDollars;
    const submitButtonText =
      isFinished && !holdPaymentForTip ? 'Pay' : 'Authorize';
    const chargeStatusText = () => {
      const isPayingWithCard = is_using_stripe || is_using_square;
      if ((isPayingWithCard && isFinished && !holdPaymentForTip) || isPaid) {
        return 'Paid';
      }
      return 'Added';
    };

    const hasCardBrand = !!ccBrand || !!newBrand;

    const cardSection = () => {
      if (loading) {
        return 'Saving...';
      }
      if (is_using_square) {
        return (
          <SquareModal
            disabled={loading}
            onToken={this.onUpdatedToken}
            openButtonText={hasCardBrand ? 'CHANGE' : 'ADD CREDIT CARD'}
            submitButtonText={submitButtonText}
            textStyleButton={hasCardBrand}
            fromOnlineAppointmentPage
          />
        );
      }
      return (
        <StripeCheckout
          disabled={loading}
          email={email}
          token={this.onUpdatedToken}
          stripeKey={stripeKey}
          allowRememberMe={false}
          name={serviceProviderName}
          panelLabel={submitButtonText}
          zipCode
          style={{ width: 'auto' }}
        >
          <Button
            flat={hasCardBrand}
            className={
              hasCardBrand ? 'add-payment-text-btn' : 'add-payment-btn'
            }
            ref={(button) => (this.button = button)}
          >
            {hasCardBrand ? 'CHANGE' : 'ADD CREDIT CARD'}
          </Button>
        </StripeCheckout>
      );
    };

    return (
      <div
        style={{
          backgroundColor: 'whitesmoke',
          padding: 10,
          borderRadius: 10,
          marginTop: 25,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}
        >
          <b>Payment Method</b>
          {(chargeStatus || isPaid) && (
            <CardPanel
              className="green darken-4"
              style={{
                textAlign: 'center',
                color: 'white',
                paddingTop: 5,
                paddingBottom: 5,
                marginTop: 0,
                display: 'flex',
              }}
            >
              <Icon style={{ marginRight: 5 }}>check_circle</Icon>
              {chargeStatusText()}
            </CardPanel>
          )}
          {!!chargeFailureReason && (
            <div>
              <CardPanel
                className="red darken-4"
                style={{
                  textAlign: 'center',
                  color: 'white',
                  paddingTop: 5,
                  paddingBottom: 5,
                  marginTop: 0,
                  display: 'flex',
                }}
              >
                <Icon style={{ marginRight: 5 }}>error</Icon>
                There was an error with your card. Please try again.
              </CardPanel>
            </div>
          )}
        </div>
        {isCCOnline ? (
          <div>
            <b>Card on file: </b>
            {creditCardInfoToDescription(
              newBrand || ccBrand,
              newLast4 || ccLast4,
            )}{' '}
            {!isPaid && cardSection()}
            {!isFinished && !isPaid && (
              <div style={{ marginTop: 10, fontSize: 12 }}>
                Card will not be charged until the appointment is complete.
              </div>
            )}
          </div>
        ) : (
          <div>{paymentMethod}</div>
        )}
        {is_charging_cancellation_fee && scheduling_system === 'time_slots' && (
          <div style={{ fontSize: 12 }}>
            By adding your credit card, you are agreeing to our cancellation
            policy outlined{' '}
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => this.setState({ showCancellationPolicy: true })}
            >
              here
            </span>
            .
          </div>
        )}
        <CancellationPolicyModal
          open={showCancellationPolicy}
          onCancel={() => this.setState({ showCancellationPolicy: false })}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(AddPaymentWithoutLoginModal);
