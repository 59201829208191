import React, { Component, Fragment } from 'react';
import Raven from 'raven-js';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';

import Pricing from './components/Pricing';

import { apiGet } from '../brainApi';
import { setPageTitle } from '../helper';

class Location extends Component {
  constructor(props) {
    super(props);

    const allKeyosks = props.customerLocation.allKeyosks || [];
    const allParkingZones = props.customerLocation.allParkingZones || [];

    // If the booking is for BOS007T or BOS003V, allow the customer to park anywhere
    this.parkAnywhere =
      props.schedule.serviceVehicleId === 'BOS007T' ||
      props.schedule.serviceVehicleId === 'BOS003V';

    this.state = {
      allKeyosks,
      selectedKeyosk: null,

      allParkingZones,
      selectedParking: null,
    };
  }

  componentWillMount() {
    setPageTitle('Location Selection');
  }

  componentDidMount() {
    const { allKeyosks, allParkingZones } = this.state;
    const { account, dispatch, schedule, cart, homeAddress } = this.props;
    // If the customer doesn't have any account info or if they've selected a home address,
    // direct them back to Garage, where we will load their account info.
    if (
      !account ||
      !account.default_client_location_id ||
      (homeAddress && homeAddress.addressIDSelected)
    ) {
      return browserHistory.push('/account');
    }

    // If the customer doesn't have anything in their cart,
    // direct them back to the Simple Services page.
    if (!cart || cart.length === 0) {
      return browserHistory.push('/pricing/simple');
    }

    // User should have a schedule before they hit this page
    if (!schedule) {
      return browserHistory.push('/pricing/schedule');
    }

    const { default_client_location_id: defaultClientLocationId } = account;
    // Try to select keyosk/parking based on pre-loaded info.
    this.tryToDecideSelectedKeyosk(allKeyosks);
    if (this.parkAnywhere) {
      this.loadParkAnywhereFromBrain2().then((parking) => {
        this.setState({
          selectedParking: parking,
        });
      });
    } else {
      this.tryToDecideSelectedParking(allParkingZones);
    }

    // Also refresh keyosk/parking info, in case it has changed.
    // At this point, if there are still no keyosk/parking options, use the default descriptions.

    this.loadKeyosksFromBrain2(defaultClientLocationId).then((keyosks) => {
      if (!keyosks || keyosks.length === 0) {
        this.setState({
          allKeyosks: [this.DEFAULT_KEYOSK],
          selectedKeyosk: this.DEFAULT_KEYOSK,
        });
      } else {
        this.setState({ allKeyosks: keyosks });
        this.tryToDecideSelectedKeyosk(keyosks);
      }
    });

    if (!this.parkAnywhere) {
      this.loadParkingZonesFromBrain2(defaultClientLocationId).then(
        (parkingZones) => {
          if (!parkingZones || parkingZones.length === 0) {
            this.setState({
              allParkingZones: [this.DEFAULT_PARKING],
              selectedParking: this.DEFAULT_PARKING,
            });
          } else {
            this.setState({ allParkingZones: parkingZones });
            this.tryToDecideSelectedParking(parkingZones);
          }
        },
      );
    }

    // Is the customer's location one with "dedicatedParking"?
    const dedicatedParking = this.isLocationDedicatedParking();
    dispatch({ type: 'SET_DEDICATED_PARKING', dedicatedParking });
  }

  DEFAULT_KEYOSK = {
    name: `${this.props.z3pConfiguration.customer_facing_name} Keyosk`,
    blurb:
      'On the day of your service, please drop off your keys at our Keyosk, similar to the one in the image above.',
    image_url: 'https://zippity.imgix.net/keyosk/generic.jpg',
  };

  DEFAULT_PARKING = {
    name: 'Main Parking Area',
  };

  loadKeyosksFromBrain2(clientLocationId) {
    const { dispatch } = this.props;
    return apiGet(`/client-location/${clientLocationId}/keyosk`)
      .then((response) => response.json())
      .then((allKeyosks) => {
        dispatch({ type: 'SET_ALL_KEYOSKS', allKeyosks });
        return allKeyosks;
      })
      .catch((error) => Raven.captureException(error));
  }

  loadParkingZonesFromBrain2(clientLocationId) {
    const { dispatch } = this.props;
    return apiGet(`/client-location/${clientLocationId}/parking`)
      .then((response) => response.json())
      .then((allParkingZones) => {
        dispatch({ type: 'SET_ALL_PARKING_ZONES', allParkingZones });
        return allParkingZones;
      })
      .catch((error) => Raven.captureException(error));
  }

  tryToDecideSelectedKeyosk(allKeyosks) {
    const { selectedKeyosk: alreadySelectedKeyosk } = this.state;
    const { keyosk, account } = this.props;

    // If there are no options, or if we already have a selected keyosk, return.
    if (!allKeyosks || alreadySelectedKeyosk) {
      return;
    }

    if (keyosk && keyosk.name) {
      // User has already selected a keyosk for this booking
      const selectedKeyosk = allKeyosks.find((k) => k.name === keyosk.name);
      this.setState({ selectedKeyosk });
    } else if (account.preferredKeyoskId) {
      // If user's account already has a keyosk, try to find it in this list.
      const selectedKeyosk = allKeyosks.find(
        (k) => k.keyosk_id === account.preferredKeyoskId,
      );
      this.setState({ selectedKeyosk });
    } else if (allKeyosks.length === 1) {
      // If there's only 1 in the list, select it.
      this.setState({ selectedKeyosk: allKeyosks[0] });
    }
  }

  tryToDecideSelectedParking(allParkingZones) {
    const { selectedParking: alreadySelectedParking } = this.state;
    const { parking, account } = this.props;

    // If there are no options, or if we already have a selected parking zone, return.
    if (!allParkingZones || alreadySelectedParking) {
      return;
    }

    if (parking && parking.name) {
      // User has already selected a parking zone for this booking
      const selectedParking = allParkingZones.find(
        (p) => p.name === parking.name,
      );
      this.setState({ selectedParking });
    } else if (account.preferredParkingId) {
      // If user's account already has a parking zone, try to find it in this list.
      const selectedParking = allParkingZones.find(
        (p) => p.parking_id === account.preferredParkingId,
      );
      this.setState({ selectedParking });
    } else if (allParkingZones.length === 1) {
      // If there's only 1 in the list, select it.
      this.setState({ selectedParking: allParkingZones[0] });
    }
  }

  getParkingImage() {
    const { selectedParking } = this.state;
    return selectedParking && selectedParking.image_url
      ? `${selectedParking.image_url}?fit=max&w=500&auto=format,compress`
      : '';
  }

  getParkingBlurb() {
    const { selectedParking } = this.state;
    const { z3pConfiguration } = this.props;
    const { z3p_client_name: z3pEnv } = z3pConfiguration;

    if (selectedParking && selectedParking.blurb) {
      return selectedParking.blurb;
    }
    if (this.isLocationDedicatedParking() && z3pEnv === 'zippity') {
      return `Please park inside the Zippity Zone in your selected parking area: ${selectedParking.name}.`;
    }
    return `Please park in your selected parking area: ${selectedParking.name}.`;
  }

  getKeyoskImage() {
    const { selectedKeyosk } = this.state;
    return selectedKeyosk && selectedKeyosk.image_url
      ? `${selectedKeyosk.image_url}?fit=max&w=500&auto=format,compress`
      : 'https://zippity.imgix.net/keyosk/generic.jpg';
  }

  getKeyoskBlurb() {
    const { selectedKeyosk } = this.state;
    return selectedKeyosk && selectedKeyosk.blurb
      ? selectedKeyosk.blurb
      : 'On the day of your service, please drop off your keys at our Keyosk, similar to the one in the image above.';
  }

  loadParkAnywhereFromBrain2 = () => {
    const parkingId = 100;
    return apiGet(`/parking/${parkingId}`)
      .then((response) => response.json())
      .catch((error) => Raven.captureException(error));
  };

  // We are trying to phase out dedicated parking.  All our new sites use dedicated parking.
  // In the future, if we start needing to add sites without dedicated parking, we should
  // add this as a boolean in the data model.
  isLocationDedicatedParking() {
    const { customerLocation } = this.props;
    return customerLocation.location !== 'DHMC';
  }

  parkingExplanatoryText = () => {
    const { dedicatedParking } = this.props;
    const text = dedicatedParking
      ? `Pick the parking zone closest to where you would normally park. You'll need to park inside the zone on the day of service for our team to retrieve your car.`
      : 'Pick the lot where you plan to park on the day of service.';

    return text;
  };

  keyoskExplanatoryText = () => {
    const { dedicatedParking } = this.props;
    const text = dedicatedParking
      ? "You'll drop off and pick up your keys here on the day of service."
      : "You'll use our interactive keyosk to drop off your keys here on the day of service.";

    return text;
  };

  handleContinue = () => {
    const { dispatch } = this.props;
    const { selectedKeyosk, selectedParking } = this.state;
    dispatch({
      type: 'SET_KEYOSK',
      keyosk: selectedKeyosk,
    });
    dispatch({
      type: 'SET_PARKING',
      parking: selectedParking,
    });

    browserHistory.push('/pricing/review');
  };

  render() {
    const {
      selectedKeyosk,
      selectedParking,
      allParkingZones,
      allKeyosks,
    } = this.state;
    const selectedParkingName = selectedParking ? selectedParking.name : '';
    const selectedKeyoskName = selectedKeyosk ? selectedKeyosk.name : '';

    const showParking = !this.parkAnywhere;

    return (
      <Pricing
        currentStep="Schedule"
        h1="Schedule Your Service"
        h2={`Select the ${
          showParking ? 'parking zone and ' : ''
        }Keyosk most convenient to you.`}
        showContinue
        enableContinue={selectedKeyosk && (selectedParking || !showParking)}
        onContinue={this.handleContinue}
      >
        <div className="row">
          <div className="col m6 calendar">
            <div className="col s12">
              {showParking && (
                <Fragment>
                  {/* Parking Lots List */}
                  <h5>Select a Parking Zone</h5>
                  <p>{this.parkingExplanatoryText()}</p>
                  <ul className="dates">
                    {allParkingZones.map((p, i) => {
                      const className =
                        selectedParkingName === p.name ? 'active' : 'available';
                      return (
                        <li
                          key={i}
                          className={className}
                          onClick={() => this.setState({ selectedParking: p })}
                        >
                          {p.name}
                        </li>
                      );
                    })}
                  </ul>
                </Fragment>
              )}

              {/* Keyosks List */}
              <h5 style={{ paddingTop: 10 }}>Select a Keyosk</h5>
              <p>{this.keyoskExplanatoryText()}</p>
              <ul className="dates">
                {allKeyosks.map((k, i) => {
                  const className =
                    selectedKeyoskName === k.name ? 'active' : 'available';
                  return (
                    <li
                      key={i}
                      className={className}
                      onClick={() => this.setState({ selectedKeyosk: k })}
                    >
                      {k.name}
                    </li>
                  );
                })}
              </ul>
            </div>

            <span
              className="btn-large"
              onClick={() => this.handleContinue()}
              disabled={!selectedKeyosk || (!selectedParking && showParking)}
            >
              Continue
              <i className="material-icons right">play_arrow</i>
            </span>

            {!selectedParking && showParking && (
              <span className="neue error">
                <br />
                Please select a parking lot to continue
              </span>
            )}

            {!selectedKeyosk && (
              <span className="neue error">
                <br />
                Please select a Keyosk to continue
              </span>
            )}
          </div>

          <div className="col m6 when center">
            {selectedParking && (
              <div>
                <h5>Parking Instructions</h5>
                {showParking && this.getParkingImage() && (
                  <img
                    alt="Zippity Parking"
                    src={this.getParkingImage()}
                    className="responsive"
                    style={{ paddingTop: 10 }}
                  />
                )}
                {showParking && (
                  <p className="neue">{this.getParkingBlurb()}</p>
                )}
                {!showParking && (
                  <p className="neue">
                    On the morning of your service day, park wherever you&apos;d
                    like. We&apos;ll send you a link to drop a pin on a map
                    indicating where you parked your car.
                    <br />
                    <br />
                    We&apos;ll reserve your parking spot while we work on your
                    car and we&apos;ll place your car back in the same spot once
                    your service is complete.
                  </p>
                )}
              </div>
            )}

            {selectedKeyosk && (
              <div>
                <h5>Key Drop Instructions</h5>
                <img
                  alt="Zippity Keyosk"
                  src={this.getKeyoskImage()}
                  className="responsive"
                  style={{ paddingTop: 10 }}
                />
                <p className="neue">{this.getKeyoskBlurb()}</p>
              </div>
            )}
          </div>
        </div>
      </Pricing>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.ui.account,
    keyosk: state.ui.keyosk,
    parking: state.ui.parking,
    cart: state.ui.cart,
    schedule: state.ui.schedule,
    dedicatedParking: state.ui.dedicatedParking,
    customerLocation: state.ui.customerLocation,
    homeAddress: state.ui.homeAddress,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(Location);
