import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-materialize';
import _ from 'lodash';
import Raven from 'raven-js';
import moment from 'moment';
import { apiGet } from '../../brainApi';
import { getCommonVehicleColorsShortList } from '../../helper';
import VehicleDropdown from '../components/VehicleDropdown';

// Transform the engine into the right format for Brain2
export const transformEngine = ({ description, engineId }) => {
  return {
    engine_name: description || '',
    motor_engine_id: engineId || '',
  };
};

const YearMakeModelTab = (props) => {
  const { vehicleDetails, setVehicleDetails } = props;

  // Loading Step 1: Makes, 2: Models, 3: Trims, 4: Engines. If the step is lower than the active field, show as disabled
  const [stepLoading, setStepLoading] = useState(5);
  // Tracks which dropdown was most recently changed to avoid extra renders
  const [selectedDropdown, setSelectedDropdown] = useState('year');

  const [makeList, setMakeList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [trimList, setTrimList] = useState([]);
  const [engineList, setEngineList] = useState([]);

  // Always show the future year as the top end of the range (ex: in 2021, show up to 2022)
  const years = _.range(Number(moment().format('YYYY')) + 1, 1990);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const previous = usePrevious({
    vehicleDetails,
  });

  useEffect(() => {
    if (!previous) {
      return;
    }
    const { vehicleDetails: prevVehicleDetails } = previous;
    const {
      year: prevSelectedYear,
      makeId: prevSelectedMakeId,
      modelId: prevSelectedModelId,
      submodelId: prevSelectedSubmodelId,
    } = prevVehicleDetails;

    const {
      year: selectedYear,
      makeId: selectedMakeId,
      modelId: selectedModelId,
      submodelId: selectedSubmodelId,
    } = vehicleDetails;
    if (
      selectedYear &&
      prevSelectedYear !== selectedYear &&
      selectedDropdown === 'year'
    ) {
      setStepLoading(1);
      loadMakes(selectedYear);
    } else if (
      selectedYear &&
      selectedMakeId &&
      prevSelectedMakeId !== selectedMakeId &&
      selectedDropdown === 'make'
    ) {
      setStepLoading(2);
      loadModels(selectedMakeId);
    } else if (
      selectedYear &&
      selectedMakeId &&
      selectedModelId &&
      prevSelectedModelId !== selectedModelId &&
      selectedDropdown === 'model'
    ) {
      setStepLoading(3);
      loadTrims(selectedModelId);
    } else if (
      selectedYear &&
      selectedMakeId &&
      selectedModelId &&
      prevSelectedSubmodelId !== selectedSubmodelId &&
      selectedDropdown === 'trim'
    ) {
      setStepLoading(4);
      loadEngines();
    }
  }, [vehicleDetails]);

  const handleCatch = (error) => {
    setStepLoading(5);
    Raven.captureException(error);
  };

  const newVehicleObject = {
    year: '',
    makeName: '',
    makeId: '',
    modelName: '',
    modelId: '',
    vehicleId: '',
    baseVehicleId: '',
    submodelId: '',
    submodelName: '',
    engine: transformEngine({}),
    color: '',
  };

  const handleChange = (field, value) => {
    if (field === 'year') {
      setVehicleDetails({
        ...newVehicleObject,
        year: value,
      });
    } else if (field === 'make') {
      setVehicleDetails({
        ...newVehicleObject,
        year: vehicleDetails.year,
        makeName: value.makeName,
        makeId: value.makeId,
      });
    } else if (field === 'model') {
      setSelectedDropdown('model');
      setVehicleDetails({
        ...newVehicleObject,
        year: vehicleDetails.year,
        makeName: vehicleDetails.makeName,
        makeId: vehicleDetails.makeId,
        modelName: value.modelName,
        modelId: value.modelId,
      });
    } else if (field === 'trim') {
      setSelectedDropdown('trim');
      setVehicleDetails({
        ...vehicleDetails,
        vehicleId: value.vehicleId,
        baseVehicleId: value.baseVehicleId,
        submodelId: value.submodelId,
        submodelName: value.submodelName,
        engine: transformEngine({}),
      });
    } else if (field === 'engine') {
      setVehicleDetails({
        ...vehicleDetails,
        engine: transformEngine(value),
      });
    } else if (field === 'color') {
      setVehicleDetails({
        ...vehicleDetails,
        color: value,
      });
    }
  };

  const loadMakes = (year) => {
    apiGet(`/vehicles/makes/${year}`)
      .then((res) => {
        return res.json();
      })
      .then((makes) => {
        setMakeList(makes);
        setModelList([]);
        setTrimList([]);
        setEngineList([]);
        setStepLoading(5);
      })
      .catch((error) => {
        return handleCatch(error);
      });
  };

  const loadModels = (makeId = vehicleDetails.makeId) => {
    apiGet(`/vehicles/models/${vehicleDetails.year}/${makeId}`)
      .then((res) => {
        return res.json();
      })
      .then((models) => {
        setModelList(models);

        // If there is only one model, set it by default
        if (models.length === 1) {
          handleChange('model', models[0]);
          return;
        }
        setTrimList([]);
        setEngineList([]);

        setStepLoading(5);
      })
      .catch((error) => {
        return handleCatch(error, setStepLoading);
      });
  };

  const loadTrims = (modelId = vehicleDetails.modelId) => {
    apiGet(
      `/vehicles/trims/${vehicleDetails.year}/${vehicleDetails.makeId}/${modelId}`,
    )
      .then((res) => {
        return res.json();
      })
      .then((trims) => {
        setTrimList(trims);
        // If there is only one trim, set it by default
        if (trims.length === 1) {
          handleChange('trim', trims[0]);
          return;
        }
        setEngineList([]);

        setStepLoading(5);
      })
      .catch((error) => {
        return handleCatch(error, setStepLoading);
      });
  };

  const loadEngines = (submodelId = null) => {
    let url = `/motor/engines/${vehicleDetails.year}/${vehicleDetails.makeId}/${vehicleDetails.modelId}`;
    if (submodelId) {
      url += `/${submodelId}`;
    }
    apiGet(url)
      .then((res) => {
        return res.json();
      })
      .then((engines) => {
        setEngineList(engines);

        // If there is only one engine, set it by default
        if (engines.length === 1) {
          handleChange('engine', engines[0]);
        }
        setStepLoading(5);
      })
      .catch((error) => {
        return handleCatch(error, setStepLoading);
      });
  };

  const handleYearChange = (e) => {
    setSelectedDropdown('year');
    handleChange('year', e.target.value);
  };

  const handleMakeChange = (e) => {
    setSelectedDropdown('make');
    const matchingMake = makeList.find(
      (m) => m.makeId === e.target.value,
    );
    if (matchingMake) {
      handleChange('make', {
        makeName: matchingMake.makeName,
        makeId: e.target.value,
      });
    }
  };

  const handleModelChange = (e) => {
    setSelectedDropdown('model');
    const matchingModel = modelList.find(
      (m) => m.modelId === e.target.value,
    );
    if (matchingModel) {
      handleChange('model', {
        modelName: matchingModel.modelName,
        modelId: e.target.value,
      });
    }
  };

  const handleTrimChange = (e) => {
    setSelectedDropdown('trim');
    const matchingTrim = trimList.find(
      (t) => t.submodelId === e.target.value,
    );
    if (matchingTrim) {
      handleChange('trim', matchingTrim);
    }
  };

  const handleEngineChange = (e) => {
    setSelectedDropdown('engine');
    const matchingEngine = engineList.find(
      (engine) => engine.engineId === Number(e.target.value),
    );
    handleChange('engine', matchingEngine || { engineId: 'do-not-know' });
  };

  const handleColorChange = (e) => {
    handleChange('color', e.target.value);
  };

  return (
    <Col>
        <VehicleDropdown
          type="Year"
          value={vehicleDetails.year}
          onChange={handleYearChange}
          list={years}
          required
          classes="vehicle-entry-dropdown"
          s={12}
          m={12}
          l={4}
        />
        <VehicleDropdown
          type="Make"
          value={vehicleDetails.makeId}
          onChange={handleMakeChange}
          list={makeList}
          required
          optionValue="makeId"
          optionText="makeName"
          disabled={!(stepLoading > 1 && makeList && makeList.length > 0)}
          classes="vehicle-entry-dropdown"
          s={12}
          m={12}
          l={4}
        />
        <VehicleDropdown
          type="Model"
          value={vehicleDetails.modelId}
          onChange={handleModelChange}
          list={modelList}
          required
          optionValue="modelId"
          optionText="modelName"
          disabled={!(stepLoading > 2 && modelList && modelList.length > 0)}
          classes="vehicle-entry-dropdown"
          s={12}
          m={12}
          l={4}
        />
        <VehicleDropdown
          type="Trim"
          value={vehicleDetails.submodelId}
          onChange={handleTrimChange}
          list={trimList}
          optionValue="submodelId"
          optionText="submodelName"
          disabled={!(stepLoading > 3 && trimList && trimList.length > 0)}
          classes="vehicle-entry-dropdown"
          s={12}
          m={12}
          l={4}
        />
        {stepLoading > 4 && engineList && engineList.length > 1 && (
          <VehicleDropdown
            type="Engine"
            value={
              vehicleDetails.engine && vehicleDetails.engine.motor_engine_id
            }
            onChange={handleEngineChange}
            list={engineList}
            optionValue="engineId"
            optionText="description"
            classes="vehicle-entry-dropdown"
            s={12}
            m={12}
            l={4}
          />
        )}

        <VehicleDropdown
          type="Color"
          value={vehicleDetails.color}
          onChange={handleColorChange}
          list={getCommonVehicleColorsShortList()}
          classes="vehicle-entry-dropdown"
          s={12}
          m={12}
          l={4}
        />
    </Col>
  );
};

function mapStateToProps(state) {
  return {
    pricing: state.ui.pricing,
  };
}

export default connect(mapStateToProps, null)(YearMakeModelTab);
