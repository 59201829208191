// Modal for confirming cancellation of services

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, Modal, Row } from 'react-materialize';
import { connect } from 'react-redux';
import { getPhoneSupportLink } from '../../helper';
import Select from './Select.js';

const CancelServiceModal = (props) => {
  const {
    mainText,
    closeModalText,
    cancelling = false,
    onReschedule,
    onConfirm,
    onCloseModal,
    open = true,
    sw,
    z3pConfiguration,
  } = props;
  const {
    is_charging_cancellation_fee = false,
    cancellation_cut_off_hours,
    cancellation_fee_amount_dollars,
    no_show_fee_amount_dollars,
    scheduling_system,
  } = z3pConfiguration;

  const [selectedReason, setSelectedReason] = useState(null);
  const [details, setDetails] = useState('');
  const [feeToCharge, setFeeToCharge] = useState(0);
  const [cancelButtonText, setCancelButtonText] = useState(
    'Cancel Appointment',
  );
  const [
    allowSelfServiceCancellation,
    setAllowSelfServiceCancellation,
  ] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const phoneSupport = getPhoneSupportLink();

  useEffect(() => {
    const currentTime = moment();
    let appointmentTime = moment(sw.date);
    if (sw.confirmed_appointment_start_time) {
      appointmentTime = moment(
        `${sw.date} ${sw.confirmed_appointment_start_time}`,
      );
    }
    const address = sw.client_location || sw.customer_address || {};
    if (address.time_zone) {
      appointmentTime = appointmentTime.tz(address.time_zone);
    }
    const timeDifference = appointmentTime.diff(currentTime);
    const timeDifferenceInHours = moment
      .duration(appointmentTime.diff(currentTime))
      .asHours();

    if (
      is_charging_cancellation_fee &&
      (parseInt(cancellation_cut_off_hours) >= timeDifferenceInHours ||
        timeDifference < 0)
    ) {
      if (sw.has_credit_card) {
        setFeeToCharge(parseInt(cancellation_fee_amount_dollars));
        setCancelButtonText('Agree and Cancel Appointment');
      } else {
        // Do not let the customer cancel if they are within the cancellation policy's window but do not have a card on file
        setAllowSelfServiceCancellation(false);
      }
    }
  }, []);

  // Response options
  const reasons = [
    {
      label: 'This day and/or time no longer works for me',
      value: 'Date or Time Conflict',
    },
    {
      label: 'I received services somewhere else',
      value: 'Serviced Elsewhere',
    },
    { label: 'Other', value: 'Other' },
  ];

  return (
    <Modal
      header={mainText}
      open={open}
      options={{
        onCloseStart: onCloseModal,
      }}
      className="cancel_service_modal"
      actions={[
        <span
          className="neue"
          onClick={onReschedule}
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            marginRight: 20,
          }}
          role="button"
          tabIndex="0"
          onKeyPress={onReschedule}
        >
          Reschedule instead
        </span>,
        <Button
          className="btn-teal lighten-3"
          style={{ marginRight: 20 }}
          onClick={onCloseModal}
        >
          {closeModalText}
        </Button>,
      ]}
    >
      {is_charging_cancellation_fee && scheduling_system === 'time_slots' && (
        <Row className="vehicleCard">
          <div style={{ padding: 20 }}>
            <div style={{ marginBottom: 10 }}>
              <strong>A Reminder of Our Cancellation Policy</strong>
            </div>
            <div>
              If the customer cancels less than{' '}
              <strong>{cancellation_cut_off_hours} hours</strong> from the
              appointment start time, then they will be charged a{' '}
              <strong>${cancellation_fee_amount_dollars}</strong> cancellation
              fee. No show appointments will incur a{' '}
              <strong>${no_show_fee_amount_dollars}</strong> fee.
            </div>
          </div>
        </Row>
      )}
      {allowSelfServiceCancellation && (
        <>
          <h6>Reason for Cancellation</h6>
          <form>
            <Select
              s={12}
              m={12}
              onChange={(e) => setSelectedReason(e.target.value)}
              id="selectedReason"
              name="selectedReason"
              value={selectedReason || ''}
              required
            >
              <option disabled key="select one" value="">
                Please select one
              </option>
              {reasons.map((reason) => (
                <option key={reason.value} value={reason.value}>
                  {reason.label}
                </option>
              ))}
            </Select>
          </form>

          {selectedReason && (
            <>
              <h6>Details:</h6>
              <Row>
                <textarea
                  rows="10"
                  value={details}
                  className="rounded-text-area"
                  style={{ height: 80 }}
                  placeholder={
                    selectedReason == 'Other'
                      ? 'Required'
                      : 'Please enter more details'
                  }
                  maxLength="1020"
                  onChange={(event) => {
                    setDetails(event.target.value);
                  }}
                />
              </Row>
            </>
          )}
        </>
      )}
      {is_charging_cancellation_fee && (
        <div style={{ textAlign: 'center', marginBottom: 10 }}>
          {allowSelfServiceCancellation &&
            (feeToCharge ? (
              <strong>
                You will be charged a ${feeToCharge}.00 cancellation fee
              </strong>
            ) : (
              <strong>You will not be charged a cancellation fee</strong>
            ))}
          {!allowSelfServiceCancellation && (
            <strong>
              {errorMessage && `${errorMessage} `}In order to cancel your
              appointment, please call or text us
              {phoneSupport && <span> at {phoneSupport}</span>}
            </strong>
          )}
        </div>
      )}

      <div style={{ textAlign: 'center' }}>
        <Button
          className="btn-teal lighten-1 btn-large"
          style={{ marginRight: 20 }}
          onClick={() =>
            onConfirm(
              selectedReason,
              details,
              feeToCharge,
              setAllowSelfServiceCancellation,
              setErrorMessage,
            )
          }
          disabled={
            (selectedReason == 'Other' && !details) ||
            !selectedReason ||
            !allowSelfServiceCancellation
          }
        >
          {cancelling ? 'Cancelling...' : cancelButtonText}
        </Button>
      </div>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(CancelServiceModal);
