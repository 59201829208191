import React, { useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import Raven from 'raven-js';
import SetAddressForm from './components/SetAddressForm';
import Zippity from './components/Zippity';
import { apiPost, hasAccessToken } from '../brainApi';
import { getURLQuery, setPageTitle } from '../helper';
import ErrorModal from './components/ErrorModal';

const AddHomeAddress = ({ account }) => {
  setPageTitle('Add a Home Address');

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // if customer is not logged in, direct them to the signup page
  if (!account || !account.customer_id || !hasAccessToken()) {
    browserHistory.push('/pricing/begin');
    return null;
  }

  const query = getURLQuery();
  const redirect = query.redirect
    ? decodeURIComponent(query.redirect)
    : '/account';

  const onSetAddress = (address) => {
    apiPost('/customer/address', {
      ...address,
      is_deleted: false,
      is_primary: false,
    })
      .then((r) => {
        if (!r.ok) {
          throw Error(r.statusText);
        }
        return r.json();
      })
      .then(() => {
        setIsLoading(false);
        return browserHistory.push(redirect);
      })
      .catch((e) => {
        Raven.captureException(e);
        setError(true);
      });
  };

  const onCancel = () => {
    return browserHistory.push(redirect);
  };

  return (
    <Zippity containerId="pricing">
      <div className="col m12 r-float">
        <h3>Add a Home Address</h3>

        <ErrorModal
          error={error}
          onClose={() => setError(false)}
          errorText="Sorry, there was a problem saving your address. Please try again."
        />

        <SetAddressForm
          onSetAddress={onSetAddress}
          onCancel={onCancel}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </div>
    </Zippity>
  );
};

function mapStateToProps(state) {
  return {
    account: state.ui.account,
  };
}

export default connect(mapStateToProps, null)(AddHomeAddress);
