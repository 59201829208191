/* globals */
import React from 'react';
import { Button, TextInput } from 'react-materialize';
import { connect } from 'react-redux';

const TipInput = ({
  z3pConfiguration,
  subtotalDollars,
  tipAmountDollars,
  setTipAmountDollars,
}) => {
  const { tip_percent_defaults } = z3pConfiguration;

  return (
    <div style={{ margin: '25px 0' }}>
      <div className="tipContainer">
        <b>Add a Tip</b>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '15px',
          }}
        >
          {tip_percent_defaults.map((percent) => {
            const calculatedTipAmount = Number(
              (subtotalDollars * (percent / 100)).toFixed(2),
            );
            const isSelected = calculatedTipAmount === tipAmountDollars;
            return (
              <Button
                small
                flat
                className={`tipButton ${isSelected ? 'selected' : ''}`}
                onClick={() => {
                  if (isSelected) {
                    setTipAmountDollars(null);
                  } else {
                    setTipAmountDollars(calculatedTipAmount);
                  }
                }}
              >
                {percent}%
              </Button>
            );
          })}
          <TextInput
            id="customTipAmount"
            name="customTipAmount"
            onChange={(e) => {
              // Matches any character that's not 0-9 or .
              const regex = /[^0-9.]/g;
              const filtered = e.target.value.replace(regex, '');
              setTipAmountDollars(filtered ? Number(filtered) : '');
            }}
            value={tipAmountDollars === 0 ? '' : tipAmountDollars}
            type="number"
            style={{ width: '150px', color: 'inherit' }}
            icon={
              <span
                style={{
                  fontSize: 'inherit',
                  textAlign: 'center',
                  color: 'inherit',
                }}
              >
                $
              </span>
            }
          />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(TipInput);
