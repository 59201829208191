import React from 'react';
import Select from './Select';

const VehicleDropdown = ({
  type,
  value,
  onChange,
  list,
  s,
  m,
  l,
  classes = '',
  required = false,
  disabled = false,
  optionValue = null,
  optionText = null,
}) => {
  const dropdownOptions = list.map((item) => (
    <option
      key={optionValue ? item[optionValue] : item}
      value={optionValue ? item[optionValue] : item}
    >
      {optionText ? item[optionText] : item}
    </option>
  ));
  if (type === 'Engine') {
    dropdownOptions.push(
      <option key="do-not-know" value="do-not-know">
        Don&apos;t know
      </option>,
    );
  }

  return (
    <Select
      s={s}
      m={m}
      l={l}
      id={type}
      value={value}
      onChange={onChange}
      disabled={disabled}
      label={`${type}${required ? ' *' : ''}`}
      options={{
        classes: `${classes} ${value === '' ? 'dropdown-placeholder' : ''} ${
          disabled ? 'disabled-vehicle-dropdown' : ''
        }`,
      }}
    >
      <option disabled value="">
        Select {type}
      </option>
      {dropdownOptions}
    </Select>
  );
};

export default VehicleDropdown;
