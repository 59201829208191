import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import update from 'immutability-helper';
import { Checkbox } from 'react-materialize';

class TireFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: {},
    };
  }

  // TODO: Right now, we're not using the "open" status.
  // We're just using "show" in the className for all filters.
  // At some point, we may want to let users toggle the filters open/closed.
  toggle(filterName) {
    const { open } = this.state;
    const copy = update(open, { $toggle: [filterName] });
    this.setState({ open: copy });
  }

  individualFilter(filterName) {
    const { filters, toggleFilter } = this.props;
    const names = Object.keys(filters[filterName]);
    names.sort();

    return names.map((o) => (
      <li key={o} style={{ clear: 'both' }}>
        <Checkbox
          label={o}
          checked={filters[filterName][o]}
          onChange={() => toggleFilter(filterName, o)}
        />
        <br />
      </li>
    ));
  }

  render() {
    const { filters } = this.props;

    const filterDivs = Object.keys(filters).map((filterName) => (
      <div
        key={filterName}
        className="tireFilter"
        onClick={() => this.toggle(filterName)}
      >
        {filterName}
        <ul className="tireFilterDetail show">
          {this.individualFilter(filterName)}
        </ul>
      </div>
    ));

    return (
      <Fragment>
        <h5>Filter</h5>
        {filterDivs}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.ui.account,
    priceList: state.ui.priceList,
    tireDetails: state.ui.tireDetails,
  };
}

export default connect(mapStateToProps, null)(TireFilter);
