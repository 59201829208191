import React from 'react';
import { TextInput } from 'react-materialize';
import { formatLicenseVin } from '../../helper';

const VinTab = (props) => {
  const { vin, setVin } = props;

  const handleVinChange = (e) => {
    const formattedVin = formatLicenseVin(e.target.value);

    setVin(formattedVin);
  };

  return (
    <>
      <TextInput
        label="VIN *"
        placeholder="Enter VIN"
        id="vin"
        value={vin}
        onChange={handleVinChange}
        autoFocus
        inputClassName="vehicle-entry-text-field"
        s={10}
        m={6}
        l={4}
      />
    </>
  );
};

export default VinTab;
