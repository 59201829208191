import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card } from 'react-materialize';
import Zippity from './components/Zippity';
import { setPageTitle } from '../helper';

class OneClickConfirmation extends Component {
  constructor(props) {
    super(props);

    const { schedule } = props;

    this.state = {
      plannedArrivalTime: schedule.plannedArrivalTime || '9:00 AM',
      plannedDepartureTime: schedule.plannedDepartureTime || '5:00 PM',
      date: schedule.date || '',
    };
  }

  componentWillMount() {
    setPageTitle('Confirmation');
  }

  render() {
    const { cart, params } = this.props;
    const { type } = params;
    const { date, plannedArrivalTime, plannedDepartureTime } = this.state;
    let h4Text = 'Your Service is Confirmed';

    if (type === 'reschedule') {
      h4Text = 'Your Service has been Rescheduled';
    }

    return (
      <Zippity active="login">
        <Card className="swCard one-click">
          <h4>{h4Text}</h4>
          <ul className="neue">
            <li>Date:</li>
            <li>
              <strong>{date}</strong>
            </li>
            <li>Planned Arrival Time:</li>
            <li>
              <strong>{plannedArrivalTime}</strong>
            </li>
            <li>Planned Departure Time:</li>
            <li>
              <strong>{plannedDepartureTime}</strong>
            </li>
            <br />

            <li>
              <strong>Services:</strong>
            </li>
            {cart.map((service) => (
              <li key={service.service_offering_id}>
                {service.display_name || service.long_name}
              </li>
            ))}
            <br />
            <li>
              You will receive an email confirmation with your service details,
              as well as email and text reminders in advance of the appointment.
            </li>
          </ul>
        </Card>
      </Zippity>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.ui.account,
    schedule: state.ui.schedule,
    cart: state.ui.cart,
    diagnostic: state.ui.diagnostic,
    changeBooking: state.ui.changeBooking,
  };
}

export default connect(mapStateToProps, null)(OneClickConfirmation);
