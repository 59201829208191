import React, { Children, cloneElement } from 'react';
import { Select as RMSelect, Col } from 'react-materialize';

const Select = (props) => {
  const { children, validate, name, label, s, m, l, ...rest } = props;

  const renderOption = (child) =>
    cloneElement(child, { key: child.props.value });

  // Based on https://stackoverflow.com/a/29696509
  const isUsingiOS = () => {
    const ua = window.navigator.userAgent;
    // iPadOS has the same ua as MacOS, however we can distinguish between them by looking at the screen touch points
    const isIPadOS =
      !!ua.match(/Mac/i) &&
      window.navigator.maxTouchPoints &&
      window.navigator.maxTouchPoints > 1;
    return (
      !!ua.match(/iPad/i) ||
      !!ua.match(/iPhone/i) ||
      !!ua.match(/iPod/i) ||
      isIPadOS
    );
  };

  return isUsingiOS() ? (
    <Col s={s} m={m} l={l} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
      {!!label && (
        <label htmlFor={name} style={{ color: '#757575', fontWeight: 'bold' }}>
          {label}
        </label>
      )}
      <select
        name={name}
        required={validate}
        style={{ display: 'block' }}
        {...rest}
      >
        {Children.map(children, renderOption)}
      </select>
    </Col>
  ) : (
    <RMSelect {...props} />
  );
};

export default Select;
