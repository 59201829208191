// Note: This file is mostly copied over from QuoteQuestionFields.js in zippity-admin
import React from 'react';

// Render each question/answer in a view only state
const AnswerComponent = ({ answer }) => {
  // If the question is not admin answerable, don't render an edit field
  if (!answer?.question?.admin_can_answer) return null;
  if (answer?.question?.is_deleted) return null;

  const { question } = answer;

  // Raw value of answer
  const answerValue = answer.answer;
  // Don't return a view component if there is no value
  if (answerValue === null || answerValue === '') return null;

  // Example 'field_name' for question: "Brand"
  // Example 'questionText': "What brand of tire do you prefer?"
  const { field_name: fieldName, question: questionText } = question;

  // Slider config
  const { config_range_max_value } = question;

  let answerContent = '';
  switch (question.type) {
    case 'boolean':
      answerContent = answerValue === 'true' ? 'Yes' : 'No';
      break;
    case 'slider':
      answerContent = `${answerValue}/${config_range_max_value}`;
      break;
    default:
      answerContent = answerValue;
  }

  // Format and return answer component with label
  return (
    <div>
      <span>
        <strong>{fieldName || questionText}: </strong> {answerContent}
      </span>
    </div>
  );
};

const QuoteAnswers = ({ answers }) => {
  return (
    <div>
      {answers
        .sort(
          (answer1, answer2) =>
            answer1.question.display_order - answer2.question.display_order,
        )
        .map((answer, index) => (
          <AnswerComponent key={`viewable-answer-${index}`} answer={answer} />
        ))}
    </div>
  );
};

export default QuoteAnswers;
