/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Icon, Modal, Row } from 'react-materialize';
import YearMakeModelTab from './YearMakeModelTab';
import LicenseTab from './LicenseTab';
import VinTab from './VinTab';
import HeavyDutyTab from './HeavyDutyTab';

const AutomobileVehicleEntry = (props) => {
  const {
    z3pConfiguration,
    activeTab,
    licensePlate,
    setLicensePlate,
    vin,
    setVin,
    selectedState,
    setSelectedState,
    ymmVehicleDetails,
    setYmmVehicleDetails,
    handleSelectTab,
  } = props;
  const { available_vehicle_entry_tabs: availableTabs } = z3pConfiguration;

  const {
    is_using_heavy_duty: isUsingHeavyDuty,
    z3p_client_name: z3pClient,
  } = z3pConfiguration;
  const isValvolineHeavyDuty =
    z3pClient.toLowerCase().includes('valvoline') && isUsingHeavyDuty;

  const [showVinModal, setShowVinModal] = useState(false);

  const renderTabs = () => {
    const TYPE_TO_LABEL = {
      heavy: 'Heavy Duty',
      // license: 'License Plate',
      year: 'Year Make Model',
      // vin: 'VIN',
    };

    // If Valvoline Heavy Duty or not multiple tab options, do not render tabs
    if (
      isValvolineHeavyDuty ||
      availableTabs.filter((tab) => TYPE_TO_LABEL[tab]).length < 2
    ) {
      return null;
    }

    return availableTabs.map((tab) => {
      if (TYPE_TO_LABEL[tab]) {
        if (tab === 'heavy' && !isUsingHeavyDuty) return null;
        return (
          <Col
            onClick={() => handleSelectTab(tab)}
            className={`vehicle-entry-tab ${
              activeTab === tab ? 'vehicle-entry-active-tab' : ''
            }`}
          >
            {TYPE_TO_LABEL[tab]}
            {tab === 'vin' && activeTab === 'vin' && (
              <span
                onClick={() => setShowVinModal(true)}
                style={{ marginLeft: '2px', cursor: 'pointer' }}
              >
                <Icon tiny>help</Icon>
              </span>
            )}
          </Col>
        );
      }
      return null;
    });
  };

  return (
    <>
      <Row className="vehicle-entry-tabs">
        <Modal
          header="Where Can I Find My VIN?"
          actions={[
            <Button
              className="btn-gray"
              onClick={() => {
                setShowVinModal(false);
              }}
            >
              Close
            </Button>,
          ]}
          open={showVinModal}
        >
          <li>
            On your vehicle on the dashboard where it meets the windshield or on
            the driver’s side door frame
          </li>
          <li>Insurance card/Insurance policy</li>
          <li>Vehicle title and registration</li>
        </Modal>
        {renderTabs()}
      </Row>
      <Row>
        {activeTab === 'heavy' && <HeavyDutyTab />}
        {activeTab === 'license' && (
          <LicenseTab
            licensePlate={licensePlate}
            setLicensePlate={setLicensePlate}
            selectedState={selectedState}
            setSelectedState={setSelectedState}
          />
        )}
        {activeTab === 'year' && (
          <YearMakeModelTab
            vehicleDetails={ymmVehicleDetails}
            setVehicleDetails={setYmmVehicleDetails}
          />
        )}
        {activeTab === 'vin' && <VinTab vin={vin} setVin={setVin} />}
      </Row>
    </>
  );
};

function mapStateToProps(state) {
  return {
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(AutomobileVehicleEntry);
