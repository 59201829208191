/* globals window Raven */

import React, { Component } from 'react';
import { Col, Row } from 'react-materialize';

import { connect } from 'react-redux';
import Zippity from '../components/Zippity';
import { apiGet } from '../../brainApi';
import FutureQuote from './FutureQuote';
import {
  getEmailSupportLink,
  getPhoneSupportLink,
  setPageTitle,
} from '../../helper';

class FutureQuoteWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      scheduledWork: {},
    };
  }

  componentWillMount() {
    setPageTitle('Suggested Services');
  }

  componentDidMount() {
    const { params } = this.props;
    const { sw_id, customer_id } = params;
    // Get the details on this SW
    apiGet(`/scheduled-work/${sw_id}`, { include_deleted: false })
      .then((response) => response.json())
      .then((json) => {
        const isValidUrl = json.customer_id.toString() === customer_id;

        if (!isValidUrl) {
          window.location = 'https://zippitycars.com/';
          return;
        }

        this.setState({
          scheduledWork: json,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        Raven.captureException(error);
      });
  }

  render() {
    const { loading, scheduledWork } = this.state;
    const {
      customer,
      vehicle,
      status,
      services,
      date,
      keyosk,
      parking,
    } = scheduledWork;

    if (loading) {
      return (
        <Zippity>
          <Row>
            <Col s={12} className="center">
              <h2>Loading...</h2>
            </Col>
          </Row>
        </Zippity>
      );
    }

    const vehicleShortDescription = vehicle?.display_name;

    const swIsFinished = ['finished'].includes(status);

    const futureUpsells = services.filter((s) => s.upsell_status === 'future');
    const rejectedUpsells = services.filter(
      (s) => s.upsell_status === 'rejected',
    );
    const pendingUpsells = services.filter(
      (s) => s.upsell_status === 'pending',
    );

    const { params, z3pConfiguration } = this.props;
    const { sw_id, customer_id } = params;
    const receiptLink = `/receipt/${customer_id}/${sw_id}`;
    const { customer_facing_name: serviceProviderName } = z3pConfiguration;

    return (
      <Zippity>
        <div className="quote">
          <Row>
            <Col s={12} m={10} offset="m1">
              <Row>
                <Col className="neue">
                  <h4>Your {serviceProviderName} Quote</h4>

                  <p>Hey {customer.first_name},</p>

                  <p>
                    Here is your quote
                    {vehicleShortDescription &&
                      ` for your ${vehicleShortDescription},`}{' '}
                    from your {serviceProviderName} service on {date}. To see
                    the full receipt, <a href={receiptLink}>click here</a>.
                  </p>

                  <FutureQuote
                    futureUpsells={futureUpsells}
                    rejectedUpsells={rejectedUpsells}
                    vehicle={vehicle}
                    customerId={customer.customer_id}
                    keyosk={keyosk}
                    parking={parking}
                  />

                  {pendingUpsells.length > 0 && !swIsFinished && (
                    <p>
                      Also, you still have services you can approve today.{' '}
                      <a href={receiptLink}>Click here</a> to see them.
                    </p>
                  )}

                  <p>
                    If you have any questions, please contact us at{' '}
                    {getEmailSupportLink()} or {getPhoneSupportLink()}.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Zippity>
    );
  }
}

function mapStateToProps(state) {
  return {
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(FutureQuoteWrapper);
