import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { Col, Row } from 'react-materialize';

import Pricing from '../components/Pricing';
import { getServicesByScheduleType } from '../../serviceHelpers';
import { loadClientLocationDetailsAndPriceList } from '../../brain2';
import TireServiceCard from '../TireServiceCard';
import { setPageTitle } from '../../helper';

const Detailing = (props) => {
  const {
    priceList,
    availableScheduleTypes,
    dispatch,
    customerLocation,
    vehicle,
    z3pConfiguration,
  } = props;

  setPageTitle('Detailing Services');

  const [detailingServices, setDetailingServices] = useState(null);
  const [showServicesBlurb, setShowServicesBlurb] = useState(false);

  useEffect(() => {
    loadClientLocationDetailsAndPriceList(
      dispatch,
      customerLocation?.clientLocationId,
      vehicle?.vehicle_id,
    );
  }, [dispatch, customerLocation?.clientLocationId, vehicle.vehicle_id]);

  useEffect(() => {
    const { is_skipping_vehicle } = z3pConfiguration;
    const hasMechanicalService = !!(
      availableScheduleTypes && availableScheduleTypes.includes('mechanical')
    );
    setShowServicesBlurb(!is_skipping_vehicle && hasMechanicalService);
  }, [availableScheduleTypes]);

  useEffect(() => {
    const detailingServices = getServicesByScheduleType(
      priceList,
      'detailing',
    ).filter((a) => a.display_category !== 'addon');

    console.log(detailingServices.length);

    const serviceOfferingIdToService = {};
    detailingServices.forEach((s) => {
      serviceOfferingIdToService[s.service_offering_id] = s;
    });

    const detailingServicesFinal = Object.values(serviceOfferingIdToService);

    detailingServicesFinal.sort((a, b) => {
      if (a.display_order < b.display_order) {
        return -1;
      }
      return 1;
    });

    setDetailingServices(detailingServicesFinal);
  }, [priceList]);

  const handleSelectDetailing = (service) => {
    dispatch({
      type: 'SET_CART',
      cart: [service],
    });

    return browserHistory.push('/pricing/detailing-more');
  };

  return (
    <Pricing currentStep="Services" h1="Select Your Services">
      <Row>
        {!!detailingServices &&
          detailingServices.map((serviceOffering) => (
            <TireServiceCard
              key={serviceOffering.short_name}
              serviceOffering={serviceOffering}
              handleSelectTireService={handleSelectDetailing}
            />
          ))}
      </Row>
      {showServicesBlurb && (
        <Row>
          <Col>
            <p>
              If you&apos;d also like to schedule mechanical service for your
              vehicle, please finish booking your detail service first.
              You&apos;ll be able to book a separate mechanical service
              afterward.
            </p>
          </Col>
        </Row>
      )}
      <span className="attribution-text" style={{ marginBottom: '-50px' }}>
        Icons made by Smashicons and Freepik, from www.flaticon.com
      </span>
    </Pricing>
  );
};

function mapStateToProps(state) {
  return {
    vehicle: state.ui.pricing,
    availableScheduleTypes: state.ui.availableScheduleTypes,
    priceList: state.ui.priceList,
    z3pConfiguration: state.ui.z3pConfiguration,
    customerLocation: state.ui.customerLocation,
  };
}

export default connect(mapStateToProps, null)(Detailing);
