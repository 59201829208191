// src/components/About/index.js
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compact, groupBy } from 'lodash';
import {
  changeCouponHelper,
  formatPrice,
  isCouponAlreadyUsed,
} from '../../helper';
import { loadCoupon } from '../../brain2';

class YourCar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayAlert: false,
      couponCode: '',
      couponMessage: '',
      showCouponInput: !!props.coupon,
    };
  }

  generateServicesDiv = () => {
    const { cart } = this.props;

    if (!cart || !compact(cart).length) {
      return null;
    }
    const groupedServices = groupBy(cart, 'short_name');
    const items = Object.keys(groupedServices).map((key) => {
      const s = groupedServices[key][0];
      const quantity = s.quantity || groupedServices[key].length;
      return (
        <li key={s.service_offering_id}>
          <i className="material-icons">done</i>
          {quantity > 1 && <span>{quantity}x </span>}
          {s.short_name}
        </li>
      );
    });

    const cartTotalDollars = cart
      .map((s) => parseFloat(s.total_cost_dollars) * (s.quantity || 1))
      .reduce((sum, x) => sum + x, 0);
    const servicesDiv = (
      <div className="white-splash your-services">
        <div className="clearfix your-header">
          <span className="header">Your Services</span>
        </div>
        <div className="services-content">
          <ul>
            {items}

            <li className="total">
              <span>
                <span className="header">{formatPrice(cartTotalDollars)}</span>
                <span className="neue"> subtotal</span>
                <span className="neue">
                  <br />
                  <small>plus tax and fees</small>
                </span>
              </span>
            </li>
          </ul>
          <div className="clearfix" />
        </div>
      </div>
    );

    return servicesDiv;
  };

  displayAlert = () => {
    this.setState({ displayAlert: true });
  };

  changeCoupon = (e) => {
    const { scheduledWorks, couponList } = this.props;
    const [couponCode, couponMessage] = changeCouponHelper(
      e,
      scheduledWorks,
      couponList,
    );
    this.setState({ couponCode, couponMessage });
  };

  submitCoupon = (event) => {
    event.preventDefault();
    const { couponCode } = this.state;
    const { cart, dispatch, scheduledWorks, couponList } = this.props;
    if (isCouponAlreadyUsed(couponCode, scheduledWorks, couponList)) {
      dispatch({ type: 'SET_COUPON', coupon: null });
      this.setState({
        couponMessage: 'Sorry, this coupon has already been used',
      });
      return 'Coupon has already been used';
    }
    if (
      couponCode &&
      !isCouponAlreadyUsed(couponCode, scheduledWorks, couponList)
    ) {
      loadCoupon(couponCode.toUpperCase()).then((coupon) => {
        console.log(coupon);
        console.log(cart);
        if (!coupon || !coupon.coupon_code) {
          dispatch({ type: 'SET_COUPON', coupon: null });
          const couponMessage =
            'Sorry, that coupon cannot be applied to your order.';
          this.setState({ couponMessage });
        } else {
          dispatch({ type: 'SET_COUPON', coupon });
          this.setState({ couponCode: '' });
        }
      });
    }
  };

  couponSection = () => {
    const { couponCode, couponMessage, showCouponInput } = this.state;
    const { coupon } = this.props;

    let existingCouponHtml = <span />;
    if (coupon && coupon.coupon_code) {
      existingCouponHtml = <div>{coupon.coupon_code} Applied!</div>;
    } else if (couponMessage) {
      existingCouponHtml = <div>{couponMessage}</div>;
    }

    if (!showCouponInput) {
      return (
        <div
          onClick={() => this.setState({ showCouponInput: true })}
          style={{
            marginBottom: '10px',
            textAlign: 'center',
            textDecoration: 'underline',
            fontSize: '.9rem',
            cursor: 'pointer',
          }}
        >
          Have a coupon?
        </div>
      );
    }
    return (
      <div className="white-splash">
        <div className="clearfix your-header">
          <span className="header">Your Coupon</span>
        </div>
        <div className="coupon-content">
          <ul>
            <li>
              {existingCouponHtml}
              <input
                id="coupon-code-input"
                type="text"
                value={couponCode}
                placeholder={
                  coupon ? 'Replace coupon code' : 'Enter coupon code'
                }
                onChange={this.changeCoupon}
              />
              {couponCode && couponCode.length > 0 && (
                <div
                  onClick={this.submitCoupon}
                  style={{
                    margin: '5px 0',
                    fontSize: '1.1rem',
                    textAlign: 'center',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  <strong>Apply</strong>
                </div>
              )}
              <div style={{ fontSize: '.7rem' }}>
                *Discount applied at checkout
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  render() {
    const {
      customerLocation,
      homeAddress,
      vehicle,
      onContinue,
      showContinue,
      enableContinue,
      alertText,
    } = this.props;

    const { display_name } = vehicle || {};

    const { displayAlert } = this.state;
    const servicesDiv = this.generateServicesDiv();
    const buttonClass = `button-link btn-large${
      enableContinue ? '' : ' disabled-button-link'
    }`;

    const location =
      homeAddress.addressNameSelected || customerLocation.location;
    const showCar = !!display_name;

    return (
      <div className="col m2 side-bar">
        {!!location && (
          <div className="white-splash">
            <div className="clearfix your-header">
              <span className="header">Your Location</span>
            </div>
            <div className="car-content">
              <ul>
                <li>{location}</li>
              </ul>
            </div>
          </div>
        )}

        {showCar && (
          <div className="white-splash">
            <div className="clearfix your-header">
              <span className="header">Your Vehicle</span>
            </div>
            <div className="car-content">
              <ul>
                <li>{display_name}</li>
              </ul>
            </div>
          </div>
        )}

        {servicesDiv}

        {!!location && this.couponSection()}

        {showContinue && (
          <div align="center">
            <button
              type="button"
              className={buttonClass}
              onClick={enableContinue ? onContinue : this.displayAlert}
            >
              Continue
            </button>
            {!enableContinue && displayAlert && (
              <div
                className="zippity-red"
                style={{
                  paddingTop: '10px',
                  textAlign: 'center',
                }}
              >
                {alertText}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
YourCar.defaultProps = {
  vehicle: {},
};

function mapStateToProps(state) {
  return {
    vehicle: state.ui.pricing,
    cart: state.ui.cart,
    account: state.ui.account,
    customerLocation: state.ui.customerLocation,
    homeAddress: state.ui.homeAddress,
    scheduledWorks: state.ui.scheduledWorks,
    coupon: state.ui.coupon,
    couponList: state.ui.couponList,
  };
}

export default connect(mapStateToProps, null)(YourCar);
