import React, { useEffect, useState } from 'react';
import { Icon } from 'react-materialize';
import moment from 'moment-timezone';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { connect } from 'react-redux';
import Week from './Week';
import Loading from '../Loading';
import {
  loadCompatibleDates,
  loadCompatibleDatesForHomeAddress,
} from '../../../brainApi';
import usePrevious from '../../../hooks/usePrevious';

const weekInMilliseconds = 7 * 24 * 60 * 60 * 1000;

const TimeSlotCalendar = (props) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState([]);
  const [showWeekends, setShowWeekends] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [timeslots, setTimeslots] = useState({});
  const [loading, setLoading] = useState(true);
  const [compatibleData, setCompatibleData] = useState([]);
  // Set to true if there is no availability in first two weeks
  const [missingInitialAvailability, setMissingInitialAvailability] = useState(
    false,
  );

  const {
    startTime,
    setStartTime,
    customerLocation,
    cart,
    selectScheduleInstance,
    useHomeAddress,
    homeAddress,
    tz,
    dispatch,
  } = props;
  const computeDaysOfWeek = (date, showWeekends) => {
    let momentDate = moment(date).startOf('week');
    const weekDays = [];
    for (let i = 0; i < 7; i++) {
      weekDays.push(momentDate);
      momentDate = momentDate.clone().add(1, 'day');
    }
    if (showWeekends) {
      setCurrentWeek(weekDays);
    } else {
      setCurrentWeek(weekDays.slice(1, 6));
    }
  };

  // When the user navigates to a different calendar day, re-compute the days of the week to show.
  useEffect(() => {
    computeDaysOfWeek(currentDate, showWeekends);
  }, [currentDate, showWeekends]);

  useEffect(() => {
    loadAvailability(currentDate, true);
  }, []);

  const convertTime = (t) => {
    return moment(t)
      .tz(tz)
      .format('HH:mm');
  };

  const calcCalendarEvents = (datesList) => {
    const output = {};
    const outputUnavailable = {};
    setCompatibleData(datesList);
    dispatch({
      type: 'SET_COMPATIBLE_DATES',
      compatibleDates: datesList,
    });

    datesList.forEach((s) => {
      const {
        date,
        is_available,
        available_start_times,
        unavailable_slot_times,
      } = s;
      const mergeTwo = (arr1, arr2) => {
        const result = [...arr1, ...arr2];
        return result.sort(
          (a, b) => new Date(a).getTime() - new Date(b).getTime(),
        );
      };

      if (is_available) {
        const timeUnavailable = [
          ...(unavailable_slot_times?.length ? unavailable_slot_times : []),
        ];

        const times = mergeTwo(available_start_times, timeUnavailable);

        const timeBlocks = {};
        times.forEach((time) => {
          timeBlocks[convertTime(time)] = null;
        });

        const timeBlockUnavailable = {};
        timeUnavailable.forEach((time) => {
          timeBlockUnavailable[convertTime(time)] = null;
        });

        if (!(date in output)) {
          // Start with a copy of the empty default timeBlocks
          output[date] = { ...timeBlocks };
        }
        if (!(date in outputUnavailable)) {
          // Start with a copy of the empty default timeBlockUnavailable
          outputUnavailable[date] = { ...timeBlockUnavailable };
        }
        available_start_times.forEach((t) => {
          const timeStr = convertTime(t);
          if (timeStr in output[date] && !output[date][timeStr]) {
            output[date][timeStr] = s;
          }
        });
        if (unavailable_slot_times) {
          unavailable_slot_times.forEach((t) => {
            const timeStr = convertTime(t);
            if (
              timeStr in outputUnavailable[date] &&
              !outputUnavailable[date][timeStr]
            ) {
              output[date][timeStr] = null;
            }
          });
        }
      }
    });

    setTimeslots({ ...timeslots, ...output });

    // If there is any weekend availability, show weekends for all weeks (for a consistent experience).
    const containsWeekend = datesList.some(
      (d) => d.is_available && isWeekend(d.date),
    );
    setShowWeekends(containsWeekend);
    setLoading(false);
  };

  const loadAvailability = (
    week,
    isInitialLoad = false,
    getMoreDates = false,
  ) => {
    setLoading(true);
    const startDate = moment(week)
      .startOf('week')
      .format('YYYY-MM-DD');
    let endDate = moment(week)
      .add(isInitialLoad ? 1 : 0, 'week')
      .endOf('week')
      .format('YYYY-MM-DD');
    // If no availability in first two weeks, load 3 months
    if (getMoreDates) {
      endDate = moment(week)
        .add(3, 'month')
        .format('YYYY-MM-DD');
    }

    if (useHomeAddress) {
      loadCompatibleDatesForHomeAddress(
        homeAddress.addressIDSelected,
        cart,
        startDate,
        endDate,
      ).then((datesList) => {
        calcCalendarEvents(datesList);
      });
    } else {
      loadCompatibleDates(
        customerLocation.clientLocationId,
        cart,
        startDate,
        endDate,
      ).then((datesList) => {
        calcCalendarEvents(datesList);
      });
    }
  };

  const isWeekend = (d) => {
    const dayOfWeek = moment(d)
      .format('ddd')
      .toLowerCase();
    return dayOfWeek === 'sun' || dayOfWeek === 'sat';
  };

  const checkFirstAvailableSchedule = (datesList) => {
    let firstAvailableSchedule = null;
    let firstAvailableMoment = null;

    if (datesList.length >= 1) {
      datesList.forEach((s) => {
        const { available_start_times, date, is_available } = s;
        if (is_available) {
          available_start_times.forEach((t) => {
            const timeStr = convertTime(t);
            if (timeslots[date] && timeStr in timeslots[date]) {
              firstAvailableSchedule = firstAvailableSchedule || s;
              firstAvailableMoment = firstAvailableMoment || moment(t);
            }
          });
        }
        if (firstAvailableSchedule) {
          // Auto-select the first available timeslot
          selectScheduleInstance(firstAvailableSchedule);
          setStartTime(firstAvailableMoment);
          setCurrentDate(firstAvailableMoment.toDate());
        } else {
          // TODO: No availability at all, we should probably give the customer some other way to proceed.
        }
      });
    } else if (
      Object.keys(timeslots).length < 1 &&
      !missingInitialAvailability
    ) {
      // If no dates exist, fetch additional availability (only attempt once)
      setMissingInitialAvailability(true);
      loadAvailability(moment(), true, true);
    }
  };

  const currentWeekDays = (weekDate) => {
    const weekStartDateTimeStamp = new Date(
      moment(weekDate)
        .startOf('week')
        .format('YYYY-MM-DD'),
    ).getTime();
    const endStartDateTimeStamp = new Date(
      moment(weekDate)
        .endOf('week')
        .format('YYYY-MM-DD'),
    ).getTime();

    const weekDays = Object.keys(timeslots).reduce((weeks, dayDate) => {
      const dayTimeStamp = new Date(
        moment(dayDate).format('YYYY-MM-DD'),
      ).getTime();
      if (
        dayTimeStamp >= weekStartDateTimeStamp &&
        dayTimeStamp <= endStartDateTimeStamp
      ) {
        weeks.push(timeslots[dayDate]);
      }
      return weeks;
    }, []);

    const availableTimeOnCurrentWeek = weekDays.reduce(
      (currentWeekAvailableDate, dayAvailable) => {
        Object.values(dayAvailable).map((dayItem) => {
          if (dayItem) {
            return currentWeekAvailableDate.push(dayItem);
          }
          return currentWeekAvailableDate;
        });
        return currentWeekAvailableDate;
      },
      [],
    );

    return [...new Set(availableTimeOnCurrentWeek)];
  };

  const handlePrev = () => {
    const disablePrev = moment(currentDate)
      .startOf('week')
      .isBefore(moment());
    if (disablePrev) {
      // Don't allow user to go back before today
      return;
    }
    const prevWeekDate = new Date();
    prevWeekDate.setTime(currentDate.getTime() - weekInMilliseconds);
    checkFirstAvailableSchedule(currentWeekDays(prevWeekDate));
    setCurrentDate(prevWeekDate);
  };

  const handleNext = () => {
    const nextWeekDate = new Date();
    nextWeekDate.setTime(currentDate.getTime() + weekInMilliseconds);
    checkFirstAvailableSchedule(currentWeekDays(nextWeekDate));
    setCurrentDate(nextWeekDate);
  };

  const prevCurrentDate = usePrevious(currentDate);
  useEffect(() => {
    const formattedNextWeek = moment(currentDate).format('YYYY-MM-DD');

    if (
      prevCurrentDate &&
      prevCurrentDate !== currentDate &&
      !loading &&
      !(formattedNextWeek in timeslots)
    ) {
      loadAvailability(currentDate, false);
    }
  }, [currentDate, prevCurrentDate, loading]);

  const prevTimeSlot = usePrevious(timeslots);
  useEffect(() => {
    if (prevTimeSlot && timeslots !== prevTimeSlot) {
      checkFirstAvailableSchedule(compatibleData);
    }
  }, [prevTimeSlot, timeslots]);

  const renderActions = () => {
    if (currentWeek.length === 0) return null;

    // Show first visible date in the title "Week of __".
    // This is either Sunday or Monday, depending on showWeekends.
    const startDate = currentWeek[0].format('MMM Do');

    const disablePrev = moment(currentDate)
      .startOf('week')
      .isBefore(moment());

    return (
      <div className="tsc__actions">
        <div
          className={`tsc__action ${(loading || disablePrev) &&
            'tsc__action-disabled'}`}
          onClick={!loading ? (date) => handlePrev(date) : () => {}}
        >
          <Icon>chevron_left</Icon>
        </div>
        <div
          className="tsc__action-title"
          onClick={() => setShowPicker((prev) => !prev)}
        >
          Week of {startDate}
        </div>
        <div
          className={`tsc__action ${loading && 'tsc__action-disabled'}`}
          onClick={!loading ? (date) => handleNext(date) : () => {}}
        >
          <Icon>chevron_right</Icon>
        </div>
      </div>
    );
  };

  const handleSelectTimeslot = (timeslot, scheduleInstance) => {
    if (!timeslot || !scheduleInstance) return false;
    selectScheduleInstance(scheduleInstance);
    setStartTime(timeslot);
  };

  return (
    <div className="tsc__calendar">
      {renderActions()}
      {showPicker && (
        <div className="picker-calendar">
          <Calendar
            calendarType="US"
            value={currentDate}
            onChange={(date) => {
              setShowPicker(false);
              setCurrentDate(date);
            }}
            minDetail="year"
          />
        </div>
      )}
      {loading ? (
        <Loading />
      ) : (
        <>
          <Week
            timeslots={timeslots}
            selectedTimeslot={startTime}
            weekToRender={currentWeek}
            onSelectTimeslot={handleSelectTimeslot}
            tz={tz}
          />
        </>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    cart: state.ui.cart,
    changeBooking: state.ui.changeBooking,
    compatibleDates: state.ui.compatibleDates,
    customerLocation: state.ui.customerLocation,
    homeAddress: state.ui.homeAddress,
    schedule: state.ui.schedule,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(TimeSlotCalendar);
