import React, { useEffect, useState } from 'react';

import StripeCheckout from 'react-stripe-checkout';

import { Button, Card, CardPanel, Icon, Modal } from 'react-materialize';

import { connect } from 'react-redux';
import { apiPost } from '../../brainApi';
import {
  mediaQueryMaxWidth600,
  creditCardInfoToDescription,
} from '../../helper';
import {
  getStripePublishableKey,
  SQUARE,
  STRIPE,
} from '../../paymentProcessor';
import SquareModal from '../components/SquareModal';

const UpdateCard = (props) => {
  const [loading, setLoading] = useState(false);
  const [updateButtonText, setUpdateButtonText] = useState('Update Payment');
  const [newLast4, setNewLast4] = useState(false);
  const [newBrand, setNewBrand] = useState(false);
  const [cardFailed, setCardFailed] = useState(false);

  const {
    customerId,
    brand,
    last4,
    email,
    paymentProcessor,
    z3pConfiguration,
  } = props;
  const { customer_facing_name: serviceProviderName } = z3pConfiguration;

  const resetUpdateButtonText = () => {
    setUpdateButtonText(
      mediaQueryMaxWidth600().matches
        ? 'Update Payment'
        : 'Update Payment Method',
    );
  };

  useEffect(() => {
    resetUpdateButtonText();
  }, []);

  const onUpdatedToken = (token) => {
    const payload = {
      customer_id: customerId,
    };

    if (paymentProcessor === STRIPE) {
      payload.stripe_token = token.id;
    } else if (paymentProcessor === SQUARE) {
      payload.square_token = token;
    }

    setUpdateButtonText('Updating...');
    setLoading(true);

    apiPost(`/booking/update-payment-method/${customerId}`, payload)
      .then((response) => {
        setLoading(false);
        resetUpdateButtonText();

        if (response.status === 200) {
          return response.json();
        }
        setCardFailed(true);
        return response.text().then((text) => {
          throw new Error(text);
        });
      })
      .then((json) => {
        setNewBrand(json.brand);
        setNewLast4(json.last4);
        setCardFailed(!json.success);
      })
      .catch((error) => console.log(error));
  };

  const explainModal = (
    <Modal
      header="What's My Active Payment Method?"
      trigger={
        <span style={{ color: '#9f7a7a', cursor: 'pointer' }}>
          {' '}
          <Icon>help</Icon>
        </span>
      }
    >
      <p>
        This is the credit card we&apos;ll use to process payment for your
        upcoming services. We process payments the evening after a service is
        completed. You may change your payment method at any time until your
        service is complete.
        <br />
        <br />
        We support one active payment method per account at this time. If you
        book a new service, your active payment method will be changed to the
        card you used to book that service.
      </p>
    </Modal>
  );

  const stripeKey = getStripePublishableKey();

  return (
    <Card title="Payment" className="swCard">
      {newBrand && !cardFailed && (
        <div>
          <CardPanel className="green lighten-4">
            Active payment method successfully updated!
          </CardPanel>
        </div>
      )}
      {!!cardFailed && (
        <div>
          <CardPanel className="red lighten-4">
            There was an error with your card. Please try again.
          </CardPanel>
        </div>
      )}

      {newBrand ? (
        <div>
          <p>
            <div style={{ float: 'left' }}>
              <strong>Active Payment Method: </strong>
              {creditCardInfoToDescription(newBrand, newLast4, ' ****-')}
            </div>
            {explainModal}
          </p>
        </div>
      ) : (
        <p>
          {(brand || last4) && (
            <>
              <div style={{ float: 'left' }}>
                <strong>Active Payment Method: </strong>
                {creditCardInfoToDescription(brand, last4, ' ****-')}
              </div>
              {explainModal}
            </>
          )}
        </p>
      )}

      {paymentProcessor === STRIPE && (
        <StripeCheckout
          disabled={loading}
          email={email}
          token={(token) => onUpdatedToken(token)}
          stripeKey={stripeKey}
          allowRememberMe={false}
          name={serviceProviderName}
          panelLabel="Update"
          zipCode
        >
          <Button className="btn-teal">{updateButtonText}</Button>
        </StripeCheckout>
      )}

      {paymentProcessor === SQUARE && (
        <SquareModal
          disabled={loading}
          onToken={(token) => onUpdatedToken(token)}
          openButtonText={updateButtonText}
          submitButtonText="Update"
        />
      )}
    </Card>
  );
};

function mapStateToProps(state) {
  return {
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(UpdateCard);
