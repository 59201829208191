/* globals document window */

import React, { useEffect, useState } from 'react';
import { GoogleApiWrapper } from 'google-maps-react';
import { Button, Checkbox, Col, Row, TextInput } from 'react-materialize';
const AddressAutocomplete = ({
  setAddressError,
  setAddressErrorMessage,
  onSelectAddress,
  onEnterManually,
  selectedHomeAddress
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [googleAPISucceeded, setGoogleAPISucceeded] = useState(false);
  // ID associated with Google Maps suggested Address
  const [placeID, setPlaceID] = useState('');
  const [selectedAddress, setSelectedAddress] = useState('');

  const { google } = window;
  const autocompleteService = new google.maps.places.AutocompleteService();
  const placeService = new google.maps.places.PlacesService(
    document.createElement('div'),
  );
  const sessionToken = new google.maps.places.AutocompleteSessionToken();
  const googleMapsServiceOK = google.maps.places.PlacesServiceStatus.OK;

  const autocompleteCallback = (predictions, status) => {
    if (status !== googleMapsServiceOK) {
      return;
    }
    setSuggestions(
      predictions.map((p, idx) => ({
        id: p.id,
        description: p.description,
        placeId: p.place_id,
        index: idx,
        main_text: p.structured_formatting.main_text,
        secondary_text: p.structured_formatting.secondary_text,
        matchedSubstrings: p.matched_substrings,
        terms: p.terms,
        types: p.types,
      })),
    );
    setLoading(false);
    setGoogleAPISucceeded(true);
  };

  const fetchPredictions = (event) => {
    const { value } = event.target;
    const types = ['address'];
    if (value.length) {
      setLoading(true);
      autocompleteService.getPlacePredictions(
        {
          input: value,
          types,
          sessionToken,
        },
        autocompleteCallback,
      );
    } else {
      setSuggestions([]);
    }
  };

  const getPlaceDetails = (placeId) => {
    placeService.getDetails(
      {
        placeId,
        sessionToken,
      },
      (place, status) => {
        if (status === googleMapsServiceOK) {
          const addressComponents = place.address_components;
          const address = {
            formatted: place.formatted_address,
            mapURL: place.url,
          };
          // More info on address types:
          // https://developers.google.com/maps/documentation/geocoding/intro#Types
          Object.values(addressComponents).forEach((adr) => {
            if (adr.types.includes('street_number'))
              address.streetNumber = adr.long_name;
            else if (adr.types.includes('route'))
              address.street = adr.long_name;
            else if (adr.types.includes('neighborhood'))
              address.neighborhood = adr.long_name;
            else if (adr.types.includes('locality'))
              address.city = adr.long_name;
            else if (adr.types.includes('administrative_area_level_2'))
              address.county = adr.long_name;
            else if (adr.types.includes('administrative_area_level_1')) {
              address.state = adr.long_name;
              address.stateShort = adr.short_name;
            } else if (adr.types.includes('country'))
              address.country = adr.long_name;
            else if (adr.types.includes('postal_code'))
              address.postalCode = adr.long_name;
          });
          if (!address.streetNumber) {
            setAddressError(true);
            // Check if beginning of selectedAddress is a number to see if street number was specified
            if (/\d/.test(selectedAddress[0])) {
              setAddressErrorMessage(
                <>
                  We were unable to find an exact match for your address. Please
                  double-check the address details below:
                  <br />
                  <br />
                  Address Entered: {selectedAddress}
                  <br />
                  Address Found: {address.formatted}
                </>,
              );
            } else {
              setAddressErrorMessage(
                'This address is missing a street number. Please add a street number so that we have your exact address.',
              );
            }
          }
          onSelectAddress(address);
          setSuggestions([]);
        }
      },
    );
  };

  useEffect(() => {
    if (selectedAddress && placeID) {
      getPlaceDetails(placeID);
    }
  }, [selectedAddress, placeID]);

  return (
      <div className="forms-inputs">
        <Row>
          <Col className="s12">
            <TextInput
              label="Address"
              id="searchTextField"
              value={selectedHomeAddress.addressNameSelected}
              onChange={fetchPredictions}
              m={12}
              s={12}
            />
          </Col>
        </Row>
       
        {(suggestions.length > 0 ) && (
        <ul className="suggestion-list">
          {suggestions.map(({ description, id, placeId }) => (
            <li
              key={id}
              className="button-to-link"
              onClick={(event) => {
                setSelectedAddress(event.target.innerText);
                setPlaceID(placeId);
              }}
            >
              {description}
            </li>
          ))}
          {loading && !googleAPISucceeded && <li>...</li>}
        </ul>
        )}
        <p className="address_link">
          <button
            type="button"
            className="button-to-link"
            onClick={onEnterManually}
          >
            Enter address manually
          </button>
        </p>
      </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
})(AddressAutocomplete);
