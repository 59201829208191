// src/components/About/index.js

// Here's how the breadcrumbs/labels work in this component:
// - Label "Address": Begin
// - Label "Vehicle": VehicleEntry
// - Label "Location": Place
// - Label "Services": SimpleServices, Services, Tires, etc (including Account, on signup)
// - Label "Schedule": Schedule, Location, Review, Finished

import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { Col, Row } from 'react-materialize';
import MediaQuery from 'react-responsive';

import YourCar from './YourCar';
import Zippity from './Zippity';

import { isFromQuote, shouldAutoSelectLocation } from '../../helper';
import { hasAccessToken } from '../../brainApi';
import MotorAtttribution from './MotorAttribution';

function breadCrumbClick(pathEnd, index, i) {
  if (index >= i) {
    browserHistory.push(`/pricing/${pathEnd}`);
  }
}

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLocationBreadCrumb: true,
      showVehicleBreadCrumb: false,
    };
  }

  componentDidMount() {
    // If the only service location is shop or workplace and there is only 1 active client location,
    // do not show the Location breadcrumb since location is auto-selected
    const { clientLocations, z3pConfiguration } = this.props;
    const {
      service_locations: serviceLocations,
      is_skipping_vehicle: isSkippingVehicle,
      skip_simple_services_to: skipSimpleServicesTo,
    } = z3pConfiguration;
    if (shouldAutoSelectLocation(serviceLocations, clientLocations)) {
      this.setState({ showLocationBreadCrumb: false });
    }
    this.setState({ showVehicleBreadCrumb: !isSkippingVehicle });
  }

  render() {
    const {
      currentStep = 'Services',
      h1,
      hideBreadcrumbs,
      h2,
      referrer,
      cart,
      hideYourCar,
      children,
      onContinue,
      showContinue,
      enableContinue,
      alertText = 'Please fill out all required fields before continuing.',
      showMotorAttribution = false,
      vehicle,
      hideNames,
      z3pConfiguration,
      backText
    } = this.props;
    const { service_locations: serviceLocations } = z3pConfiguration;
    const { skip_simple_services_to } = z3pConfiguration;

    const { showLocationBreadCrumb, showVehicleBreadCrumb } = this.state;
    const hasShopService = serviceLocations.includes('shop');
    // All possible steps are: ['Address', 'Vehicle', 'Location', 'Services', 'Schedule']
    const names = [];

    names.push('Services');
    if (!hasAccessToken()) {
      names.push('Account');
    }
    if (hasAccessToken() && !hasShopService) {
      names.push('Address');
    }
    if (hasAccessToken() && showLocationBreadCrumb && hasShopService) {
      names.push('Location');
    }
    if (!isFromQuote(cart) && showVehicleBreadCrumb) {
      names.push('Vehicle');
    }
    names.push('Schedule');
    // eslint-disable-next-line no-undef
    const { pathname } = window.location;
    const segments = pathname.split('/');
    const currentPath = segments.pop() || '/';
    const currentIndex = names.indexOf(currentStep);
    

    // pathEnd: Convert from human-readable label to URL path ending, e.g. if the user clicks "Location", we open the URL "/pricing/place"
    // abbrBreadcrumb: Convert label to abbreviated breadcrumb (mobile device sizing)
    const labelConversion = {
      Services: {
        pathEnd: 'tires',
        abbrBreadcrumb: 'Select Services',
      },
      Account: {
        pathEnd: 'account',
        abbrBreadcrumb: 'Login',
      },
      Address: {
        pathEnd: 'begin',
        abbrBreadcrumb: 'Address',
      },
      Location: {
        pathEnd: 'place',
        abbrBreadcrumb: 'Select a Location',
      },
      Vehicle: {
        pathEnd: 'vehicle',
        abbrBreadcrumb: 'Select a Vehicle',
      },
      Schedule: {
        pathEnd: 'schedule',
        abbrBreadcrumb: 'Select Availability',
      },
    };
    const items = names.map((label, i) => {
      let status = 'active';
      if (i < currentIndex) {
        status = 'completed';
      } else if (i > currentIndex) {
        status = 'disabled';
      }else if(currentPath === 'finished'){
        status = 'completed';
      }
      const { pathEnd } = labelConversion[label];
      const extra =
        status === 'completed' ? <i className="material-icons">done</i> : '';
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <li
          key={label}
          className={status}
          onClick={() => breadCrumbClick(pathEnd, currentIndex, i)}
        >
          {extra}
          <div className="node" />
          {label}
        </li>
      );
    });
    const content = (
      <div className="col m12 r-float">
        <MediaQuery minWidth={301}>
          {(matches) => {
            // If screen width > 300px
            if (matches && !hideNames) {
              return (
                <>
                  {hideBreadcrumbs ? (
                    ''
                  ) : (
                    <ul className="header progress-bar inline">{items}</ul>
                  )}
                  {!hasShopService &&
                    (currentIndex > 0) && (
                    <div className="back_link" onClick={() => { breadCrumbClick(labelConversion[names[(currentIndex - 1 : -1)]].pathEnd, currentIndex > 0 ? currentIndex - 1 : -1 , 0); }}>
                      <a href="javascript:;"><i className="material-icons">arrow_back_ios_new</i></a> Back to {names[currentIndex > 0 ? currentIndex - 1 : -1]}
                    </div>
                  )}
                  <h3>{h1}</h3>
                </>
              );

            }
            // If screen width <= 300px
            if (!hideBreadcrumbs && currentIndex > 0) {
              const prevIndex = currentIndex > 0 ? currentIndex - 1 : -1;
              const prevPath = labelConversion[names[prevIndex]].pathEnd;
              return (
                <>
                  <button
                    type="button"
                    className="button-to-link abbreviated-breadcrumb"
                    onClick={() => {
                      breadCrumbClick(prevPath, prevIndex, 0);
                    }}
                  >
                    &#8249; {labelConversion[names[prevIndex]].abbrBreadcrumb}
                  </button>
                  <h3>{h1}</h3>
                </>
              );
            }
            return <h3>{h1}</h3>;
          }}
        </MediaQuery>
        <Row className="marginBottom0">
          <Col s={12} m={8}>
            <h5 className={!!h1 && !!h2 && 'subtext'}>{h2}</h5>
          </Col>
        </Row>
      </div>
    );

    return (
      <Zippity containerId="pricing" active="pricing">
        <div className="grey-splash row">
          {referrer.code && (
            <div
              style={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #e0cdcc',
                padding: '.25em 1em',
                borderRadius: '.5em',
                fontWeight: '800',
              }}
            >
              <p>
                <span style={{ color: '#d74742' }}>
                  REFERRAL DISCOUNT APPLIED |
                </span>{' '}
                Additional 20% Off All Service Packages
              </p>
            </div>
          )}

          {content}

          {hideYourCar ? (
            <div className="col m12 white-splash">
              <div className="row">{children}</div>
              {showMotorAttribution && (
                <MotorAtttribution make={vehicle.make} />
              )}
            </div>
          ) : (
            <div>
              <div className="col m12 s12 white-splash calendar-responsive marginBottom0">
                <div className="row marginBottom0">{children}</div>
                {showMotorAttribution && (
                  <MotorAtttribution make={vehicle.make} />
                )}
              </div>
              {/* <YourCar
                onContinue={onContinue}
                showContinue={showContinue}
                enableContinue={enableContinue}
                alertText={alertText}
              /> */}
            </div>
          )}
        </div>
      </Zippity>
    );
  }
}

function mapStateToProps(state) {
  return {
    referrer: state.ui.referrer,
    cart: state.ui.cart,
    vehicle: state.ui.pricing,
    clientLocations: state.ui.clientLocations,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(Pricing);
