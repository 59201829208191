import React from 'react';
import { Z3P_CONFIG } from './helper';
import { loadClientLocationDetailsAndPriceList } from './brain2';
import { stateAbbreviationToName } from './screens/components/UsStates';
import { apiPut } from './brainApi';

export function workplaceQuestionReason() {
  return (
    <p>
      {Z3P_CONFIG.customer_facing_name} has relationships with a number of
      corporate partners to provide convenient car maintenance at your
      workplace, while you work. Select your workplace to take advantage of this
      perk!
    </p>
  );
}

export async function setUserWorkplace(
  dispatch,
  clientLocationId,
  vehicle_id,
  callback,
) {
  return loadClientLocationDetailsAndPriceList(
    dispatch,
    clientLocationId,
    vehicle_id,
  ).then(() => {
    apiPut(
      clientLocationId
        ? `/customer/client-location/${clientLocationId}`
        : '/customer/delete-client-location',
    );
    callback();
  });
}
