import React from 'react';

const TireImage = (props) => {
  const { largeImages } = props;

  if (largeImages && largeImages.length) {
    return <img className="tireImage" src={largeImages[0]} alt="Tire" />;
  }

  const noImageText =
    'A real image of this tire was not provided. This is a placeholder and does not reflect the actual characteristics of the tire listed.';
  return (
    <img
      className="tireImage"
      src="/static/img/tires/tire-without-image.jpg"
      alt="Tire"
      title={noImageText}
    />
  );
};

export default TireImage;
