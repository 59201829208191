import React from 'react';
import { connect } from 'react-redux';
import { TAB_TO_TYPE } from '../../vehicleHelper';
import Pricing from '../components/Pricing';

const TYPE_TO_LABEL = {
  rv: 'RV',
  atv: 'ATV',
  motorcycle: 'Motorcycle',
  boat: 'Boat',
  plane: 'Plane',
  automobile: 'Car/Truck/SUV/Van',
};

const VehicleTypeSelection = ({ dispatch, availableTabs, setActiveTab }) => {
  const buttonsToRender = new Set();
  availableTabs.forEach((tab) => {
    if (TYPE_TO_LABEL[TAB_TO_TYPE[tab]]) {
      buttonsToRender.add(TAB_TO_TYPE[tab]);
    }
  });

  const selectVehicleType = (selectedType) => {
    dispatch({
      type: 'SELECT_VEHICLE_TYPE',
      vehicleType: selectedType,
    });
    if (selectedType !== 'automobile') {
      setActiveTab(selectedType);
    }
  };

  return (
    <Pricing
      currentStep="Vehicle"
      h1="Select Your Vehicle Type"
      h2="What type of vehicle are you scheduling services for?"
    >
      {[...buttonsToRender].map((type) => {
        if (TYPE_TO_LABEL[type]) {
          return (
            <div className="row" key={type}>
              <span
                onClick={() => selectVehicleType(type)}
                className="waves-effect waves-light btn-large"
                role="button"
                tabIndex="-1"
                onKeyPress={() => selectVehicleType(type)}
              >
                {TYPE_TO_LABEL[type]}
              </span>
            </div>
          );
        }
        return null;
      })}
    </Pricing>
  );
};

export default connect()(VehicleTypeSelection);
