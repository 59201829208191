/* globals window */

import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-materialize';
import { connect } from 'react-redux';
import { possibleProStatuses } from '../helper';

const ChurnedModal = ({ proConfiguration }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [redirectSecondsLeft, setRedirectSecondsLeft] = useState(15);

  const redirect = () => {
    setInterval(() => {
      setRedirectSecondsLeft((prev) => {
        if (prev > 0) {
          return prev - 1;
        }
        window.location.assign('https://zippitycars.com/');
        return 0;
      });
    }, 1000);
  };

  useEffect(() => {
    if (proConfiguration.status === possibleProStatuses.churned) {
      setModalOpen(true);
      redirect();
    }
  }, []);

  return (
    <Modal
      header="We're Sorry"
      actions={[
        <Button
          className="btn-gray"
          style={{ height: 'auto' }}
          onClick={() => window.location.assign('https://zippitycars.com/')}
        >
          See More Service Providers
        </Button>,
      ]}
      open={modalOpen}
      options={{ dismissible: false }}
    >
      <div style={{ textAlign: 'center' }}>
        <div>This business no longer supports online booking.</div>
        <div>
          Please click the button below or stay on the page to be redirected to
          zippitycars.com to book service with a different provider in your
          area.
        </div>
        <br />
        <div>Redirecting in {redirectSecondsLeft}...</div>
      </div>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    proConfiguration: state.ui.proConfiguration,
  };
}

export default connect(mapStateToProps, null)(ChurnedModal);
