/* globals window alert */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'react-materialize';
import equal from 'fast-deep-equal';
import Moment from 'moment';
import Raven from 'raven-js';

import Zippity from '../components/Zippity';
import { addMessage } from '../../zippity';
import { apiGet, apiPost } from '../../brainApi';
import { calculateCosts } from '../../costCalculator';

import FutureQuote from './FutureQuote';
import UpsellItem from './UpsellItem';
import {
  getEmailSupportLink,
  getPhoneSupportLink,
  setPageTitle,
  determineIfSingleTotalColumn,
  priceOr,
} from '../../helper';

const isOnReceiptPage = window.location.pathname.includes('receipt');

class ApproveUpsellsItemized extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      scheduledWork: {},
      pendingServices: [],
      originalSubtotal: 0,
      upsellSubtotal: 0,
      discounts: [],
      suppliesFee: 0,
      salesTax: 0,
      minimumServiceCharge: 0,
      newTotal: 0,
      disableConfirmButton: true,
      confirmButtonText: 'Confirm',
      validationText: '',
    };

    this.calculateNewTotal = this.calculateNewTotal.bind(this);
    this.isConfirmButtonDisabled = this.isConfirmButtonDisabled.bind(this);

    this.handleApprovalButtonClick = this.handleApprovalButtonClick.bind(this);
    this.handleConfirmButtonClick = this.handleConfirmButtonClick.bind(this);
  }

  componentWillMount() {
    setPageTitle('Suggested Services');
  }

  componentDidMount() {
    const { params } = this.props;
    const { sw_id, customer_id } = params;

    // Get the details on this SW
    apiGet(`/scheduled-work/${sw_id}/${customer_id}`)
      .then((response) => response.json())
      .then((json) => {
        const isValidUrl = json.customer_id.toString() === customer_id;

        if (!isValidUrl) {
          window.location = 'https://zippitycars.com/';
          return;
        }

        const pendingServices = json.services.filter(
          (service) => service.upsell_status === 'pending',
        );

        // Default all pending services to "approved"
        const newPendingServices = pendingServices.map((service) => ({
          ...service,
          upsell_status: 'approved',
        }));

        this.setState(
          {
            scheduledWork: json,
            pendingServices: newPendingServices,
            loading: false,
          },
          this.calculateNewTotal,
        );
      })
      .catch((error) => {
        console.log(error);
        Raven.captureException(error);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { pendingServices } = this.state;
    if (!equal(pendingServices, prevState.pendingServices)) {
      this.calculateNewTotal();
      this.isConfirmButtonDisabled();
    }
  }

  isConfirmButtonDisabled() {
    const { pendingServices } = this.state;
    const disableConfirmButton =
      pendingServices &&
      pendingServices.some((service) => !service.upsell_status);

    if (!disableConfirmButton) {
      this.setState({ validationText: '' });
    }

    this.setState({ disableConfirmButton });
  }

  calculateNewTotal() {
    const { scheduledWork, pendingServices } = this.state;

    if (!pendingServices || !scheduledWork) {
      return;
    }

    const approvedPendingServices = pendingServices.filter(
      (service) => service.upsell_status === 'approved',
    );

    const scheduledServices = scheduledWork.services.filter(
      (service) => !service.is_upsell || service.upsell_status === 'approved',
    );

    const approvedAndScheduledServices = approvedPendingServices.concat(
      scheduledServices,
    );

    const originalSubtotal = scheduledServices.reduce(
      (sum, service) => sum + parseFloat(service.total_cost_dollars),
      0,
    );

    const upsellSubtotal = approvedPendingServices.reduce(
      (sum, service) => sum + parseFloat(service.total_cost_dollars),
      0,
    );

    const bookingCreatedDate = Moment(scheduledWork.created_at);

    const combinedNewCosts = calculateCosts(
      scheduledWork.coupon_detail,
      approvedAndScheduledServices,
      scheduledWork.client_location_id,
      scheduledWork.tax_rate_percent,
      bookingCreatedDate,
      isOnReceiptPage && scheduledWork.tip_amount_dollars, // only include tip in calculation if appointment is complete
    );

    this.setState({
      originalSubtotal,
      upsellSubtotal,
      discounts: combinedNewCosts.discounts,
      suppliesFee: combinedNewCosts.estimatedSuppliesFeeDollars,
      salesTax: combinedNewCosts.estimatedSalesTaxDollars,
      minimumServiceCharge: combinedNewCosts.minimumServiceChargeAmountDollars,
      newTotal: combinedNewCosts.finalTaxedTotalDollars,
    });
  }

  handleApprovalButtonClick(selectedService, upsell_status) {
    const { pendingServices } = this.state;
    const newPendingServices = pendingServices.map((service) => {
      if (service.id === selectedService.id) {
        return { ...service, upsell_status };
      }
      return service;
    });

    this.setState({ pendingServices: newPendingServices });
  }

  handleConfirmButtonClick() {
    const { disableConfirmButton, pendingServices, scheduledWork } = this.state;
    const { z3pConfiguration } = this.props;
    const { customer_facing_phone: phone } = z3pConfiguration;
    if (disableConfirmButton) {
      return this.setState({
        validationText:
          'Please select "Approve" or "Not Today" for each of our additional suggested services.',
      });
    }

    this.setState({
      disableConfirmButton: true,
      confirmButtonText: 'Please wait...',
    });

    const upsellResponses = pendingServices.map((service) => ({
      id: service.id,
      upsell_status: service.upsell_status,
    }));

    const approvedAnyServices = upsellResponses.some(
      (response) => response.upsell_status === 'approved',
    );

    const { params, dispatch } = this.props;
    const { customer_id } = params;

    return apiPost(
      `/booking/approve-services/${scheduledWork.id}/${customer_id}`,
      {
        upsell_responses: upsellResponses,
      },
    )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('Error updating upsell services');
        }
        return response;
      })
      .then(() => {
        if (approvedAnyServices) {
          addMessage(
            dispatch,
            'Your services have been approved! Your updated receipt is below.',
            10,
            { pageToDisplayOn: window.location.href },
          );
        } else {
          addMessage(dispatch, 'Your updated receipt is below.', 10, {
            pageToDisplayOn: window.location.href,
          });
        }
        window.location.reload();
      })
      .catch(() =>
        alert(
          `Sorry, there was an internal error. Please contact customer support if this persists: ${phone}`,
        ),
      )
      .catch((error) => Raven.captureException(error))
      .catch((error) => console.log(error));
  }

  render() {
    const {
      loading,
      pendingServices,
      scheduledWork,
      originalSubtotal,
      upsellSubtotal,
      discounts,
      suppliesFee,
      salesTax,
      minimumServiceCharge,
      newTotal,
      disableConfirmButton,
      confirmButtonText,
      validationText,
    } = this.state;
    const {
      customer,
      vehicle,
      parking,
      keyosk,
      tip_amount_dollars,
    } = scheduledWork;
    const { z3pConfiguration } = this.props;
    const {
      customer_facing_name: serviceProviderName,
      is_using_service_report: isUsingServiceReport,
    } = z3pConfiguration;

    if (loading) {
      return (
        <Zippity>
          <Row>
            <Col s={12} className="center">
              <h2>Loading...</h2>
            </Col>
          </Row>
        </Zippity>
      );
    }

    const swIsActive = ['keys_available', 'scheduled'].includes(
      scheduledWork.status,
    );
    const swIsFinished = ['finished'].includes(scheduledWork.status);

    if (
      isUsingServiceReport &&
      !scheduledWork.hc_is_complete &&
      !swIsFinished
    ) {
      return (
        <Zippity>
          <div className="approve-services">
            <h4>Your {serviceProviderName} Service</h4>
            <p>Hey {customer.first_name},</p>
            <p>
              Your Health Check is not yet complete. Please check back here in a
              bit!
            </p>
            <p>
              If you have any questions, please contact us at{' '}
              {getEmailSupportLink()} or {getPhoneSupportLink()}.
            </p>
            <p>Thanks,</p>
            <p>The {serviceProviderName} Team</p>
          </div>
        </Zippity>
      );
    }

    const vehicleDescription = vehicle?.display_name;

    const allConfirmedServices = scheduledWork.services.filter(
      (s) => !s.is_upsell || s.upsell_status === 'approved',
    );
    const futureUpsells = scheduledWork.services.filter(
      (s) => s.upsell_status === 'future',
    );
    const rejectedUpsells = scheduledWork.services.filter(
      (s) => s.upsell_status === 'rejected',
    );
    const hasFutureUpsell = futureUpsells && futureUpsells.length > 0;
    const hasRejectedUpsell = rejectedUpsells && rejectedUpsells.length > 0;
    const existingShowTotalOnly = determineIfSingleTotalColumn(
      allConfirmedServices,
    );

    const pendingShowTotalOnly = determineIfSingleTotalColumn(pendingServices);

    const showTotalOnly = existingShowTotalOnly && pendingShowTotalOnly;

    const serviceWidth = !showTotalOnly ? '40%' : '80%';

    const shouldShowTipLineItem =
      scheduledWork.tip_amount_dollars > 0 && isOnReceiptPage;
    return (
      <Zippity>
        {pendingServices.length === 0 && (
          <div className="approve-services">
            <Row>
              <Col s={12} m={10} offset="m1" className="neue">
                <h4>Your {serviceProviderName} Service</h4>

                <p>Hey {customer.first_name},</p>

                {swIsFinished ? (
                  <p>
                    These are the services we completed on {scheduledWork.date}.
                  </p>
                ) : (
                  <p>These are the services we&apos;re performing.</p>
                )}

                <div className="approve-service-table">
                  <table>
                    <tr style={{ backgroundColor: 'lightGray' }}>
                      <th width={serviceWidth}> SERVICE</th>
                      {!showTotalOnly ? (
                        <>
                          <th width="20%">PARTS</th>
                          <th width="20%">LABOR</th>
                          <th width="20%">TOTAL</th>
                        </>
                      ) : (
                        <th width="20%">COST</th>
                      )}
                    </tr>

                    {allConfirmedServices.map((s) => (
                      <tr>
                        <td>{s.display_name || s.long_name}</td>

                        {!showTotalOnly && (
                          <>
                            <td>{priceOr(s.parts_cost_dollars, '--')}</td>
                            <td>{priceOr(s.labor_cost_dollars, '--')}</td>
                          </>
                        )}
                        <td>{priceOr(s.total_cost_dollars, 'FREE')}</td>
                      </tr>
                    ))}
                  </table>
                </div>

                <div
                  className="approve-service-table"
                  style={{ marginTop: '15px' }}
                >
                  <table>
                    <tr style={{ backgroundColor: 'lightGray' }}>
                      <th colSpan="2">TOTALS</th>
                    </tr>
                    <tr>
                      <td>Services</td>
                      <td>
                        {`$${(originalSubtotal + upsellSubtotal).toFixed(2)}`}
                      </td>
                    </tr>

                    {discounts.map((discount) => (
                      <tr key={discount}>
                        <td>Discount</td>
                        <td>{discount}</td>
                      </tr>
                    ))}

                    {suppliesFee > 0 && (
                      <tr>
                        <td>Supplies and Environmental Disposal Fee</td>
                        <td>{`$${suppliesFee.toFixed(2)}`}</td>
                      </tr>
                    )}

                    <tr>
                      <td>Sales Tax</td>
                      <td>{`$${salesTax.toFixed(2)}`}</td>
                    </tr>

                    {!!minimumServiceCharge && (
                      <tr>
                        <td>Minimum Service Charge</td>
                        <td>{`$${minimumServiceCharge.toFixed(2)}`}</td>
                      </tr>
                    )}

                    {shouldShowTipLineItem && (
                      <tr>
                        <td>Tip</td>
                        <td>{`$${tip_amount_dollars.toFixed(2)}`}</td>
                      </tr>
                    )}

                    <tr>
                      <th>Total</th>
                      <th>{`$${newTotal.toFixed(2)}`}</th>
                    </tr>
                  </table>
                </div>

                {/* If the SW is still "active", tell them they can make changes by contacting us. */}
                {swIsActive && (
                  <p>
                    If you&apos;d like to make any changes, please contact us
                    right away at {getEmailSupportLink()} or{' '}
                    {getPhoneSupportLink()}.
                  </p>
                )}

                {(hasFutureUpsell || hasRejectedUpsell) && (
                  <Fragment>
                    <br />
                    <br />
                    <hr />
                    <p>
                      {swIsFinished
                        ? 'And here are the other services we recommended.'
                        : 'And here are the services we recommended for a future visit.'}
                    </p>
                    <FutureQuote
                      futureUpsells={futureUpsells}
                      rejectedUpsells={rejectedUpsells}
                      vehicle={vehicle}
                      customerId={customer.customer_id}
                      keyosk={keyosk}
                      parking={parking}
                    />
                  </Fragment>
                )}
              </Col>
            </Row>
          </div>
        )}

        {pendingServices.length > 0 && (
          <div className="approve-services">
            <Row>
              <Col s={12} m={10} offset="m1">
                <Row>
                  <Col className="neue">
                    <h4>Additional Suggested Services</h4>

                    <p>Hey {customer.first_name}!</p>

                    <p>
                      We have a few additional services that we suggest get done
                      today. Select the services you&apos;d like to approve
                      below and we&apos;ll get started. We won&apos;t begin any
                      additional work without your approval.
                    </p>

                    <p>
                      If you have any questions, please reply to the health
                      check results email we sent you.
                    </p>
                  </Col>
                </Row>

                {vehicleDescription && (
                  <Row>
                    <Col s={12} className="neue">
                      <strong>{vehicleDescription}</strong>
                    </Col>
                  </Row>
                )}

                <div className="approve-service-table">
                  <table>
                    <tr style={{ backgroundColor: 'lightGrey ' }}>
                      <div className="small-screen-wrap">
                        <th colSpan="1" style={{ textAlign: 'left' }}>
                          SERVICE
                        </th>
                        {!showTotalOnly ? (
                          <>
                            <th colSpan="1">PARTS</th>
                            <th colSpan="1">LABOR</th>
                            <th colSpan="1">TOTAL</th>
                          </>
                        ) : (
                          <th colSpan="1">COST</th>
                        )}
                      </div>
                      <div className="small-screen-hide">
                        <th colSpan="3" />
                      </div>
                    </tr>

                    {pendingServices.map((service) => (
                      <UpsellItem
                        key={service.id}
                        showTotalOnly={showTotalOnly}
                        handleApprovalButtonClick={
                          this.handleApprovalButtonClick
                        }
                        service={service}
                      />
                    ))}

                    <tr style={{ backgroundColor: 'lightGray' }}>
                      <div className="small-screen-wrap">
                        <th colSpan="4">Your Original Services</th>
                      </div>
                      <div className="small-screen-hide">
                        <th colSpan="2" />
                      </div>
                    </tr>

                    {allConfirmedServices.map((s) => (
                      <tr>
                        <div className="small-screen-wrap">
                          <td style={{ textAlign: 'left', marginLeft: '3px' }}>
                            {s.display_name || s.long_name}
                          </td>
                          {!showTotalOnly && (
                            <>
                              <td>{priceOr(s.parts_cost_dollars, '--')}</td>
                              <td>{priceOr(s.labor_cost_dollars, '--')}</td>
                            </>
                          )}
                          <td>{priceOr(s.total_cost_dollars, 'FREE')}</td>
                        </div>
                      </tr>
                    ))}
                  </table>
                </div>

                <p>{validationText}</p>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    large
                    onClick={this.handleConfirmButtonClick}
                    className={`${disableConfirmButton ? 'grey' : 'green'} `}
                    style={{ fontWeight: '600', padding: '0 6rem' }}
                  >
                    {confirmButtonText}
                  </Button>
                </div>

                <div
                  className="approve-service-table"
                  style={{ marginTop: '10px' }}
                >
                  <table>
                    <tr style={{ backgroundColor: 'lightGray' }}>
                      <th colSpan="2">TOTALS</th>
                    </tr>
                    <tr>
                      <td>Services</td>
                      <td>
                        {`$${(originalSubtotal + upsellSubtotal).toFixed(2)}`}
                      </td>
                    </tr>

                    {discounts.map((discount) => (
                      <tr key={discount}>
                        <td>Discount</td>
                        <td>{discount}</td>
                      </tr>
                    ))}

                    {suppliesFee > 0 && (
                      <tr>
                        <td>Supplies and Environmental Disposal Fee</td>
                        <td>{`$${suppliesFee.toFixed(2)}`}</td>
                      </tr>
                    )}

                    <tr>
                      <td>Sales Tax</td>
                      <td>{`$${salesTax.toFixed(2)}`}</td>
                    </tr>

                    {!!minimumServiceCharge && (
                      <tr>
                        <td>Minimum Service Charge</td>
                        <td>{`$${minimumServiceCharge.toFixed(2)}`}</td>
                      </tr>
                    )}

                    <tr>
                      <th>Updated Total</th>
                      <th>{`$${newTotal.toFixed(2)}`}</th>
                    </tr>
                  </table>
                </div>

                {hasFutureUpsell && (
                  <Fragment>
                    <br />
                    <br />
                    <hr />
                    <p>
                      And here are the services we recommend for a future visit.
                    </p>
                    <FutureQuote
                      futureUpsells={futureUpsells}
                      rejectedUpsells={rejectedUpsells}
                      vehicle={vehicle}
                      customerId={customer.customer_id}
                      keyosk={keyosk}
                      parking={parking}
                    />
                  </Fragment>
                )}
              </Col>
            </Row>
          </div>
        )}
      </Zippity>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.ui.account,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(ApproveUpsellsItemized);
