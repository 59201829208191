import React from 'react';
import { Button, Icon, Row, Col } from 'react-materialize';
import InputMask from 'react-input-mask';

const AdditionalContacts = (props) => {
  const {
    areAdditionalContactsValid = true,
    setAreAdditionalContactsValid,
    additionalContactList,
    setAdditionalContactList,
    savePhoneNumberList = () => {},
    cancelPhoneNumberUpdates = () => {},
    showActionButtons = false,
    isSavingPhoneNumbers = false,
    fromAccountPage = false,
  } = props;

  const hasInvalidNumber = (contactList) => {
    return contactList.some(
      (contact) =>
        contact.phoneNumber.length > 0 && contact.phoneNumber.length !== 10,
    );
  };

  const handleChange = (value, index) => {
    const additionalContactListCopy = [...additionalContactList];
    if (!isNaN(value)) {
      additionalContactListCopy[index].phoneNumber = value;
    }
    setAreAdditionalContactsValid(!hasInvalidNumber(additionalContactListCopy));
    setAdditionalContactList(additionalContactListCopy);
  };

  const handleRemoveContact = (index) => {
    const additionalContactListCopy = [...additionalContactList];
    additionalContactListCopy.splice(index, 1);
    setAreAdditionalContactsValid(!hasInvalidNumber(additionalContactListCopy));
    setAdditionalContactList(additionalContactListCopy);
  };

  const handleAddAdditionalContact = () => {
    const additionalContactListCopy = [...additionalContactList];
    additionalContactListCopy.push({
      phoneNumber: '',
    });
    setAdditionalContactList(additionalContactListCopy);
  };

  return (
    <div className="contactList">
      {fromAccountPage ? (
        <li className="title">
          <span>Additional Contacts</span>
        </li>
      ) : (
        <h5 style={{ marginBottom: 0 }}>Additional Contacts</h5>
      )}
      <p style={{ marginTop: 0, marginBottom: 0 }}>
        Need to share appointment reminders & updates? Add more numbers
        here:
      </p>
      {additionalContactList.map((contact, index) => (
        <div
          key={contact}
          className="row"
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            margin: '0 0 0 0',
          }}
        >
          <InputMask
            className="col s6 m9 l10"
            mask="(999) 999-9999"
            placeholder="Phone Number"
            name={`${contact}.phoneNumber`}
            value={contact?.phoneNumber}
            onChange={(e) =>
              handleChange(e.target.value.replace(/[^0-9]/g, ''), index)
            }
          />

          <Button
            type="button"
            className="col s6 m3 l2 cancelBtn"
            onClick={() => handleRemoveContact(index)}
            style={{
              color: '#9E9E9E',
              margin: '20px 0 0 0',
              background: 'none',
              boxShadow: 'none',
            }}
          >
            <Icon small>close</Icon>
          </Button>
        </div>
      ))}

      <Row
        onClick={() => handleAddAdditionalContact()}
        className="addPhoneNumber"
      >
        <Col s={1}>
          <Icon>add_circle</Icon>
        </Col>
        <Col><span>Add Mobile Phone Number</span></Col>
      </Row>

      {fromAccountPage && showActionButtons && (
        <Row className="btn_actions">
          <Col s={6}>
            <Button
              small
              className="grey btn lighten-3 btn-cancel cancel-or-delete-button"
              onClick={cancelPhoneNumberUpdates}
            >
              Cancel
            </Button>
          </Col>
          <Col s={6}>
            <Button
              small
              onClick={savePhoneNumberList}
              disabled={!areAdditionalContactsValid || isSavingPhoneNumbers}
               className="btn"
            >
              {isSavingPhoneNumbers ? 'Saving...' : 'Save'}
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default AdditionalContacts;
