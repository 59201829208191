import { Button, Modal } from 'react-materialize';
import React from 'react';

const ErrorModal = ({ error, onClose, errorText }) => (
  <Modal
    id={`modal-error-${errorText}`}
    className="error_modal"
    header="Error"
    open={error}
    options={{ onCloseStart: onClose }}
    actions={
      <Button
        className="btn lighten-3 cancel-or-delete-button"
        onClick={onClose}
      >
        Close
      </Button>
    }
  >
    {errorText}
  </Modal>
);

export default ErrorModal;
