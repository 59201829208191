import React from 'react';
import { setPageTitle } from '../helper';

const NotFound = () => {
  setPageTitle('Not Found');
  return (
    <div className="NotFound">
      <div className="image-container">
        <img src="/static/img/not-found-img.png" />
        <h2>Page not found</h2>
      </div>
      
    </div>
  );
};

export default NotFound;
