/* globals fetch */

import React, { useState, useEffect } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { Button, Card, Col, Row } from 'react-materialize';

import Pricing from '../components/Pricing';
import PrettyDate from '../components/PrettyDate';
import { couponIsValid } from '../../costCalculator';
import { loadCoupon } from '../../brain2';
import { findOrCreateVehicleId } from '../../vehicleHelper';
import {
  changeCouponHelper,
  datetimeToTime,
  isCouponAlreadyUsed,
  setPageTitle,
} from '../../helper';
import Checkout from './Checkout/Checkout';
import ReviewPrices from './ReviewPrices';
import ReviewServices from './ReviewServices';
import ReviewYourCar from './ReviewYourCar';
import AdditionalContacts from '../AdditionalContacts';

const Review = (props) => {
  setPageTitle('Review Booking');

  const {
    homeAddress,
    pricing,
    dispatch,
    account,
    vehicles,
    keyosk,
    parking,
    schedule,
    cart,
    scheduledWorks,
    coupon,
    couponList,
    customerLocation,
    clientLocations,
    z3pConfiguration,
  } = props;
  const {
    z3p_client_name: z3pEnv,
    pickup_delivery_service_location: pickupDeliveryServiceLocation,
    scheduling_system: schedulingSystem,
    service_locations: serviceLocations,
    is_skipping_vehicle,
    allow_additional_user_sms_communications: allowAdditionalContacts,
  } = z3pConfiguration;

  const hasWorkplaceService = serviceLocations.includes('workplace');
  const hasShopService = serviceLocations.includes('shop');

  const [processing, setProcessing] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [couponMessage, setCouponMessage] = useState('');
  const [tipAmountDollars, setTipAmountDollars] = useState(0);
  const [checkTermsAlert, setCheckTermsAlert] = useState(false);
  const [additionalContactList, setAdditionalContactList] = useState([]);
  const [areAdditionalContactsValid, setAreAdditionalContactsValid] = useState(
    true,
  );
  const isAtHomeService = !!homeAddress.addressIDSelected;

  useEffect(() => {
    // If the customer doesn't have anything in their cart,
    // direct them back to the Simple Services page.
    if (!cart || cart.length === 0) {
      browserHistory.push('/pricing/simple');
    }

    // If the customer got here without selecting a keyosk/parking and they are not receiving at-home service,
    // direct them back to the location selection page.
    if (
      hasWorkplaceService &&
      (!keyosk || !parking) &&
      !homeAddress.keyLocationNote
    ) {
      browserHistory.push('/pricing/location');
    }

    // User should have a schedule before they hit this page
    if (!schedule) {
      browserHistory.push('/pricing/schedule');
    }

    // Since quotes may not have a vehicle attached, check if there is vehicle info.
    const hasVehicle = !!pricing?.vehicleType;

    // If there's no vehicleId, either find one in their existing vehicles,
    // or save this vehicle to get a new vehicleId.
    if (!is_skipping_vehicle && !pricing?.vehicle_id && hasVehicle) {
      findOrCreateVehicleId(pricing, account.customer_id, dispatch, vehicles);
    }
  }, []);

  const changeCoupon = (e) => {
    const [code, message] = changeCouponHelper(e, scheduledWorks, couponList);
    setCouponCode(code);
    setCouponMessage(message);
  };

  const submitCoupon = (event) => {
    event.preventDefault();
    if (
      couponCode &&
      !isCouponAlreadyUsed(couponCode, scheduledWorks, couponList)
    ) {
      loadCoupon(couponCode.toUpperCase()).then((coupon) => {
        console.log(coupon);
        console.log(cart);
        if (coupon) {
          const isValid = couponIsValid(
            coupon,
            cart,
            isAtHomeService ? null : account.default_client_location_id,
          );

          if (isValid) {
            dispatch({ type: 'SET_COUPON', coupon });
            setCouponCode('');
          } else {
            dispatch({ type: 'SET_COUPON', coupon: null });
            const message =
              'Sorry, that coupon cannot be applied to your order.';
            setCouponMessage(message);
          }
        }
      });
    }
  };

  function editClick(pathEnd) {
    browserHistory.push(`${pathEnd}`);
  }

  let location = null;
  let scheduleDisplay = null;
  let contact = null;
  let vehicleSection = null;

  const isFleetUser = account.is_fleet_user;
  let sectionDivider = (
      <div className="section_divider"></div>
    );
  if (account) {
    contact = (
      <div className="customer-info">
        <div className="header">
          <h5>Customer Info</h5>
          <a href="javascript:;" onClick={() => editClick('/settings')} style={{display:'none'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M7 7.00012H6C5.46957 7.00012 4.96086 7.21084 4.58579 7.58591C4.21071 7.96098 4 8.46969 4 9.00012V18.0001C4 18.5306 4.21071 19.0393 4.58579 19.4143C4.96086 19.7894 5.46957 20.0001 6 20.0001H15C15.5304 20.0001 16.0391 19.7894 16.4142 19.4143C16.7893 19.0393 17 18.5306 17 18.0001V17.0001M16 5.00012L19 8.00012M20.385 6.58511C20.7788 6.19126 21.0001 5.65709 21.0001 5.10011C21.0001 4.54312 20.7788 4.00895 20.385 3.61511C19.9912 3.22126 19.457 3 18.9 3C18.343 3 17.8088 3.22126 17.415 3.61511L9 12.0001V15.0001H12L20.385 6.58511Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </a>
        </div>
        <ul>
          <li>{account.first_name} {account.last_name}</li>
          <li>{account.email}</li>
          <li>{account.phone}</li>
        </ul>
      </div>
    );
  }

  if (isAtHomeService) {
    const { address } = homeAddress;
    location = (
      <div className="d-flex">
        <h6>Service Location</h6>
        <ul>
          {schedule.isPickupAndDelivery && (
            <li style={{ fontSize: '15px' }}>
              Vehicle will be picked up from:
            </li>
          )}
          <li>
            <strong>{address.display_name}</strong>
          </li>
          <li>{address.address_line_1}</li>
          <li>{address.address_line_2}</li>
          <li>
            {address.city}, {address.state} {address.postal_code}
          </li>
        </ul>
        {schedule.isPickupAndDelivery && <p>{pickupDeliveryServiceLocation}</p>}
      </div>
    );
  } else if (keyosk && parking) {
    location = (
      <div className="d-flex">
        <h6>Location</h6>
        <ul>
          <li>Keyosk: {keyosk.name}</li>
          <li>Parking Lot: {parking.name}</li>
        </ul>
      </div>
    );
  } else if (hasShopService) {
    const shopAddress = clientLocations.find(
      (cl) => cl.client_location_id === customerLocation.clientLocationId,
    );
    location = (
      <div className="d-flex">
        <h6>Location</h6>
        <ul>
          <li>{customerLocation.location}</li>
          {shopAddress && shopAddress.address && (
            <li>{shopAddress.address.one_line_human_readable_address}</li>
          )}
        </ul>
      </div>
    );
  }

  if (schedule) {
    scheduleDisplay = (
      <div className="d-flex">
        <h6>Date & Time</h6>
        <ul>
          <li>
            {schedule.date ? (
              <PrettyDate dateString={schedule.date} />
            ) : (
              'Date not yet set - we will reach out!'
            )}
          </li>
          <li>
            {schedulingSystem === 'time_slots'
              ? `${datetimeToTime(schedule.confirmedAppointmentStartTime)}`
              : `${schedule.plannedArrivalTime} - ${schedule.plannedDepartureTime}`}
          </li>
        </ul>
      </div>
    );
  }

  if (vehicles) {
    vehicleSection = (
      <div className="d-flex">
        <h6>Vehicle</h6>
        <ul>
          <li>
            <ReviewYourCar />
          </li>
        </ul>
      </div>
    );
  }

  const couponEntryForm = () => {
    const title = ['zippity', 'uvngo'].includes(z3pEnv)
      ? 'Gift Card or Coupon (Optional)'
      : 'Coupon (Optional)';
    return (
      <form>
        <Row className="d-flex">
          <Col s={8} m={9} l={9}>
            <input
              id="coupon-code-input"
              type="text"
              value={couponCode}
              placeholder="Enter your coupon code"
              onChange={changeCoupon}
            />
          </Col>
          <Col s={4} m={3} l={3}>
            <Button
              className="header coupon_btn"
              onClick={submitCoupon}
              disabled={isCouponAlreadyUsed(
                couponCode,
                scheduledWorks,
                couponList,
              )}
            >
              &nbsp;Apply&nbsp;
            </Button>
          </Col>
         
        </Row>
      </form>
    );
  };

  let existingCouponHtml = <span />;
  if (coupon && coupon.coupon_code) {
    existingCouponHtml = (
      <Card key="couponCard" className="couponCard" title="Coupon Applied!">
        {coupon.coupon_code}
      </Card>
    );
  } else if (couponMessage) {
    existingCouponHtml = (
      <Card key="couponCard" className="invalidCouponCard">
        {couponMessage}
      </Card>
    );
  }

  const appointmentSection = (
    <div className="appointment-info">
        <div className="header">
          <h5>Appointment Details</h5>
          <a href="javascript:;" onClick={() => editClick('/pricing/place')}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M7 7.00012H6C5.46957 7.00012 4.96086 7.21084 4.58579 7.58591C4.21071 7.96098 4 8.46969 4 9.00012V18.0001C4 18.5306 4.21071 19.0393 4.58579 19.4143C4.96086 19.7894 5.46957 20.0001 6 20.0001H15C15.5304 20.0001 16.0391 19.7894 16.4142 19.4143C16.7893 19.0393 17 18.5306 17 18.0001V17.0001M16 5.00012L19 8.00012M20.385 6.58511C20.7788 6.19126 21.0001 5.65709 21.0001 5.10011C21.0001 4.54312 20.7788 4.00895 20.385 3.61511C19.9912 3.22126 19.457 3 18.9 3C18.343 3 17.8088 3.22126 17.415 3.61511L9 12.0001V15.0001H12L20.385 6.58511Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </a>
        </div>
        {location}
        {scheduleDisplay}
        {vehicleSection}
    </div>
  ); 

  const serviceSection = (
    <div className="service-info">
        <div className="header">
          <h5>Services</h5>
          <a href="javascript:;" onClick={() => editClick('/pricing/simple')}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M7 7.00012H6C5.46957 7.00012 4.96086 7.21084 4.58579 7.58591C4.21071 7.96098 4 8.46969 4 9.00012V18.0001C4 18.5306 4.21071 19.0393 4.58579 19.4143C4.96086 19.7894 5.46957 20.0001 6 20.0001H15C15.5304 20.0001 16.0391 19.7894 16.4142 19.4143C16.7893 19.0393 17 18.5306 17 18.0001V17.0001M16 5.00012L19 8.00012M20.385 6.58511C20.7788 6.19126 21.0001 5.65709 21.0001 5.10011C21.0001 4.54312 20.7788 4.00895 20.385 3.61511C19.9912 3.22126 19.457 3 18.9 3C18.343 3 17.8088 3.22126 17.415 3.61511L9 12.0001V15.0001H12L20.385 6.58511Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </a>
        </div>
        <ReviewServices
          tipAmountDollars={tipAmountDollars}
          setTipAmountDollars={setTipAmountDollars}
        />
        
    </div>
  ); 

  return (
    <Pricing currentStep="Schedule" h1="Appointment Summary" hideYourCar backText="Back To Services">
      <div className="review">
        <Row>
          <Col m={12} className="review-column-left">
            {contact}
            {sectionDivider}
            {appointmentSection}
            {sectionDivider}
            {serviceSection}
            {couponEntryForm()}
            {existingCouponHtml}
            <ReviewPrices tipAmountDollars={tipAmountDollars} sectionDivider={sectionDivider} />
            
            <Checkout
              isFleetUser={isFleetUser}
              processing={processing}
              setProcessing={setProcessing}
              couponCode={couponCode}
              isAtHomeService={isAtHomeService}
              isWorkplaceService={
                serviceLocations.includes('workplace') && !isAtHomeService
              }
              setCheckTermsAlert={setCheckTermsAlert}
              areAdditionalContactsValid={areAdditionalContactsValid}
              allowAdditionalContacts={allowAdditionalContacts}
              additionalContactList={additionalContactList}
              tipAmountDollars={tipAmountDollars}
              setTipAmountDollars={setTipAmountDollars}
            />

            {checkTermsAlert && (
              <span>
                Please check the box above to agree to the Terms and Conditions.
              </span>
            )}
          </Col>

          {/*<Col m={12} className="review-column-right">
            {scheduleDisplay}
            {contact}
            {allowAdditionalContacts && (
              <AdditionalContacts
                additionalContactList={additionalContactList}
                setAdditionalContactList={setAdditionalContactList}
                areAdditionalContactsValid={areAdditionalContactsValid}
                setAreAdditionalContactsValid={setAreAdditionalContactsValid}
              />
            )}
            {location}

            {couponEntryForm()}

            {existingCouponHtml}
          </Col>*/}
        </Row>
      </div>
    </Pricing>
  );
};

function mapStateToProps(state) {
  return {
    pricing: state.ui.pricing,
    cart: state.ui.cart,
    schedule: state.ui.schedule,
    account: state.ui.account,
    keyosk: state.ui.keyosk,
    parking: state.ui.parking,
    homeAddress: state.ui.homeAddress,
    scheduledWorks: state.ui.scheduledWorks,
    coupon: state.ui.coupon,
    couponList: state.ui.couponList,
    vehicles: state.ui.vehicles,
    customerLocation: state.ui.customerLocation,
    clientLocations: state.ui.clientLocations,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(Review);
