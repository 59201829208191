/* globals window */
import React, { Component, Fragment } from 'react';
import { Col, Row } from 'react-materialize';

import { connect } from 'react-redux';
import Zippity from '../components/Zippity';
import GoogleMap from './GoogleMap';
import { apiGet, apiPut } from '../../brainApi';
import { setPageTitle } from '../../helper';

export class Location {
  static Create(latitude, longitude) {
    if (!latitude || !longitude) {
      return null;
    }
    const loc = new Location();
    loc.latitude = latitude;
    loc.longitude = longitude;
    return loc;
  }

  toString() {
    return `(${this.latitude}, ${this.longitude})`;
  }
}

class CheckinGeolocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: null,
      loading: true,
      markerLocation: null,
      width: window.innerWidth,
      markerMoved: false,
      showMoveMarkerMessage: false,
    };

    this.completeCheckin = this.completeCheckin.bind(this);
    this.onMarkerDrag = this.onMarkerDrag.bind(this);
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    setPageTitle('Checkin');
  }

  async componentDidMount() {
    const { params } = this.props;
    this.requestUserDetails(params.customerId, params.swId);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  // https://github.com/fullstackreact/google-maps-react/issues/39#issuecomment-407369290
  onMarkerDrag(m1, m2, m3) {
    console.log('onMarkerDrag');
    const { latLng: newPosition } = m3;
    const lat = newPosition.lat();
    const lng = newPosition.lng();

    this.setState({
      markerMoved: true,
      showMoveMarkerMessage: false,
      markerLocation: { latitude: lat, longitude: lng },
    });
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  async requestUserDetails(customerId, swId) {
    // Get the details on this SW
    apiGet(`/scheduled-work/${swId}/${customerId}`)
      .then((response) => response.json())
      .then((json) => {
        const status = json.status ? json.status.toUpperCase() : '';
        if (status === 'FINISHED') {
          window.location = 'https://zippitycars.com/checked-in';
          return;
        }

        const isValid = json.customer.customer_id.toString() === customerId;

        if (!isValid) {
          window.location = '/login';
          return;
        }

        const { client_location: cl, keyosk, parking, box } = json;

        // Default location preference order:
        // 1. Van Location
        // 2. Parking location
        // 3. Trailer location
        // TODO: This order may change after we set up real "parking_type" and use "demand-based" flag
        let defaultLocation;
        if (cl.van_parking_location_lat && cl.van_parking_location_lng) {
          defaultLocation = Location.Create(
            cl.van_parking_location_lat,
            cl.van_parking_location_lng,
          );
        } else if (parking.lat && parking.lng) {
          defaultLocation = Location.Create(parking.lat, parking.lng);
        } else if (cl.trailer_lat && cl.trailer_lng) {
          defaultLocation = Location.Create(cl.trailer_lat, cl.trailer_lng);
        }

        const keyoskLocation = Location.Create(keyosk.lat, keyosk.lng);

        // TODO: Better way to decide on keyosk type
        const isOutdoorKeyosk = json.service_vehicle_id === 'DFW008M';

        this.setState({
          firstName: json.customer.first_name,
          swId: json.id,
          defaultLocation,
          keyoskLocation,
          boxNumber: box,
          isOutdoorKeyosk,
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        window.location = '/login';
      });
  }

  async completeCheckin() {
    const { markerLocation, swId, markerMoved } = this.state;

    if (markerMoved) {
      this.setState({ processingCheckin: true });
      apiPut(`/checkin/scheduled-work/${swId}`, {
        keys_available: true,
        checkin_type: 'web',
        markerLocation,
      }).then(() => {
        window.location = 'https://zippitycars.com/checked-in';
      });
    } else {
      this.setState({ showMoveMarkerMessage: true });
    }
  }

  render() {
    const {
      firstName,
      markerLocation,
      defaultLocation,
      keyoskLocation,
      loading,
      processingCheckin,
      boxNumber,
      isOutdoorKeyosk,
      width,
      markerMoved,
      showMoveMarkerMessage,
    } = this.state;
    const { google, z3pConfiguration } = this.props;

    const {
      customer_facing_name: serviceProviderName,
      customer_facing_phone: serviceProviderPhone,
    } = z3pConfiguration;

    const greeting = firstName ? `Hi, ${firstName}!` : '';

    const keyoskBoxImageUrl = `https://zippity.imgix.net/box-images/${
      isOutdoorKeyosk ? 'Outdoor' : 'Indoor'
    }_Box${boxNumber}.png?auto=format,compress`;

    const keyoskBoxImage = boxNumber >= 1 && boxNumber <= 12 && (
      <img
        className="keyoskBoxImage"
        src={keyoskBoxImageUrl}
        alt={`Drop your keys in Box ${boxNumber}`}
      />
    );

    const checkinButton = processingCheckin ? (
      <button type="button" className="disabled btn-large">
        <strong>Checking In...</strong>
      </button>
    ) : (
      <Fragment>
        <button
          type="button"
          className={`button-link waves-effect waves-light btn-large${
            markerMoved ? '' : ' disabled-button-link'
          }`}
          onClick={this.completeCheckin}
        >
          <strong>Check In</strong>
        </button>
        {showMoveMarkerMessage && (
          <p style={{ color: 'red' }}>
            Please drag the pin on the map to indicate where your car is parked
          </p>
        )}
      </Fragment>
    );

    const geoLocationInstructions =
      'Drag the "My Car" marker to your car\'s parking spot.';

    const map = defaultLocation ? (
      <GoogleMap
        google={google}
        onMarkerDrag={this.onMarkerDrag}
        defaultLocation={defaultLocation}
        markerLocation={markerLocation}
        keyoskLocation={keyoskLocation}
      />
    ) : (
      <p>Loading...</p>
    );

    if (loading) {
      return (
        <Zippity active="checkin">
          <Row>
            <Col s={12} className="center">
              <h2>Loading...</h2>
            </Col>
          </Row>
        </Zippity>
      );
    }

    const isMobile = width <= 600;

    if (isMobile) {
      return (
        <Zippity active="checkin">
          <Col s={12} className="neue center">
            <h3>{greeting}</h3>

            <p>
              It&apos;s time to check in for your {serviceProviderName} service!
            </p>

            <p>1. {geoLocationInstructions}</p>

            <div style={{ position: 'relative', height: '300px' }}>{map}</div>

            <p>
              2. Once you&apos;ve parked, drop your keys in box {boxNumber}.
            </p>

            {keyoskBoxImage}

            <p>3. Then click here to let us know you&apos;re all set!</p>

            {checkinButton}

            <p>
              If you have any questions about your service day, please text us
              at {serviceProviderPhone}.
            </p>
          </Col>
        </Zippity>
      );
    }

    return (
      <Zippity active="checkin">
        <Row className="checkinTwoColumns">
          <Col s={12} m={6} l={4} className="neue center leftCol">
            <h3>{greeting}</h3>

            <p>
              It&apos;s time to check in for your {serviceProviderName} service!
            </p>
            <p>1. {geoLocationInstructions}</p>

            <p>
              2. Once you&apos;ve parked, drop your keys in box {boxNumber}.
            </p>

            {keyoskBoxImage}

            <p>3. Then click here to let us know you&apos;re all set!</p>

            {checkinButton}

            <p>
              If you have any questions about your service day, please text us
              at {serviceProviderPhone}.
            </p>
          </Col>
          <Col s={12} m={6} l={8} className="rightCol">
            {map}
          </Col>
        </Row>
      </Zippity>
    );
  }
}

function mapStateToProps(state) {
  return {
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(CheckinGeolocation);
