import React, { useState } from 'react';
import { Button, Card, Col, Dropdown, Icon, Row } from 'react-materialize';
import SetAddressForm from './SetAddressForm';
import { apiPut } from '../../brainApi';
import ConfirmActionModal from './ConfirmActionModal';
import LocationDropdown from './LocationDropdown';
import { workplaceQuestionReason } from '../../placeHelper';
import ShopLocationDropdown from './ShopLocationDropdown';

const AddressMenu = ({
  id,
  onClickEdit,
  refreshAddresses,
  onClickMakePrimary,
  address,
  workplace,
  deleteWorkplace,
  hasShopService,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const onClickDelete = () => {
    setModalOpen(true);
  };

  const deleteAddress = () => {
    if (workplace) {
      setModalOpen(false);
      deleteWorkplace();
    } else {
      apiPut(`/customer/address/${address.customer_address_id}`, {
        is_deleted: true,
      })
        .then((res) => res.json())
        .then(() => {
          setModalOpen(false);
          refreshAddresses();
        });
    }
  };

  let modalTextSuffix = workplace;
  if (!workplace) {
    modalTextSuffix =
      address && address.display_name
        ? `"${address && address.display_name}"`
        : (address && address.address_line_1) || 'this address';
  }

  let modalConfirmText = `Delete ${workplace ? 'My Workplace' : 'Address'}`;
  if (hasShopService) modalConfirmText = 'Delete My Shop';

  return (
    <>
      <Dropdown
        id={`Dropdown_${id}`}
        trigger={
          <span className="cardMenu">
            <Icon>more_vert</Icon>
          </span>
        }
      >
        <button
          type="button"
          className="dropdownMenuItem"
          onClick={onClickEdit}
        >
          <Icon>edit</Icon> Edit
        </button>
        <button
          type="button"
          className="dropdownMenuItem"
          onClick={onClickDelete}
        >
          <Icon>delete</Icon> Delete
        </button>
      </Dropdown>
      {address && !address.is_primary && (
        <button
          type="button"
          className="button-to-link"
          onClick={onClickMakePrimary}
        >
          Make Primary
        </button>
      )}
      <ConfirmActionModal
        open={modalOpen}
        mainText={`Are you sure you want to delete ${modalTextSuffix}?`}
        confirmText={modalConfirmText}
        onConfirm={deleteAddress}
        onCancel={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};

const EditableAddressCard = ({
  address,
  refreshAddresses,
  setEditing,
  onCancel,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onSetAddress = (newAddress) => {
    apiPut(`/customer/address/${address.customer_address_id}`, {
      ...newAddress,
    })
      .then((res) => res.json())
      .then(() => {
        refreshAddresses();
        setEditing(false);
        setIsLoading(false);
      });
  };

  return (
    <Col l={12} m={12} s={12}>
      <Card key={address.customer_address_id} className={`swCard ${address.is_primary ? 'is_primary' : ''}`}>
        <ul className="neue">
          {address.is_primary && (
            <ul className="neue teal-header">
              <span>PRIMARY</span>
            </ul>
          )}
        </ul>
        <SetAddressForm
          onSetAddress={onSetAddress}
          onCancel={() => {
            setEditing(false);
            onCancel();
          }}
          propAddress={address}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          hideServiceabilitySection
        />
      </Card>
    </Col>
  );
};

const EditableWorkplaceCard = ({
  isNewWorkplace,
  setWorkplaceAsync,
  setEditing,
  onCancel,
  hasShopService,
  onSelect = () => {},
}) => {
  const [selectedLocationAlias, setSelectedLocationAlias] = useState('');
  const [clientLocationId, setClientLocationId] = useState('');

  const onSetWorkplace = (id = clientLocationId) => {
    setWorkplaceAsync(id).then(() => {
      setEditing(false);
      onSelect();
    });
  };

  const SaveButton = () => (
    <Button
      className="btn-teal"
      onClick={() => onSetWorkplace()}
      disabled={!clientLocationId}
    >
      Save
    </Button>
  );

  const CancelButton = () => (
    <Button
      className="btn-gray"
      onClick={() => {
        onCancel();
        setEditing(false);
      }}
    >
      Cancel
    </Button>
  );

  const handleClientChange = (event) => {
    const clID = event.target[event.target.selectedIndex].getAttribute(
      'data-client-location-id',
    );
    setSelectedLocationAlias(event.target.value);
    setClientLocationId(Number(clID));
  };

  const locationDropdown = () => {
    if (hasShopService) {
      return (
        <Row>
          <ShopLocationDropdown
            handleClientChange={handleClientChange}
            selectedClientLocation={selectedLocationAlias}
            fullWidth
          />
        </Row>
      );
    }
    return (
      <>
        {isNewWorkplace ? workplaceQuestionReason() : null}
        <Row>
          <LocationDropdown
            handleClientChange={handleClientChange}
            selectedClientLocation={selectedLocationAlias}
          />
        </Row>
      </>
    );
  };

  return (
    <Col l={8} m={8} s={12}>
      <Card
        key="address.customer_address_id"
        className="swCard"
        title={hasShopService ? 'Shop' : 'Work'}
      >
        {locationDropdown()}
        <Row>
          <Col>
            <SaveButton />
          </Col>
          <Col>
            <CancelButton />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

const Location = ({ workplace, address }) => {
  if (workplace) return <p>{workplace}</p>;
  return (
    <div>
      {address && address.address_line_1 && (
        <p>{address && address.address_line_1}</p>
      )}
      {address && address.address_line_2 && (
        <p>{address && address.address_line_2}</p>
      )}
      {
        <p>
          {address && address.city}, {address && address.state}{' '}
          {address && address.postal_code}
        </p>
      }
    </div>
  );
};

const AddressCard = ({
  id,
  address,
  refreshAddresses,
  workplace,
  setWorkplaceAsync,
  deleteWorkplace,
  onMakePrimary,
  onCancel = () => {},
  editable = false,
  isNewWorkplace = false,
  selectable = false,
  onSelect,
  hasShopService,
}) => {
  const [editing, setEditing] = useState(isNewWorkplace);
  let displayName = address && address.display_name;
  if (workplace) displayName = 'Work';
  if (hasShopService) displayName = 'Shop';

  if (editable && editing) {
    if (address)
      return (
        <EditableAddressCard
          address={address}
          refreshAddresses={refreshAddresses}
          setEditing={setEditing}
          onCancel={onCancel}
        />
      );

    return (
      <EditableWorkplaceCard
        isNewWorkplace={isNewWorkplace}
        setWorkplaceAsync={setWorkplaceAsync}
        setEditing={setEditing}
        onCancel={onCancel}
        hasShopService={hasShopService}
        onSelect={onSelect}
      />
    );
  }

  return (
    <Col l={4} m={6} s={12}>
      <Card
        key={id}
        className={`swCard ${selectable ? 'waves-effect waves-light' : null} ${address && address.is_primary ? 'is_primary' : ''}`}
        style={{ width: '100%' }}
        title={displayName}
        onClick={selectable ? onSelect : null}
      >
        <ul className="neue">
          {address && address.is_primary && (
            <ul className="neue teal-header">
              <span>PRIMARY</span>
            </ul>
          )}
        </ul>

        <Location workplace={workplace} address={address} />

        {editable && (address || workplace) && (
          <AddressMenu
            id={id}
            address={address}
            onClickEdit={() => setEditing(true)}
            workplace={workplace}
            deleteWorkplace={deleteWorkplace}
            refreshAddresses={refreshAddresses}
            onClickMakePrimary={() => {
              onMakePrimary(id);
            }}
            hasShopService={hasShopService}
          />
        )}
      </Card>
    </Col>
  );
};

export default AddressCard;
