import React from 'react';
import { connect } from 'react-redux';

const ReviewYourCar = ({ pricing }) => {
  if (!pricing) {
    return null;
  }

  return <span>{pricing.display_name}</span>;
};

function mapStateToProps(state) {
  return {
    pricing: state.ui.pricing,
  };
}

export default connect(mapStateToProps, null)(ReviewYourCar);
