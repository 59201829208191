export function getServiceByName(serviceList, name) {
  if (!serviceList) {
    return null;
  }
  const filtered = serviceList.filter((s) => 'short_name' in s);
  return filtered.find((s) => s.short_name.includes(name));
}

export function getServiceById(serviceList, serviceOfferingId) {
  if (!serviceList) {
    return null;
  }
  const filtered = serviceList.filter((s) => 'service_offering_id' in s);
  return filtered.find((s) => s.service_offering_id === serviceOfferingId);
}

export function getTireInstallationFee(serviceList) {
  return serviceList?.find((s) => !!s?.is_tire_installation_fee);
}

export function getATDTireProduct(serviceList) {
  return serviceList?.find((s) => !!s?.is_atd_tire_product);
}

export function getServicesByScheduleTypes(serviceList, scheduleTypes) {
  if (!serviceList) {
    return [];
  }
  const filtered = serviceList.filter((s) => 'schedule_types' in s);
  return filtered.filter((s) =>
    s.schedule_types.some((st) => {
      return scheduleTypes.includes(st);
    }),
  );
}

export function hasServiceWithScheduleTypes(serviceList, scheduleTypes) {
  const matching = getServicesByScheduleTypes(serviceList, scheduleTypes);
  return matching && matching.length;
}

export function getServicesByScheduleType(serviceList, scheduleType) {
  if (!serviceList) {
    return [];
  }
  const filtered = serviceList.filter((s) => 'schedule_types' in s);
  return filtered.filter((s) => s.schedule_types.includes(scheduleType));
}

export function hasServiceWithScheduleType(serviceList, scheduleType) {
  const matching = getServicesByScheduleType(serviceList, scheduleType);
  return matching && matching.length;
}

export function addDeepLinkedServices(
  dispatch,
  autoSelectServiceOfferingIds,
  priceList,
) {
  if (
    autoSelectServiceOfferingIds &&
    autoSelectServiceOfferingIds.length > 0 &&
    priceList &&
    priceList.length > 0
  ) {
    const cart = priceList.filter(
      (s) =>
        s.included_with_every_mechanical_service ||
        autoSelectServiceOfferingIds.includes(s.service_offering_id),
    );

    dispatch({ type: 'SET_CART', cart });

    // Only autoselect once
    dispatch({
      type: 'SET_AUTOSELECT_SERVICE_OFFERING_IDS',
      autoSelectServiceOfferingIds: [],
    });
  }
}
