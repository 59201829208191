import React from 'react';
import classnames from 'classnames';
import moment from 'moment';

const Day = ({
  timeslots = {},
  selectedTimeslot,
  day,
  onSelectTimeslot,
  weekDays,
  tz,
}) => {
  const combineDayAndTime = (day, slot) => {
    const [hours, minutes] = slot.split(':');
    return day
      .clone()
      .tz(tz, true)
      .add(hours, 'hours')
      .add(minutes, 'minutes');
  };

  const renderTimeslot = (time, index) => {
    const description = moment(time, 'HH:mm').format('h:mm a');
    const timeslotMoment = combineDayAndTime(day, time);
    const isSelected = timeslotMoment.isSame(selectedTimeslot, 'minute');
    const scheduleInstance = timeslots[time];
    const isDisabled = !scheduleInstance;

    const timeslotClassNames = classnames({
      tsc__timeslot: true,
      tsc__timeslot__height: true,
      'tsc__timeslot--selected': isSelected,
      'tsc__timeslot--disabled': isDisabled,
    });

    return (
      <div
        key={index}
        className={timeslotClassNames}
        onClick={() => {
          if (!isDisabled && onSelectTimeslot) {
            onSelectTimeslot(timeslotMoment, scheduleInstance);
          }
        }}
      >
        {description}
      </div>
    );
  };

  const renderPlaceHolderTimeslot = () => {
    const placeholderClassNames = classnames({
      tsc__timeslot: true,
      tsc__timeslot__height: true,
      'tsc__timeslot--disabled': true,
    });

    return <div className={placeholderClassNames}>Day Not Free</div>;
  };

  return (
    <div
      className={`tsc__day ${
        weekDays === 7 ? 'tsc__day-responsive-weekend' : 'tsc__day-responsive'
      }`}
    >
      <div className="tsc__day-title">
        <p>{day.format('ddd')}</p>
        <p>{day.format('M/D')}</p>
      </div>
      {Object.keys(timeslots).length > 0
        ? Object.keys(timeslots).map((time, index) =>
            renderTimeslot(time, index),
          )
        : renderPlaceHolderTimeslot()}
    </div>
  );
};

export default Day;
