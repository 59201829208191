import React, { useEffect } from 'react';
import { connect } from 'react-redux';

  const ReviewPage = (props) => {
    const {
      z3pConfiguration
    } = props;
    const {
      google_review_url
    } = z3pConfiguration;
    
    useEffect(() => {
        window.location.href = google_review_url;
    }, []); 

    return null;
  }
  function mapStateToProps(state) {
    return {
      account: state.ui.account,
      vehicles: state.ui.vehicles,
      vehicle: state.ui.pricing,
      cart: state.ui.cart,
      clientLocations: state.ui.clientLocations,
      z3pConfiguration: state.ui.z3pConfiguration,
      homeAddress: state.ui.homeAddress,
    };
  }
  
  export default connect(mapStateToProps, null)(ReviewPage);