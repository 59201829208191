import React, { Component } from 'react';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';

const innerStyle = {
  position: 'relative',
  width: '100%',
  height: '100%',
};

class GoogleMap extends Component {
  // Translate a location into the right format for Google
  translate = (location) => {
    if (!location) {
      return null;
    }
    const { latitude, longitude } = location;
    return { lat: latitude, lng: longitude };
  };

  createMapView(google, defaultLocation, markerLocation, keyoskLocation) {
    const { onMarkerDrag, defaultIcon, style, mapType } = this.props;
    return (
      <Map
        google={google}
        style={{ ...innerStyle, ...style }}
        initialCenter={defaultLocation}
        zoom={17}
        maxZoom={20}
        minZoom={13}
        tilt={0}
        draggable
        fullscreenControl={false}
        mapTypeControl={false}
        streetViewControl={false}
        rotateControl /* enables Tilt & Rotate */
        disableDoubleClickZoom
        scrollwheel={false}
        mapType={mapType || 'hybrid'}
        zoomControl
      >
        {keyoskLocation && (
          <Marker
            position={keyoskLocation}
            name="Key Drop"
            icon={{
              url: 'https://zippity.imgix.net/maps/new/key-aqua-3.png',
              anchor: new google.maps.Point(20, 60),
              scaledSize: new google.maps.Size(41, 60),
            }}
          />
        )}
        <Marker
          onDragend={onMarkerDrag}
          position={markerLocation || defaultLocation}
          name="Current location"
          draggable
          icon={
            !defaultIcon
              ? {
                  url: 'https://zippity.imgix.net/maps/new/park-aqua-4.png',
                  anchor: new google.maps.Point(20, 60),
                  scaledSize: new google.maps.Size(41, 60),
                }
              : undefined
          }
        />
      </Map>
    );
  }

  render() {
    const {
      defaultLocation,
      markerLocation,
      keyoskLocation,
      google,
    } = this.props;

    return this.createMapView(
      google,
      this.translate(defaultLocation),
      this.translate(markerLocation),
      this.translate(keyoskLocation),
    );
  }
}

// Env vars must have prefix REACT_APP
// See: https://medium.com/@tacomanator/environments-with-create-react-app-7b645312c09d
export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
})(GoogleMap);
