import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Modal, Row } from 'react-materialize';

import TireImage from './TireImage';

class TireRecommendation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfTires: 4,
      isShowingDetail: false,
      maxQuantity: 4,
    };
    this.changeNumberOfTires = this.changeNumberOfTires.bind(this);
    this.setIsShowingDetail = this.setIsShowingDetail.bind(this);
  }

  componentDidMount() {
    const { product } = this.props;
    const { availability } = product;
    const quantityAvailable =
      Number(availability.local) + Number(availability.localPlus);
    // Show a max of 4 in the dropdown
    if (quantityAvailable > 4) {
      this.setState({ maxQuantity: 4 });
    } else {
      // If the quantity available is 3, only allow to book 2 (3 is a rare/odd number of tires to purchase)
      if (quantityAvailable === 3) {
        // Select the maximum quantity by default
        this.setState({ maxQuantity: 2, numberOfTires: 2 });
      }
      // Select the maximum quantity by default
      this.setState({
        maxQuantity: quantityAvailable,
        numberOfTires: quantityAvailable,
      });
    }
  }

  transformProductSpecs = (productSpecs) => {
    const transformed = {};

    // This gives us the flexibility to change the key names, or remove certain keys
    const keyTranslations = {
      MileageWarranty: 'Mileage Warranty',
      TreadDepth: 'Tread Depth',
      SeasonalDesignation: 'Seasonal Designation',
      Size: 'Size',
      TemperatureRating: 'Temperature Rating',
      UPC: 'UPC',
      TreadWear: 'Tread Wear',
      WinterDesignation: 'Winter Designation',
      RPM: 'RPM',
      MaxRimWidth: 'Max Rim Width',
      RunFlat: 'Run Flat',
      LoadRange: 'Load Range',
      TractionRating: 'Traction Rating',
      RimDiameter: 'Rim Diameter',
      Sidewall: 'Sidewall',
      ProductLength: 'Product Length',
      LoadIndex: 'Load Index',
      SpeedRating: 'Speed Rating',
      OverallTireDiameter: 'Overall Tire Diameter',
      ProductHeight: 'Product Height',
      RadialBiasFlag: 'Radial Bias Flag',
      SingleMaxLoadLBS: 'Single Max Load LBS',
      AspectRatio: 'Aspect Ratio',
      TireWidth: 'Tire Width',
      MaxTirePressure: 'Max Tire Pressure',
      TirePly: 'Tire Ply',
      MinRimWidth: 'Min Rim Width',
      ProductWidth: 'Product Width',
      ShippingWeight: 'Shipping Weight',
      MAP: 'MAP',
    };
    const whitelistedKeys = Object.keys(keyTranslations);

    Object.keys(productSpecs)
      .filter((k) => whitelistedKeys.includes(k))
      .forEach((key) => {
        transformed[keyTranslations[key]] = productSpecs[key];
      });
    return transformed;
  };

  changeNumberOfTires(event) {
    this.setState({ numberOfTires: event.target.value });
  }

  setIsShowingDetail(bool) {
    if (bool) {
      this.setState({ isShowingDetail: false }, () =>
        this.setState({ isShowingDetail: true }),
      );
    } else {
      this.setState({ isShowingDetail: false });
    }
  }

  render() {
    const { numberOfTires, isShowingDetail, maxQuantity } = this.state;
    const { product, pricing, addToCart, sortSelection } = this.props;
    const { make } = pricing;

    const {
      ATDProductNumber,
      style,
      brand,
      price,
      images,
      productSpec,
      OEMakes,
    } = product;

    const { cost, retail } = price;
    const retailPrice = retail || cost; // Ideally, every product should have price.retail

    // const { local, localPlus, nationWide, onHand } = availability;
    const { large } = images;

    const isOERecommendation = OEMakes.includes(make);

    const transformedProductSpec = this.transformProductSpecs(productSpec);
    const productSpecKeys = Object.keys(transformedProductSpec);
    const half = Math.floor(productSpecKeys.length / 2);
    const productSpecKeys1 = productSpecKeys.slice(0, half);
    const productSpecKeys2 = productSpecKeys.slice(
      half,
      productSpecKeys.length,
    );

    // Create an array for the quantity dropdown using the max quantity as the upper bound
    const quantityDropdownList = Array.from(
      { length: maxQuantity },
      (_, i) => i + 1,
    );

    const seasonalIcons = {
      Summer: '/static/img/tires/seasonal-summer.png',
      Winter: '/static/img/tires/seasonal-winter.png',
      'Winter Studdable': '/static/img/tires/seasonal-winter.png',
      'Winter Studless': '/static/img/tires/seasonal-winter.png',
      '3 Peak': '/static/img/tires/seasonal-3peak.png',
      'All Season': '/static/img/tires/seasonal-all.png',
      'Fuel Saver': '/static/img/tires/seasonal-fuel.png',
      RunFlat: '/static/img/tires/seasonal-runflat.png',
    };

    let seasonalBadgeIcon = false;
    if (
      productSpec.SeasonalDesignation &&
      seasonalIcons[productSpec.SeasonalDesignation]
    ) {
      const imgSrc = seasonalIcons[productSpec.SeasonalDesignation];
      seasonalBadgeIcon = (
        <img
          src={imgSrc}
          style={{ height: '20px', marginBottom: '-5px' }}
          alt="Seasonal"
        />
      );
    }

    return (
      <div className="tireRecommendation">
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => this.setIsShowingDetail(true)}
        >
          <div className="brand">{brand}</div>
          <div className="style">{style} </div>
          <TireImage largeImages={large} />
        </span>
        <div className="badges">
          {!!productSpec.SeasonalDesignation && (
            <span>
              {seasonalBadgeIcon || `${productSpec.SeasonalDesignation} `}
              <span className="tooltiptext">
                This tire&apos;s <strong>Seasonal Designation</strong> is{' '}
                {productSpec.SeasonalDesignation}.
                {productSpec.SeasonalDesignation === '3 Peak' &&
                  ' This type of winter tire meets performance criteria to be considered severe snow service-rated.'}
              </span>
            </span>
          )}
          {!!productSpec.MileageWarranty &&
            productSpec.MileageWarranty !== '0' && (
              <span
                className={sortSelection === 'warrantyDesc' ? 'sortBadge' : ''}
              >
                {productSpec.MileageWarranty}
                <span className="tooltiptext">
                  <strong>
                    {productSpec.MileageWarranty} Mileage Warranty
                  </strong>{' '}
                  is the guarantee by the tire manufacturer about how long your
                  tires will last.{' '}
                  <a
                    href="https://zippitycars.com/purchase-tires/#mileage-warranty"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    More Info
                  </a>
                </span>
              </span>
            )}
          {!!productSpec.TemperatureRating && (
            <span>
              {productSpec.TemperatureRating}
              <span className="tooltiptext">
                <strong>Temperature Rating</strong> is the tire’s ability to
                resist and disperse heat.{' '}
                {productSpec.TemperatureRating === 'A' &&
                  'The top rating is A, which means it is very resistant to heat. '}
                <a
                  href="https://zippitycars.com/purchase-tires/#temperature-rating"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  More Info
                </a>
              </span>
            </span>
          )}
          {!!productSpec.TractionRating && (
            <span
              className={sortSelection === 'tractionDesc' ? 'sortBadge' : ''}
            >
              {productSpec.TractionRating}
              <span className="tooltiptext">
                <strong>Traction Rating</strong> indicates how well the tire
                stops.{' '}
                {productSpec.TractionRating === 'AA' &&
                  'AA is the highest grade - only 3% of tires are rated AA. '}
                <a
                  href="https://zippitycars.com/purchase-tires/#traction-rating"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  More Info
                </a>
              </span>
            </span>
          )}
          {!!productSpec.TreadWear && (
            <span className={sortSelection === 'treadDesc' ? 'sortBadge' : ''}>
              {productSpec.TreadWear}
              <span className="tooltiptext">
                <strong>Tread Wear</strong> is the measure of how long your
                tires are expected to last (under perfect conditions).{' '}
                <a
                  href="https://zippitycars.com/purchase-tires/#treadwear-rating"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  More Info
                </a>
              </span>
            </span>
          )}
          {isOERecommendation && (
            <span className="oeSpec">
              OE Spec
              <span className="tooltiptext">
                <strong>OE (Original Equipment)</strong> tires match the
                specification of the tires that originally came with your
                vehicle from the factory.{' '}
                <a
                  href="https://zippitycars.com/purchase-tires/#oe-tires"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  More Info
                </a>
              </span>
            </span>
          )}
        </div>
        <div>
          <span className="retailPrice">{`$${retailPrice} `}</span>per tire
        </div>
        <div>{`$${(retailPrice * numberOfTires).toFixed(
          2,
        )} for ${numberOfTires} tires`}</div>
        <div className="actionButtons">
          <p
            className="more-info"
            onClick={() => this.setIsShowingDetail(true)}
          >
            See details
          </p>
          <Modal
            header={`${brand} ${style}`}
            className="tire-modal"
            open={isShowingDetail}
            actions={
              <Button onClick={() => this.setIsShowingDetail(false)}>
                Close
              </Button>
            }
          >
            <Row>
              <Col s={6}>
                <ul>
                  <li>
                    <strong>Product Number</strong>: {ATDProductNumber}
                  </li>
                  {productSpecKeys1.map((key) => (
                    <li key={key}>
                      <strong>{key}</strong>: {transformedProductSpec[key]}
                    </li>
                  ))}
                </ul>
              </Col>
              <Col s={6}>
                <ul>
                  {productSpecKeys2.map((key) => (
                    <li key={key}>
                      <strong>{key}</strong>: {transformedProductSpec[key]}
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Modal>
          <select
            name="numTires"
            value={numberOfTires}
            className="browser-default"
            style={{
              height: '40px',
              width: '30%',
              float: 'left',
              borderRadius: '5px',
            }}
            onChange={this.changeNumberOfTires}
          >
            {quantityDropdownList.map((q) => (
              <option value={`${q}`}>{q}</option>
            ))}
          </select>
          <button
            type="button"
            className="waves-effect btn-tire"
            style={{ height: '40px', width: '70%' }}
            onClick={() => addToCart(numberOfTires, product)}
          >
            Add To Cart
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.ui.account,
    priceList: state.ui.priceList,
    cart: state.ui.cart,
    tireDetails: state.ui.tireDetails,
    pricing: state.ui.pricing,
  };
}

export default connect(mapStateToProps, null)(TireRecommendation);
