import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import Raven from 'raven-js';

import { connect } from 'react-redux';
import { Icon, Modal } from 'react-materialize';
import { requestJson } from '@fullcalendar/react';
import Zippity from '../components/Zippity';
import { apiGet, hasAccessToken } from '../../brainApi';
import {
  getEmailSupportLink,
  getPhoneSupportLink,
  setPageTitle,
  formatPrice,
} from '../../helper';
import QuoteOption from './QuoteOption';
import Loading from '../components/Loading';
import Pricing from '../components/Pricing';
import { addMessage } from '../../zippity';
import { getVehicleType } from '../../vehicleHelper';

class Quote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      quote: {},
      selectedOptionIndices: [],
      helpModalOpen: false,
    };
  }

  componentWillMount() {
    setPageTitle('Your Quote');
  }

  componentDidMount() {
    const { params, dispatch } = this.props;
    const { quote_uuid } = params;
    // Get the details on this quote
    apiGet(`/quote-from-uuid/${quote_uuid}`)
      .then((response) => response.json())
      .then((quote) => {
        if (quote.Status === 'Failure') {
          addMessage(dispatch, 'Sorry, that link is invalid.', 10, {
            messageType: 'error',
          });
          window.location = '/';
        }

        const {
          quote_request: { address, vehicle },
          sections,
          coupon_code,
          custom_discount_amount_dollars,
          custom_discount_reason,
          quote_id: quoteId,
        } = quote;
        dispatch({
          type: 'SELECT_VEHICLE',
          vehicle: vehicle || {},
        });
        const vehicleType = getVehicleType(vehicle);
        dispatch({
          type: 'SELECT_VEHICLE_TYPE',
          vehicleType,
        });

        if (coupon_code) {
          apiGet(`/coupon/${coupon_code}`).then((res) => {
            res.json().then((coupon) => {
              if (!coupon) {
                addMessage(
                  dispatch,
                  'Sorry, there was an error with the coupon. Please contact us for assistance.',
                  10,
                  {
                    messageType: 'error',
                  },
                );
              } else {
                dispatch({
                  type: 'SET_COUPON',
                  coupon,
                });
              }
            });
          });
        } else {
          dispatch({
            type: 'SET_COUPON',
            coupon: null,
          });
        }

        const customDiscount = {
          customDiscountAmountDollars: custom_discount_amount_dollars,
          customDiscountReason: custom_discount_reason,
        };

        dispatch({
          type: 'SET_CUSTOM_DISCOUNT',
          customDiscount: custom_discount_amount_dollars
            ? customDiscount
            : null,
        });

        if (address) {
          dispatch({
            type: 'SET_HOME_ADDRESS',
            addressIDSelected: address.customer_address_id,
            addressNameSelected: address.display_name || address.address_line_1,
            address,
          });
          dispatch({
            type: 'SET_KEYOSK',
            keyosk: null,
          });
          dispatch({
            type: 'SET_PARKING',
            parking: null,
          });
        } else {
          dispatch({
            type: 'SET_HOME_ADDRESS',
            address: {},
            addressIDSelected: null,
            addressNameSelected: null,
          });
          dispatch({
            type: 'SET_LOCATION',
            location: null,
            selectedLocationAlias: null,
            clientLocationId: null,
          });
          dispatch({
            type: 'SET_QUOTE_ID',
            quoteId,
          });
        }
        this.setState({
          quote,
          loading: false,
          selectedOptionIndices: sections ? sections.map(() => null) : [],
        });
      })
      .catch((error) => {
        Raven.captureException(error);
      });
  }

  onSelectOption = (sectionIndex, optionIndex, isSelected) => {
    const { quote } = this.state;
    const { dispatch } = this.props;
    const selectedOptionIndices = [...this.state.selectedOptionIndices];
    selectedOptionIndices[sectionIndex] = isSelected ? null : optionIndex;
    let selectedServices = [];
    selectedOptionIndices.forEach((opIndex, secIndex) => {
      const option =
        opIndex !== null ? quote.sections[secIndex]?.options[opIndex] : null;
      if (option?.services?.length > 0) {
        const fullServices = option.services.map((s) => ({
          ...s,
          total_cost_dollars: s.labor_cost_dollars + s.parts_cost_dollars,
          short_name: s.display_name,
        }));
        selectedServices = selectedServices.concat(fullServices);
      }
    });
    addMessage(dispatch, `${isSelected ? 'Removed from' : 'Added to'} cart`, 3);
    dispatch({ type: 'SET_CART', cart: selectedServices });
    this.setState({ selectedOptionIndices });
  };

  onClickContinue = () => {
    const {
      quote: {
        quote_request: { address },
      },
    } = this.state;
    const { coupon } = this.props;
    const { coupon_code } = coupon || {};
    if (!hasAccessToken()) {
      const baseUrl = '/pricing/begin';
      const url = coupon_code
        ? baseUrl.concat(`?couponCode=${coupon_code}`)
        : baseUrl;
      browserHistory.push(url);
    } else if (address) browserHistory.push('/pricing/schedule');
    else browserHistory.push('/pricing/place');
  };

  render() {
    const { loading, quote, helpModalOpen } = this.state;
    const { coupon, customDiscount } = this.props;
    const { quote_request: { vehicle } = {}, name, note, sections } = quote;

    if (loading) {
      return (
        <Zippity>
          <Loading />
        </Zippity>
      );
    }

    const noOptionsSelected = this.state.selectedOptionIndices?.every(
      (i) => i === null,
    );

    const vehicleShortDescription = vehicle?.display_name;

    const { z3pConfiguration } = this.props;
    const { customer_facing_name: z3pName } = z3pConfiguration;

    return (
      <Pricing
        currentStep="Begin"
        h2={`Here is ${
          vehicleShortDescription
            ? `a quote for your ${vehicleShortDescription}`
            : 'your quote'
        }`}
        hideNames
        onContinue={this.onClickContinue}
        showContinue
        enableContinue={!noOptionsSelected}
        alertText="Please select at least one option"
      >
        <div className="col m12">
          <div className="quote-top-container">
            <p>
              {note ||
                'Select from the options below and schedule your service.'}
            </p>
            <div
              className="modal-trigger"
              onClick={() => this.setState({ helpModalOpen: true })}
            >
              <Icon className="check-icon" small>
                help
              </Icon>
            </div>
          </div>

          {!!coupon && (
            <div>
              <strong>Coupon:</strong> {coupon.coupon_code} - Select services
              and continue to see the effect on cost
            </div>
          )}
          {!!customDiscount && (
            <div>
              <strong>Custom Discount:</strong>{' '}
              {formatPrice(customDiscount.customDiscountAmountDollars)}
              {customDiscount.customDiscountReason &&
                ` for ${customDiscount.customDiscountReason}`}
            </div>
          )}

          {!!sections &&
            sections.map((section, sectionIndex) => (
              <div className="quote-section-container">
                <h6>{section.name}</h6>
                <p>{section.note}</p>
                <div className="quote-options-container">
                  {!!section.options &&
                    section.options.map((option, optionIndex) => {
                      const numOptions = section.options.length;
                      const numOptionsInLastRow = numOptions % 3 || 3;
                      const optionIsInLastRow =
                        optionIndex >= numOptions - numOptionsInLastRow;
                      const width = optionIsInLastRow
                        ? `${90 / numOptionsInLastRow}%`
                        : '30%';
                      const isSelected =
                        this.state.selectedOptionIndices[sectionIndex] ===
                        optionIndex;
                      return (
                        <QuoteOption
                          option={option}
                          isSelected={isSelected}
                          width={width}
                          onSelect={() =>
                            this.onSelectOption(
                              sectionIndex,
                              optionIndex,
                              isSelected,
                            )
                          }
                        />
                      );
                    })}
                </div>
              </div>
            ))}
          <Modal
            header="Have questions on your quote?"
            open={helpModalOpen}
            options={{
              onCloseStart: () => this.setState({ helpModalOpen: false }),
            }}
          >
            <p>
              We're happy to help! Call {z3pName} at {getPhoneSupportLink()} or
              send us an email at {getEmailSupportLink()}.
            </p>
          </Modal>
        </div>
      </Pricing>
    );
  }
}

function mapStateToProps(state) {
  return {
    z3pConfiguration: state.ui.z3pConfiguration,
    priceList: state.ui.priceList,
    coupon: state.ui.coupon,
    customDiscount: state.ui.customDiscount,
  };
}

export default connect(mapStateToProps, null)(Quote);
