import React, { Fragment } from 'react';

const MAKE_TO_COPYRIGHT = {
  Chrysler:
    'Portions of materials contained herein are sourced by Fiat USA, LLC.',
  Ford:
    'This product contains copyrighted and other protected material owned by FORD Motor Company.  Unauthorized use or reproduction, in whole or in part, is prohibited.',
  'General Motors':
    'Portions of materials contained herein have been reprinted under license from General Motors, LLC, License Agreement #1710717.',
  Honda:
    'Portions of materials contained herein have been reprinted under license from American Honda Corporation, License Agreement 11203AH. The catalog and data included herein cannot be reproduced without the prior written consent of American Honda Motor Co., Inc.',
  Hyundai:
    'Portions of materials contained herein have been reprinted under license from Hyundai Motor America, License Agreement HMA13004. All copyrights in and to such material are owned by Hyundai Motor America.',
  'Land Rover':
    'This product contains material that is reproduced and distributed under a license from Land Rover.  No further reproduction or distribution of the Land Rover material is allowed without the express written permission of Land Rover.',
  Jaguar:
    'This product contains material that is reproduced and distributed under a license from Jaguar Cars Limited.  No further reproduction or distribution of the Jaguar material is allowed without the express written permission of Jaguar Cars Limited.',
  Mazda:
    'This product contains copyrighted and other protected material owned by Mazda North America.  Unauthorized use or reproduction, in whole or in part, is prohibited.',
  Nissan:
    'Portions of materials contained herein have been reprinted under a license from Nissan North America (license Agreement MNA15007).  Unauthorized user or reproduction, in whole or in part, is prohibited.',
  Subaru:
    'Portions of materials contained herein have been reprinted under license from Subaru of America, Inc.  This product contains material that is reproduced and distributed under a license from Subaru of America, Inc.  No further reproduction or distribution of the Subaru material is allowed without the express written permission of Subaru of America, Inc.',
  Toyota:
    'Portions of materials contained herein have been reprinted under license from Toyota Motor North America, Inc., License Agreement TMS1008.  Please see www.motor.com/oem-compliance-requirements for important disclosures regarding these materials.',
  Volkswagen:
    'Portions of materials contained herein have been reprinted under license from Volkswagen Group of America, Inc., License Agreement 12500VWA.',
  Volvo:
    'This product contains material that is reproduced and distributed under a license from Volvo Car Corporation.  No further reproduction or distribution of the Volvo material is allowed without the express written permission of Volvo Car Corporation.',
};

const MotorAtttribution = (props) => {
  const { make } = props;
  const makeCopyright = MAKE_TO_COPYRIGHT[make];
  return (
    <Fragment>
      <a
        href="https://www.motor.com/careers-about-us/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="attribution-img"
          src="/static/img/MOTORDriven-sm.png"
          alt="MOTOR Driven"
        />
      </a>
      <br />
      <p className="attribution-text">{makeCopyright}</p>
    </Fragment>
  );
};

export default MotorAtttribution;
