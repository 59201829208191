/* globals fetch */
import { get } from 'lodash';
import Raven from 'raven-js';
import { apiGet } from '../../brainApi';

// Concat all the notes into one.
// In the future, we will make it so Brain2 accepts multiple separate notes.
export const createTireNote = (tireNotes) => {
  if (!tireNotes) {
    return '';
  }

  return Object.keys(tireNotes)
    .map((prompt) => {
      const note = tireNotes[prompt];
      return `${prompt}\n${note}`;
    })
    .join('\n\n');
};

// Return true if stripe is only active method or if there are no active payment methods but stripe is turned on
export const showStripeCheckout = (activePaymentMethods, isUsingStripe) => {
  return (
    (activePaymentMethods.length === 1 &&
      activePaymentMethods.find((method) => method.is_stripe)) ||
    (activePaymentMethods.length === 0 && isUsingStripe)
  );
};

// Use this function to determine when to skip Stripe checkout and the Payment Method dropdown altogether and create a SW without payment information
export const skipPaymentInformation = (activePaymentMethods, isUsingStripe) => {
  // If we should show the stripe checkout, return false
  if (showStripeCheckout(activePaymentMethods, isUsingStripe)) return false;

  // Skip if activePaymentMethods.length is 0 or 1, otherwise return false and show the payment section
  return activePaymentMethods.length <= 1;
};

export const getActivePaymentMethods = (
  dispatch,
  isUsingStripe,
  isUsingSquare,
) => {
  apiGet('/payment-method')
    .then((response) => response.json())
    .then((data) => {
      if (data && !!data.length) {
        let filteredList = data.filter(
          (method) => method.is_active && !method.is_internal,
        );
        // If the client is not configured to use Stripe, do not include stripe method as active, even if is_active is true
        if (!isUsingStripe) {
          filteredList = filteredList.filter((method) => !method.is_stripe);
        }
        // If the client is not configured to use Square, do not include square method as active, even if is_active is true
        if (!isUsingSquare) {
          filteredList = filteredList.filter((method) => !method.is_square);
        }
        if (filteredList.length > 0) {
          dispatch({
            type: 'SET_SELECTED_PAYMENT_METHOD',
            selectedPaymentMethod: filteredList[0],
          });
        } else {
          dispatch({
            type: 'SET_SELECTED_PAYMENT_METHOD',
            selectedPaymentMethod: null,
          });
        }
        dispatch({
          type: 'SET_ACTIVE_PAYMENT_METHODS',
          activePaymentMethods: filteredList,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      Raven.captureException(error);
    });
};
