import React from 'react';
import { Icon } from 'react-materialize';

const Checkbox = ({ isSelected }) => (
  <Icon className="check-icon" small>
    {isSelected ? 'check_box' : 'check_box_outline_blank'}
  </Icon>
);

export default Checkbox;
