import React, { useState, useEffect } from 'react';
import { Row, TextInput } from 'react-materialize';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import Raven from 'raven-js';
import { getCommonVehicleColors } from '../../helper';
import { apiGet } from '../../brainApi';
import VehicleDropdown from '../components/VehicleDropdown';

const NonAutomobileVehicleEntry = ({
  vehicleDetails,
  setVehicleDetails,
  pricing,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allVehicleData, setAllVehicleData] = useState({});
  const [makeList, setMakeList] = useState([]);
  const [modelList, setModelList] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    apiGet(`/other-vehicle/${pricing.vehicleType}`)
      .then((response) => response.json())
      .then(({ Status, Message, Data }) => {
        setIsLoading(false);
        if (Status === 'Success') {
          const data = Data;
          setAllVehicleData(data);
          setMakeList([...Object.keys(data), 'Other']);
        } else {
          console.error(Message);
        }
      })
      .catch((error) => {
        console.log(error);
        Raven.captureException(error);
      });
  }, []);

  // Set modelList based on make selected
  useEffect(() => {
    if (vehicleDetails.makeName) {
      const models = allVehicleData[vehicleDetails.makeName];
      setModelList([...(models || []), 'Other']);
    }
  }, [vehicleDetails.makeName, allVehicleData]);

  // Always show the future year as the top end of the range (ex: in 2021, show up to 2022)
  const currentYear = Number(moment().format('YYYY'));
  const years = _.range(currentYear + 1, currentYear - 100);
  const colors = getCommonVehicleColors();

  const handleChange = (type) => (e) => {
    setVehicleDetails({
      ...vehicleDetails,
      [type]: e.target.value,
      ...(type === 'makeName' && { modelName: '' }),
    });
  };

  return (
    <Row style={{ display: 'flex', flexWrap: 'wrap' }}>
      <VehicleDropdown
        type="Year"
        value={vehicleDetails.year}
        onChange={handleChange('year')}
        list={years}
        classes="vehicle-entry-dropdown"
      />
      <VehicleDropdown
        type="Make"
        value={vehicleDetails.makeName}
        onChange={handleChange('makeName')}
        list={makeList}
        required
        classes="vehicle-entry-dropdown"
        disabled={isLoading}
      />
      {vehicleDetails.makeName === 'Other' && (
        <TextInput
          label="Other Make *"
          placeholder="Enter Make"
          id="otherMake"
          value={vehicleDetails.otherMake}
          onChange={handleChange('otherMake')}
          inputClassName="vehicle-entry-text-field"
        />
      )}
      <VehicleDropdown
        type="Model"
        value={vehicleDetails.modelName}
        onChange={handleChange('modelName')}
        list={modelList}
        classes="vehicle-entry-dropdown"
        disabled={!vehicleDetails.makeName}
      />
      {vehicleDetails.modelName === 'Other' && (
        <TextInput
          label="Other Model"
          placeholder="Enter Model"
          id="otherModel"
          value={vehicleDetails.otherModel}
          onChange={handleChange('otherModel')}
          inputClassName="vehicle-entry-text-field"
        />
      )}
      <VehicleDropdown
        type="Color"
        value={vehicleDetails.color}
        onChange={handleChange('color')}
        list={colors}
        classes="vehicle-entry-dropdown"
      />
    </Row>
  );
};

function mapStateToProps(state) {
  return {
    pricing: state.ui.pricing,
  };
}

export default connect(mapStateToProps, null)(NonAutomobileVehicleEntry);
