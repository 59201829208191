import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { Button, Card, Checkbox, Col, Row } from 'react-materialize';
import _ from 'lodash';

import Pricing from './components/Pricing';
import {
  getServiceByName,
  getServicesByScheduleTypes,
} from '../serviceHelpers';
import { loadClientLocationDetailsAndPriceList } from '../brain2';
import { hasAccessToken } from '../brainApi';
import { setPageTitle } from '../helper';

class Diagnostic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      commonConcerns: {
        scheduledMaintenance: {
          short: 'scheduledMaintenance',
          long: 'Due for scheduled maintenance or oil change.',
          selected: false,
        },
        checkEngine: {
          short: 'checkEngine',
          long: 'Check Engine light is on.',
          selected: false,
        },
        tirePressure: {
          short: 'tirePressure',
          long: 'Tire Pressure light is on.',
          selected: false,
        },
        brakeSqueal: {
          short: 'brakeSqueal',
          long: 'Brakes are squealing or grinding.',
          selected: false,
        },
        engineStart: {
          short: 'engineStart',
          long: 'Engine is having trouble starting.',
          selected: false,
        },
        newTires: {
          short: 'newTires',
          long: 'Got a flat tire. / Need new tires.',
          selected: false,
        },
        changeTires: {
          short: 'changeTires',
          long: 'Need to put on my winter or summer tires.',
          selected: false,
        },
      },
      customerDescription: props.diagnostic.customerDescription || '',
    };

    this.handleChangeCommonConcerns = this.handleChangeCommonConcerns.bind(
      this,
    );
    this.handleChangeCustomerDescription = this.handleChangeCustomerDescription.bind(
      this,
    );
    this.handleSubmit = this.handleSubmit.bind(this);
    this.disableContinue = this.disableContinue.bind(this);
  }

  componentWillMount() {
    setPageTitle('Diagnostic Services');
  }

  componentDidMount() {
    const { dispatch, customerLocation, vehicle } = this.props;

    loadClientLocationDetailsAndPriceList(
      dispatch,
      customerLocation?.clientLocationId,
      vehicle?.vehicle_id,
    );
  }

  handleChangeCommonConcerns(event) {
    const { commonConcerns } = this.state;

    const newCommonConcerns = { ...commonConcerns };

    newCommonConcerns[event.target.value].selected = event.target.checked;

    this.setState({ commonConcerns: newCommonConcerns });
  }

  handleChangeCustomerDescription(event) {
    this.setState({ customerDescription: event.target.value });
  }

  disableContinue() {
    const { commonConcerns, customerDescription } = this.state;

    const noCommonConcerns = _.every(commonConcerns, {
      selected: false,
    });

    const noCustomerDescription = customerDescription.length < 5;

    return noCommonConcerns && noCustomerDescription;
  }

  handleSubmit() {
    const { dispatch, priceList } = this.props;
    const { commonConcerns, customerDescription } = this.state;

    const selectedCommonConcerns = _.filter(commonConcerns, {
      selected: true,
    });
    let commonConcernText = selectedCommonConcerns.map(
      (concern) => ` ${concern.long}`,
    );
    if (commonConcernText.length === 0) {
      commonConcernText = 'None.';
    }
    const completeDescription = `Common Concerns: ${commonConcernText} | Description: ${customerDescription}`;

    // Save their description
    dispatch({
      type: 'SET_DIAGNOSTIC',
      diagnostic: {
        selected: true,
        customerDescription,
        completeDescription,
      },
    });

    // Add a 'diagnostic'
    const mechanicalServices = getServicesByScheduleTypes(priceList, [
      'mechanical',
    ]);
    const diagnosticService = getServiceByName(
      mechanicalServices,
      'Diagnostic',
    );

    dispatch({
      type: 'SET_CART',
      cart: [diagnosticService],
    });

    // if we already have the userId, we can skip straight to schedule
    if (hasAccessToken()) {
      browserHistory.push('/pricing/schedule');
      return;
    }

    browserHistory.push('/pricing/account');
  }

  render() {
    const { commonConcerns, customerDescription } = this.state;
    const { coupon } = this.props;
    return (
      <Pricing currentStep="Services" h1="Select Your Services">
        <form onSubmit={this.handleSubmit}>
          <Row>
            <Col s={12} m={7}>
              <Row>
                <Col s={12}>
                  <p>
                    <strong>
                      Common Concerns: Check any that apply to your car.
                    </strong>
                  </p>
                  <p />
                </Col>

                {Object.keys(commonConcerns).map((key) => (
                  <Col key={key} s={12}>
                    <Checkbox
                      checked={commonConcerns[key].selected}
                      name={commonConcerns[key].short}
                      value={commonConcerns[key].short}
                      label={commonConcerns[key].long}
                      className="filled-in"
                      onChange={this.handleChangeCommonConcerns}
                    />
                  </Col>
                ))}
              </Row>

              <Row>
                <Col s={12}>
                  <p>
                    <strong>
                      What else needs work? What is your mileage? Describe what
                      you&apos;re experiencing with the car. We&apos;ll use this
                      information to guide our inspection.
                    </strong>
                  </p>
                  <textarea
                    onChange={this.handleChangeCustomerDescription}
                    value={customerDescription}
                    s={11}
                  />
                </Col>
              </Row>
              <Row>
                <Col m={8} s={12}>
                  <Button
                    className="btn-large header"
                    type="submit"
                    disabled={this.disableContinue()}
                  >
                    Continue
                  </Button>
                </Col>
              </Row>
            </Col>

            <Col s={12} m={5}>
              <Card className="services-we-provide" title="How It Works">
                <p>
                  <strong>1.</strong> Tell us what you&apos;re experiencing.
                </p>
                <p>
                  <strong>2.</strong> We&apos;ll inspect your vehicle, report
                  our findings, and provide upfront pricing for recommended
                  services.
                </p>
                <p>
                  <strong>3.</strong> Given our recommendations, you decide what
                  work our technicians should perform.
                </p>

                <p>
                  We have capabilities similar to traditional auto garages and
                  can address most car maintenance and repair needs.
                  <a
                    href="https://zippitycars.com/fulllist"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    &nbsp;Learn more about our services.
                  </a>
                </p>
              </Card>
            </Col>
          </Row>
        </form>
      </Pricing>
    );
  }
}

function mapStateToProps(state) {
  return {
    vehicle: state.ui.pricing,
    account: state.ui.account,
    customerLocation: state.ui.customerLocation,
    priceList: state.ui.priceList,
    diagnostic: state.ui.diagnostic,
    coupon: state.ui.coupon,
  };
}

export default connect(mapStateToProps, null)(Diagnostic);
