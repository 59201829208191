import React from 'react';
import { Modal } from 'react-materialize';
import { connect } from 'react-redux';

const CancellationPolicyModal = ({ open, onCancel, z3pConfiguration }) => {
  const {
    cancellation_cut_off_hours: cancellationCutOffHours,
    cancellation_fee_amount_dollars: cancellationFeeAmountDollars,
    no_show_fee_amount_dollars: noShowFeeAmountDollars,
  } = z3pConfiguration;

  return (
    <Modal
      header="Cancellation Policy"
      open={open}
      options={{
        onCloseStart: onCancel,
      }}
    >
      <>
        <p>
          If the customer cancels less than
          <span style={{ fontWeight: 'bold' }}>
            {' '}
            {cancellationCutOffHours} hrs{' '}
          </span>
          from the appointment start time, they will be charged a
          <span style={{ fontWeight: 'bold' }}>
            {' '}
            ${cancellationFeeAmountDollars}{' '}
          </span>
          cancellation fee. No show appointments will incur a
          <span style={{ fontWeight: 'bold' }}>
            {' '}
            ${noShowFeeAmountDollars}{' '}
          </span>
          fee.
        </p>
      </>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(CancellationPolicyModal);
