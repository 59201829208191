import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Col, Row } from 'react-materialize';
import {
  apiGet,
  apiPut,
  hasAccessToken,
  loadCompatibleDates,
  loadCompatibleDatesForHomeAddress,
} from '../brainApi';
import { addMessage } from '../zippity';
import Zippity from './components/Zippity';
import { datetimeToTime } from '../helper';
import { getVehicleType } from '../vehicleHelper';

class ProactiveSchedule extends Component {
  componentDidMount() {
    const { params } = this.props;
    const { customer_id, sw_id, new_date } = params;

    apiGet(`/scheduled-work/${sw_id}/${customer_id}`)
      .then((response) => response.json())
      .then((sw) => {
        this.setData(sw);
        if (new_date) {
          this.getCompatibleDatesAndRescheduleSw(sw, customer_id, new_date);
        } else {
          this.redirectToSchedule(sw);
        }
      });
  }

  isDateAvailable = (date, compatibleDates) => {
    const newDateFormatted = new Date(date);
    return compatibleDates.some((dateDetails) => {
      const dateFormatted = new Date(dateDetails.date);
      return (
        dateFormatted.getTime() === newDateFormatted.getTime() &&
        dateDetails.is_available
      );
    });
  };

  setData = (sw) => {
    const { dispatch } = this.props;

    dispatch({
      type: 'SET_CHANGE_BOOKING',
      changeBooking: true,
    });

    dispatch({
      type: 'SET_CART',
      cart: sw.services,
    });

    dispatch({
      type: 'SELECT_VEHICLE',
      vehicle: sw.vehicle,
    });
    const vehicleType = getVehicleType(sw.vehicle);
    dispatch({ type: 'SELECT_VEHICLE_TYPE', vehicleType });
  };

  // If customer did not accept the suggested date, or the date is unavailable, redirect to the pricing/schedule page
  redirectToSchedule = (sw) => {
    const { dispatch } = this.props;
    const isLoggedIn = hasAccessToken();

    const schedule = {
      plannedArrivalTime: datetimeToTime(sw.planned_arrival_time),
      plannedDepartureTime: datetimeToTime(sw.planned_departure_time),
      note: sw.customer_note,
      date: sw.date,
      swId: sw.id,
      customerId: sw.customer_id,
      serviceVehicleId: sw.service_vehicle_id,
    };
    dispatch({ type: 'SET_SCHEDULE', schedule });
    // If logged in, go straight to the schedule page
    if (isLoggedIn) {
      return browserHistory.push(`/pricing/schedule`);
    }
    // If not logged in, redirect to login and then schedule
    const redirect = encodeURIComponent('/pricing/schedule');
    return browserHistory.push(`/login?redirect=${redirect}`);
  };

  // if the customer accepts the suggested date and it is available, update the SW with a new date
  // if the date is unavailable, set a message and redirect
  getCompatibleDatesAndRescheduleSw = (sw, customer_id, new_date) => {
    const atHomeAddressID = sw.customer_address && sw.customer_address.id;

    if (atHomeAddressID) {
      loadCompatibleDatesForHomeAddress(
        atHomeAddressID,
        sw.services,
      ).then((newCompatibleDates) =>
        this.rescheduleSw(sw, newCompatibleDates, customer_id, new_date),
      );
    } else {
      loadCompatibleDates(
        sw.client_location_id,
        sw.services,
      ).then((newCompatibleDates) =>
        this.rescheduleSw(sw, newCompatibleDates, customer_id, new_date),
      );
    }
  };

  rescheduleSw = (sw, compatibleDates, customer_id, new_date) => {
    const { dispatch, location } = this.props;
    const { pathname } = location;
    const isLoggedIn = hasAccessToken();
    dispatch({
      type: 'SET_COMPATIBLE_DATES',
      compatibleDates,
    });
    const dateAvailable = this.isDateAvailable(new_date, compatibleDates);
    if (dateAvailable) {
      apiPut(`/proactive-scheduling/${sw.id}/${customer_id}`, {
        date: new_date,
      })
        .then((response) => response.json())
        .then((sw) => {
          const schedule = {
            plannedArrivalTime: datetimeToTime(sw.planned_arrival_time),
            plannedDepartureTime: datetimeToTime(sw.planned_departure_time),
            date: sw.date,
            swId: sw.id,
            customerId: sw.customer_id,
            serviceVehicleId: sw.service_vehicle_id,
          };
          dispatch({ type: 'SET_SCHEDULE', schedule });
          if (pathname.includes('reschedule')) {
            return browserHistory.push(`/one-click-confirmation/reschedule`);
          }
          return browserHistory.push(`/one-click-confirmation/proactive`);
        });
    } else {
      const loginMessage = isLoggedIn ? '' : 'log in and ';
      addMessage(
        dispatch,
        `The date you selected has filled and is no longer available. Please ${loginMessage}choose another date.`,
        10,
        { messageType: 'error' },
      );
      this.redirectToSchedule(sw);
    }
  };

  // Just render a loading screen. We redirect the user to a new screen from here.
  render() {
    return (
      <Zippity active="checkin">
        <Row>
          <Col s={12} className="center">
            <h2>Loading...</h2>
          </Col>
        </Row>
      </Zippity>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.ui.account,
  };
}

export default connect(mapStateToProps, null)(ProactiveSchedule);
