import React, { Component } from 'react';
import { Button, Card, Icon, Row, Col } from 'react-materialize';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import _, { groupBy } from 'lodash';
import Moment from 'moment';

import CancelServiceModal from '../components/CancelServiceModal';
import { mediaQueryMaxWidth600, handleRescheduleClick } from '../../helper';
import { apiPut } from '../../brainApi';
import { addMessage } from '../../zippity';
import AdditionalContacts from '../AdditionalContacts';
import cancelService from '../../appointmentHelper';

class ScheduledWorkCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCancelServiceModalOpen: false,
      cancelling: false,
      additionalContactList: [],
      areAdditionalContactsValid: true,
      isSavingPhoneNumbers: false,
      showPhoneNumberActionButtons: false,
    };
    this.handleRescheduleClick = handleRescheduleClick.bind(this);
    this.handleCheckInClick = this.handleCheckInClick.bind(this);
    this.handleSeeDetailsClick = this.handleSeeDetailsClick.bind(this);
    this.handleConfirmClick = this.handleConfirmClick.bind(this);
    this.setCancelServiceModalOpen = this.setCancelServiceModalOpen.bind(this);
    this.setCancelling = this.setCancelling.bind(this);
    this.formatPhoneNumberList = this.formatPhoneNumberList.bind(this);
    this.cleanPhoneNumber = this.cleanPhoneNumber.bind(this);
  }

  cleanPhoneNumber(number) {
    // Remove special characters (ex; +) and save just the 10 digit phone number
    const cleanedPhone = number.replace(/\D/g, '').match(/^(1|)?(\d{10})$/);
    if (cleanedPhone) {
      return cleanedPhone[2];
    }
    return '';
  }

  formatPhoneNumberList() {
    const { sw } = this.props;
    if (sw.additional_phone_numbers) {
      const formattedPhoneNumbers = sw.additional_phone_numbers.map((phone) => {
        return { phoneNumber: this.cleanPhoneNumber(phone) };
      });
      this.setState({ additionalContactList: formattedPhoneNumbers });
    }
  }

  componentDidMount() {
    this.formatPhoneNumberList();
  }

  componentDidUpdate(prevProps, prevState) {
    const { additionalContactList } = this.state;
    const { sw } = this.props;
    if (prevState.additionalContactList !== additionalContactList) {
      const simplifiedList = additionalContactList.map((c) => c.phoneNumber);
      const simplifiedOriginalList = sw.additional_phone_numbers?.map((phone) =>
        this.cleanPhoneNumber(phone),
      );
      this.setState({
        showPhoneNumberActionButtons: !_.isEqual(
          simplifiedList,
          simplifiedOriginalList,
        ),
      });
    }
  }

  // Format at 9am or 9:30am
  formatTime = (time) => {
    const hour = parseInt(time.substring(0, 2), 10);
    const min = parseInt(time.substring(3, 5), 10);
    const minPad = min < 10 ? '0' : '';
    const ampm = hour < 12 ? 'am' : 'pm';
    let hour12 = ((hour - 1) % 12) + 1;

    if (hour12 === 0) hour12 = 12;
    if (min === 0) {
      return `${hour12}${ampm}`;
    }
    return `${hour12}:${minPad}${min}${ampm}`;
  };

  handleSeeDetailsClick() {
    const { account, sw } = this.props;
    browserHistory.push(`/approve-services/${account.customer_id}/${sw.id}`);
  }

  handleCheckInClick() {
    const { account, sw } = this.props;
    browserHistory.push(`/checkin/${account.customer_id}/${sw.id}`);
  }

  handleConfirmClick() {
    const { account, sw } = this.props;
    browserHistory.push(
      `/proactive-scheduling/${account.customer_id}/${sw.id}/${sw.date}`,
    );
  }

  setCancelServiceModalOpen(value) {
    this.setState({ isCancelServiceModalOpen: value });
  }

  setCancelling(value) {
    this.setState({ cancelling: value });
  }

  render() {
    const {
      sw,
      account,
      showAdditionalContacts,
      dispatch,
      scheduledWorks,
      refreshScheduledWorks,
    } = this.props;
    const {
      isCancelServiceModalOpen,
      cancelling,
      additionalContactList,
      areAdditionalContactsValid,
      isSavingPhoneNumbers,
      showPhoneNumberActionButtons,
    } = this.state;

    const setAdditionalContactList = (value) => {
      this.setState({ additionalContactList: value });
    };

    const setAreAdditionalContactsValid = (value) => {
      this.setState({ areAdditionalContactsValid: value });
    };

    const savePhoneNumberList = () => {
      this.setState({ isSavingPhoneNumbers: true });
      const additionalPhoneNumbers = additionalContactList.map(
        (contact) => contact.phoneNumber?.length === 10 && contact.phoneNumber,
      );
      apiPut(`/booking/scheduled-work/${sw.id}`, {
        additional_phone_numbers: additionalPhoneNumbers,
      })
        .then((response) => {
          if (response.status !== 200) {
            addMessage(
              dispatch,
              'Could not update phone number list. Please try again',
              5,
              { messageType: 'error' },
            );
          } else {
            addMessage(dispatch, 'Success! Phone number list updated', 10);
            this.setState({ showPhoneNumberActionButtons: false });
            const index = scheduledWorks.findIndex(
              (s) => s.scheduled_work_id === sw.id,
            );
            if (index !== -1) {
              // Update the redux scheduled work list to be updated with new phone number list
              const scheduledWorksCopy = [...scheduledWorks];
              scheduledWorksCopy[
                index
              ].additional_phone_numbers = additionalPhoneNumbers;
              dispatch({
                type: 'SET_SCHEDULED_WORKS',
                scheduledWorks: scheduledWorksCopy,
              });
            }
          }
        })
        .catch(() => {
          addMessage(
            dispatch,
            'Could not update phone number list. Please try again',
            5,
            { messageType: 'error' },
          );
        })
        .finally(() => this.setState({ isSavingPhoneNumbers: false }));
    };

    const cancelPhoneNumberUpdates = () => {
      this.formatPhoneNumberList(sw.additional_phone_numbers);
    };

    const filteredServices = sw.services.filter(
      (s) => !s.is_upsell || s.upsell_status === 'approved',
    );
    const groupedServices = groupBy(filteredServices, 'short_name');
    const serviceItems = Object.keys(groupedServices).map((key) => {
      const s = groupedServices[key][0];
      const quantity = groupedServices[key].length;
      return (
        <li key={s.service_offering_id}>
          <Icon className="vertical-align-middle">chevron_right</Icon>
          {quantity > 1 && <span>{quantity} x </span>}
          {s.display_name || s.long_name}
        </li>
      );
    });

    const nowMoment = Moment();
    const address = sw.client_location || sw.customer_address || {};
    let swMoment = Moment(sw.date);
    if (sw.confirmed_appointment_start_time) {
      swMoment = Moment(`${sw.date} ${sw.confirmed_appointment_start_time}`);
    }
    if (address.time_zone) {
      swMoment = swMoment.tz(address.time_zone);
    }

    const swIsToday = nowMoment.isSame(swMoment, 'day');
    const swDaysFromNow = swMoment.diff(nowMoment, 'days');
    const swOlderThanOneWeekInPast = swDaysFromNow < -7;
    const swDateIsInPast = new Moment(sw.date).isBefore(new Date(), 'day');
    const readyToCheckIn = sw.status === 'SCHEDULED' && swIsToday;
    const receiptLink = `/receipt/${account.customer_id}/${sw.id}`;
    const quoteLink = `/quote/${account.customer_id}/${sw.id}`;

    const hasFutureOrRejectedUpsells = sw.services.filter((s) =>
      ['future', 'rejected'].includes(s.upsell_status),
    );

    const {
      id: swId,
      date,
      planned_arrival_time: arrival,
      planned_departure_time: departure,
      confirmed_appointment_start_time: start,
      client_location: clientLocation,
      vehicle = {},
      is_handraise,
    } = sw;
    const formattedDate = date || "Date TBD - we'll reach out soon!";
    const { display_name } = vehicle || {};

    const swAtHomeServiceLocation =
      (!clientLocation || !clientLocation.id) &&
      sw.customer_address &&
      (sw.customer_address.display_name || sw.customer_address.address_line_1);

    const locationDescription =
      swAtHomeServiceLocation ||
      (clientLocation ? clientLocation.long_name : '');

    let timeString = '';
    if (start) {
      timeString = this.formatTime(start);
    } else if (arrival && departure) {
      timeString = `${this.formatTime(arrival)} - ${this.formatTime(
        departure,
      )}`;
    }

    const title = `${formattedDate} | ${timeString} | ${locationDescription}`;

    const showHealthCheck = sw.hc_is_complete && sw.health_check_url;

    return (
      <Card key={swId} className="swCard" title={title}>
        <ul className="neue upcomming_service_list">
          {is_handraise && date && (
            <li>
              You said you want an earlier date, if possible. We’ll reach out if
              anything opens up!
            </li>
          )}
          <Row>
            <Col s={12} l={6}>
              {display_name && (
                <>
                  <li className="title">
                    <span>{display_name}</span>
                  </li>
                </>
              )}

              {serviceItems}
            </Col>
            {showAdditionalContacts && (
              <Col>
                <AdditionalContacts
                  additionalContactList={additionalContactList}
                  setAdditionalContactList={setAdditionalContactList}
                  areAdditionalContactsValid={areAdditionalContactsValid}
                  setAreAdditionalContactsValid={setAreAdditionalContactsValid}
                  showActionButtons={showPhoneNumberActionButtons}
                  savePhoneNumberList={savePhoneNumberList}
                  cancelPhoneNumberUpdates={cancelPhoneNumberUpdates}
                  isSavingPhoneNumbers={isSavingPhoneNumbers}
                  fromAccountPage
                />
              </Col>
            )}
          </Row>
        </ul>
        <div className="service_btn">
        {readyToCheckIn && (
          <Button className="btn btn green" onClick={this.handleCheckInClick}>
            Check In
          </Button>
        )}
        {sw.status === 'scheduled' && !swOlderThanOneWeekInPast && date && (
          <Button
            className="btn btn-teal"
            onClick={() => this.handleRescheduleClick(sw, dispatch, vehicle)}
          >
            Reschedule
          </Button>
        )}
        {sw.status === 'scheduled' && !date && (
          <Button
            className="btn btn-teal"
            onClick={() => this.handleRescheduleClick(sw, dispatch, vehicle)}
          >
            {mediaQueryMaxWidth600().matches
              ? 'Check Dates'
              : 'Check Dates Again'}
          </Button>
        )}
        {(sw.hc_is_complete || sw.status === 'finished') && (
          <Button className="btn btn-teal" node="a" href={receiptLink}>
            Receipt
          </Button>
        )}
        {hasFutureOrRejectedUpsells.length > 0 && sw.status !== 'suggested' && (
          <Button className="btn btn-teal" node="a" href={quoteLink}>
            {mediaQueryMaxWidth600().matches
              ? 'Get Quote'
              : 'Quote for Next Time'}
          </Button>
        )}
        {sw.status === 'suggested' && (
          <Button className="btn btn-teal" onClick={this.handleConfirmClick}>
            {mediaQueryMaxWidth600().matches
              ? 'Confirm Date'
              : 'Confirm This Date'}
          </Button>
        )}
        {sw.status === 'suggested' && (
          <Button
            className="btn btn-teal"
            onClick={() => this.handleRescheduleClick(sw, dispatch, vehicle)}
          >
            {mediaQueryMaxWidth600().matches
              ? 'Different Date'
              : 'Choose Different Date'}
          </Button>
        )}
        {showHealthCheck && (
          <a
            href={sw.health_check_url}
            target="_blank"
            className="btn btn-teal"
            rel="noreferrer"
          >
            Service Report
          </a>
        )}

        {(sw.status === 'scheduled' || sw.status === 'suggested') &&
          !swDateIsInPast &&
          !showHealthCheck && (
            <Button
              className="btn grey lighten-3 btn-cancel"
              onClick={() => this.setCancelServiceModalOpen(true)}
              role="button"
              tabIndex="0"
              onKeyPress={() => this.setCancelServiceModalOpen(true)}
            >
              Cancel Service
            </Button>
          )}
        </div>
        <CancelServiceModal
          open={isCancelServiceModalOpen}
          mainText="Are you sure you want to cancel?"
          cancelling={cancelling}
          closeModalText="Go Back"
          onConfirm={(
            reason,
            details,
            feeToCharge,
            setAllowSelfServiceCancellation,
            setErrorMessage,
          ) => {
            if (!cancelling)
              cancelService(
                reason,
                details,
                feeToCharge,
                setAllowSelfServiceCancellation,
                setErrorMessage,
                sw,
                dispatch,
                this.setCancelling,
                this.setCancelServiceModalOpen,
                refreshScheduledWorks,
              );
          }}
          onCloseModal={() => {
            if (!cancelling) this.setCancelServiceModalOpen(false);
          }}
          onReschedule={() => this.handleRescheduleClick(sw, dispatch, vehicle)}
          sw={sw}
        />
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.ui.account,
    vehicle: state.ui.pricing,
    scheduledWorks: state.ui.scheduledWorks,
  };
}

export default connect(mapStateToProps, null)(ScheduledWorkCard);
