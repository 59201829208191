import React, { Fragment } from 'react';
import { Modal } from 'react-materialize';
import { connect } from 'react-redux';
import Moment from 'moment';
import { calculateCosts } from '../../costCalculator';

const ReviewPrices = (props) => {
  const {
    taxRate,
    cart,
    account,
    coupon,
    z3pConfiguration,
    tipAmountDollars,
    customDiscount,
    sectionDivider
  } = props;
  const {
    payment_message_on_checkout_screen: paymentMessage,
  } = z3pConfiguration;

  /*
   * Calculate all sorts of things about the selected services,
   * to display customized messages.
   */

  const {
    originalTotalDollars,
    discountedTotalDollars,
    discounts,
    discountAmountDollars,
    estimatedSuppliesFeeDollars,
    estimatedSalesTaxDollars,
    minimumServiceChargeAmountDollars,
    finalTaxedTotalDollars,
  } = calculateCosts(
    coupon,
    cart,
    account.default_client_location_id,
    taxRate,
    Moment(),
    tipAmountDollars,
    customDiscount,
  );

  const formatPrice = (amountDollars) => {
    if (amountDollars === null || amountDollars === undefined) {
      return '';
    }
    return `$${amountDollars.toFixed(2)}`;
  };

  return (
    <div className="inline-superscripts">
      <ul className="d-flex">
        <li>Subtotal</li>
        <li className={discounts.length === 0 ? '' : 'strike'}>
          {formatPrice(originalTotalDollars)} 
        </li>
        {discounts.map((x) => (
          <li key={x}>{x}</li>
        ))}
      </ul>
      
      {discountAmountDollars > 0 && (
        <ul className="d-flex">
          <li>Subtotal</li>
          <li>
            <span className="new-total">
              {formatPrice(discountedTotalDollars)} 
            </span>
          </li>
        </ul>
      )}
      {sectionDivider}
      {discountedTotalDollars > 0 && estimatedSuppliesFeeDollars > 0 && (
        <ul className="d-flex">
          <li>
            Supplies and
            Environmental Disposal Fee
            <Modal
              header="Supplies and Environmental Disposal Fee"
              trigger={
                <sup style={{ color: '#9f7a7a', cursor: 'pointer' }}> [?]</sup>
              }
            >
              <p>
                We collect this supplemental fee to support environmentally
                responsible disposal of hazardous fluids, parts, and materials
                from your service.
              </p>
            </Modal>
          </li>
          <li>
            {formatPrice(estimatedSuppliesFeeDollars)} 
          </li>
        </ul>
        )}
        {discountedTotalDollars > 0 && estimatedSalesTaxDollars > 0 && (
          <ul className="d-flex">
            <li> Estimated sales tax </li>
            <li>{formatPrice(estimatedSalesTaxDollars)}</li>
          </ul>
        )}

        {!!minimumServiceChargeAmountDollars && (
          <ul className="d-flex">
            <li>Minimum service charge</li>
            <li>
              {formatPrice(minimumServiceChargeAmountDollars)} 
            </li>
          </ul>
        )}
        {tipAmountDollars > 0 && <ul className="d-flex"><li>Tip</li><li>{formatPrice(tipAmountDollars)}</li></ul>}
      
      {finalTaxedTotalDollars > 0 && (
        <Fragment>
          <ul className="d-flex">
            <li>Total</li>
            <li>
              <strong>{formatPrice(finalTaxedTotalDollars)}</strong>
            </li>
          </ul>
          {!!paymentMessage && <p>{paymentMessage}</p>}
        </Fragment>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    taxRate: state.ui.taxRate,
    cart: state.ui.cart,
    coupon: state.ui.coupon,
    customDiscount: state.ui.customDiscount,
    account: state.ui.account,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(ReviewPrices);
