import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { clearAccessToken } from '../brainApi';
import { setPageTitle } from '../helper';

class Logout extends Component {
  state = {};

  componentWillMount() {
    setPageTitle('Logout');
  }

  componentDidMount() {
    this.props.dispatch({ type: 'CLEAR_USER' });
    clearAccessToken();

    browserHistory.push('/');
  }

  render() {
    return <h1>Logging Out</h1>;
  }
}

export default connect(null, null)(Logout);
